export const locationConstants = {
  // GET FULL LOCATION'S LIST
  GET_LOCATIONS_REQUEST: 'GET_LOCATIONS_REQUEST',
  GET_LOCATIONS_SUCCESS: 'GET_LOCATIONS_SUCCESS',
  GET_LOCATIONS_FAILURE: 'GET_LOCATIONS_FAILURE',

  // GET FEATURED LOCATION'S LIST
  GET_FEATURED_LOCATIONS_REQUEST: 'GET_FEATURED_LOCATIONS_REQUEST',
  GET_FEATURED_LOCATIONS_SUCCESS: 'GET_FEATURED_LOCATIONS_SUCCESS',
  GET_FEATURED_LOCATIONS_FAILURE: 'GET_FEATURED_LOCATIONS_FAILURE',

  // GET SINGLE LOCATION
  GET_LOCATION_IDS_REQUEST: 'GET_LOCATION_IDS_REQUEST',
  GET_LOCATION_IDS_SUCCESS: 'GET_LOCATION_IDS_SUCCESS',
  GET_LOCATION_IDS_FAILURE: 'GET_LOCATION_IDS_FAILURE',

  // GET MY LOCATION'S LIST
  GET_MY_LOCATIONS_REQUEST: 'GET_MY_LOCATIONS_REQUEST',
  GET_MY_LOCATIONS_SUCCESS: 'GET_MY_LOCATIONS_SUCCESS',
  GET_MY_LOCATIONS_FAILURE: 'GET_MY_LOCATIONS_FAILURE',

  // EDIT MY LOCATION
  EDIT_MY_LOCATIONS_REQUEST: 'EDIT_MY_LOCATIONS_REQUEST',
  EDIT_MY_LOCATIONS_SUCCESS: 'EDIT_MY_LOCATIONS_SUCCESS',
  EDIT_MY_LOCATIONS_FAILURE: 'EDIT_MY_LOCATIONS_FAILURE',

  // REMOVE MY LOCATION
  REMOVE_MY_LOCATION_REQUEST: 'REMOVE_MY_LOCATION_REQUEST',
  REMOVE_MY_LOCATION_SUCCESS: 'REMOVE_MY_LOCATION_SUCCESS',
  REMOVE_MY_LOCATION_FAILURE: 'GET_MY_LOCATION_FAILURE',

  // GET FAVORITE LOCATION'S LIST
  GET_FAVORITE_REQUEST: 'GET_FAVORITE_REQUEST',
  GET_FAVORITE_SUCCESS: 'GET_FAVORITE_SUCCESS',
  GET_FAVORITE_FAILURE: 'GET_FAVORITE_FAILURE',

  // SET FAVORITE VALUE
  CHANGE_FAVORITE_VALUE: 'CHANGE_FAVORITE_VALUE',
  LOAD_FAVORITE: 'LOAD_FAVORITE',
  LOAD_FAVORITE_MAIN: 'LOAD_FAVORITE_MAIN',

  // ADD LOCATION TO FAVORITE LIST
  ADD_TO_FAVORITE_REQUEST: 'ADD_TO_FAVORITE_REQUEST',
  ADD_TO_FAVORITE_SUCCESS: 'ADD_TO_FAVORITE_SUCCESS',
  ADD_TO_FAVORITE_ERROR: 'ADD_TO_FAVORITE_ERROR',

  // REMOVE LOCATION FROM FAVORITE LIST
  REMOVE_FAVORITE_REQUEST: 'REMOVE_FAVORITE_REQUEST',
  REMOVE_FAVORITE_SUCCESS: 'REMOVE_FAVORITE_SUCCESS',
  REMOVE_FAVORITE_ERROR: 'REMOVE_FAVORITE_ERROR',

  // SET LOCATION CATEGORIES TYPE
  SET_NEW_LOCATION_TYPE: 'SET_NEW_LOCATION_TYPE',
  CHANGE_FAVORITE_VALUE_MAIN: 'CHANGE_FAVORITE_VALUE_MAIN',

  // UPDATE FORM
  UPDATE_NEW_LOCATION_FORM_VALUES: 'UPDATE_NEW_LOCATION_FORM_VALUES',
  RESET_LOCATION_FORM: 'RESET_NEW_LOCATION_FORM_VALUES',

  // CREATING/UPDATING NEW LOCATION
  LOCATION_FETCH_REQUEST: 'LOCATION_FETCH_REQUEST',
  LOCATION_FETCH_SUCCESS: 'LOCATION_FETCH_SUCCESS',
  LOCATION_FETCH_FAILURE: 'LOCATION_FETCH_FAILURE',

  // SET_COORDS
  SET_TARGET_COORDS: 'SET_TARGET_COORDS',

  // STEPS NAVIGATIONS
  GO_TO_NEXT: 'NEW_LOCATION_GO_TO_NEXT_STEP',
  GO_TO: 'NEW_LOCATION_GO_TO_STEP',

  // PULL PREVIOUS ADD FORM LOCATION (NOT ACTIVATED)
  PULL_PREV_LOCATION_REQUEST: 'PULL_USER_PREVIOUS_LOCATION_REQUEST',
  PULL_PREV_LOCATION_SUCCESS: 'PULL_USER_PREVIOUS_LOCATION_SUCCESS',
  PULL_PREV_LOCATION_FAILURE: 'PULL_USER_PREVIOUS_LOCATION_FAILURE',

  ADD_LOCATION_FILE: 'ADD_LOCATION_FILE',
  UPDATE_LOCATION_FILES: 'UPDATE_LOCATION_FILES',
  LOCATION_IS_EDIT_FILES: 'LOCATION_IS_EDIT_FILES',

  // SET CONTENT ERROR
  SET_IMAGE_FEATURED_URLS_ERROR: 'SET_IMAGE_FEATURED_URLS_ERROR',

  // SPECIFIC SEARCH
  GET_SPECIFIC_LOCATION_REQUEST: 'GET_SPECIFIC_LOCATION_REQUEST',
  GET_SPECIFIC_LOCATION_SUCCESS: 'GET_SPECIFIC_LOCATION_SUCCESS',
  GET_SPECIFIC_LOCATION_FAILURE: 'GET_SPECIFIC_LOCATION_FAILURE',

  //CLEAR LOCATION LIST
  CLEAR_LOCATION_LIST: 'CLEAR_LOCATION_LIST'
}
