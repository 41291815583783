import React from 'react'
import {
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from 'reactstrap'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import LoginForm from '../../Forms/UserLoginForm'
import RegisterForm from '../../Forms/UserRegisterForm'
import AlertCustom from '../../_common/Alert'
import './styles.scss'
import { alertActions, modalActions } from '../../../_actions'


const AuthModal = props => {
  const { modal, isOpen } = props
  const activeTab = useSelector(state => state.modal.activeTab)
  const alert = useSelector(state => state.alert)
  const dispatch = useDispatch()

 const toggleTab = (tab) => {
   dispatch(modalActions.setActiveTab(tab))
   dispatch(alertActions.clear())
  }

  return (
      <Modal isOpen={isOpen} backdrop={'static'} centered>
        <div className="modal-header modal-header--alt">
          <button
            onClick={() => modal.close()}
            className="modal-header__toggler"
          />
        </div>
        <ModalBody className={'modal-body--alt'}>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === 'new_account',
                })}
                onClick={() => {
                  toggleTab('new_account')
                }}
              >
                <i className="icon icon-user-plus"/>
                <span>New Account</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === 'login',
                })}
                onClick={() => {
                  toggleTab('login')
                }}
              >
                <i className="icon icon-sign-out-alt"/>
                <span>Log In</span>
              </NavLink>
            </NavItem>
          </Nav>
          <div className="navbrand-holder logo-holder">
            <img
              src={require('../../../assets/images/logo.png')}
              alt="description"
            />
          </div>
          {alert.message && <AlertCustom alert={alert} />}
          <TabContent activeTab={activeTab}>
            <TabPane tabId="new_account">
              <Row>
                <Col sm="12">
                  <RegisterForm
                    type={'new_account'}
                    toggle={() => toggleTab('login')}
                    close={modal.close}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="login">
              <Row>
                <Col sm="12">
                  <LoginForm
                    close={modal.close}
                    type={'login'}
                    toggle={() => toggleTab('new_account')}
                  />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    )
}

export default AuthModal
