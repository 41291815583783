import { generalConstants } from '../_constants/config.constants'

const loadLocationTypes = () => {
  const requestOptions = {
    method: 'GET',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' }
  }

  return (
    fetch(
      `${generalConstants.BASIC_URL}/locations/location_types`,
      requestOptions
    )
      .then(handleResponse)
  )
}

const loadDetails = () => {
  const requestOptions = {
    method: 'GET',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' }
  }

  return (
    fetch(
      `${generalConstants.BASIC_URL}/locations/location_parameters`,
      requestOptions
    )
      .then(handleResponse)
  )
}

const loadGroupedKeywords = () => {
  const requestOptions = {
    method: 'GET',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' }
  }

  return (
    fetch(
      `${generalConstants.BASIC_URL}/keywords/types_list`,
      requestOptions
    )
      .then(handleResponse)
  )
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }
    return data
  })
}

export const optionsService = {
  loadLocationTypes,
  loadDetails,
  loadGroupedKeywords
}
