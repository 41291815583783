import React from 'react'
import { IconBlock } from '../../../components/_common/Typography'
import { Row, Col } from 'reactstrap'
import ContactManagerForm from '../../../components/Forms/ContactManagerForm'
import './styles.scss'

const text = [
  `
    <p>
      Although the Location Manager or equivalent key contact for the production 
      should be able to field any queries or concerns you might have, you can also 
      seek further advice from one of our experienced associate Location Managers 
      should you wish.
   </p>
   <p>
      Whether it relates to the contract, a stunt, health & safety, logistics 
      or any other element relating to filming on location please complete 
      the short form adjacent and your query will be passed on to the best 
      person to assist you. 
   </p>
  `
]

const ContactManager = () => {
  return (
    <Row className='justify-content-between'>
      <Col md={6}>
        <IconBlock
          icon={'comment-alt-lines'}
          smHidden={true}
          textContainer={{
            title: 'Contact a Location Manager ',
            text: text,
          }}
        />
      </Col>
      <Col md={6}>
        <ContactManagerForm />
      </Col>
    </Row>
  )
}

export default ContactManager
