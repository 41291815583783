import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

const PrivateRoute = props => {
  const {
    loggedIn,
    ...rest
  } = props

  return loggedIn ? (
    <Route {...rest} />
  ) : (
    <Redirect to={'/'} />
  )
}

const mapStateToProps = state => {
  const { loggedIn } = state.authentication
  return { loggedIn }
}

export default connect(
  mapStateToProps,
  null
)(PrivateRoute)
