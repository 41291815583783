import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Container, Row, Col, NavItem, NavLink, Nav } from 'reactstrap'
import LastNews from './LastNews'
import './styles.scss'

const socialMedia = [
  { icon: 'facebook-f', url: 'https://www.facebook.com/' },
  { icon: 'twitter', url: 'https://twitter.com/' },
  { icon: 'instagram', url: 'https://www.instagram.com/' },
  { icon: 'google-plus-g', url: 'https://www.google.com/' },
  { icon: 'pinterest', url: 'https://www.pinterest.com/' },
]

const Footer = () => {
  const isLogged = useSelector(state => state.authentication.loggedIn)

  return (
    <footer className="footer">
      <div className="footer__top">
        <Container>
          <Row>
            <Col lg="3">
              <div className="footerSection">
                <h4 className="footer__title">Useful info</h4>
                <Nav>
                  <NavItem>
                    <NavLink tag={Link} to="/help-support">
                      Help &#38; support
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/faq">
                      faqs
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/terms">
                      Terms &#38; conditions
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/privacy">
                      Privacy policy
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </Col>
            <Col lg="3">
              <div className="footerSection">
                <h4 className="footer__title">Useful pages</h4>
                <Nav>
                  <NavItem>
                    <NavLink tag={Link} to="/contact">
                      Contact kays
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/advertise">
                      Advertise With Kays
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/news">
                      News
                    </NavLink>
                  </NavItem>
                  {!isLogged && <NavItem>
                    <NavLink tag={Link} to="/industry_professionals_area">
                      Industry professionals sign-up
                    </NavLink>
                  </NavItem>}
                </Nav>
              </div>
            </Col>
            <Col lg="3">
              <div className="footerSection">
                <h4 className="footer__title">Get in touch</h4>
                <address>
                  <span>Kays Locations</span>
                  <br />
                  <span>Pinewood Studios</span>
                  <br />
                  <span>Pinewood Road</span>
                  <br />
                  <span>Iver</span>
                  <br />
                  <span>Buckinghamshire</span>
                  <br />
                  <span className="txt-item">SL0 0NH</span>
                  <br />
                  <strong className="phone">
                    Tel: <a href="tel: 01753307537">01753-307537</a>
                  </strong>
                </address>
              </div>
            </Col>
            <Col lg="3">
              <div className="footerSection last-col">
                <LastNews />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer__bottom">
        <Container>
          <Row className={'text-center text-lg-left'}>
            <Col
              lg="6"
              className={'order-2 order-lg-1 d-lg-flex align-items-center'}
            >
              <span>
                &#169; 2020 All rights reserved | Part of the KAYS group of
                companies
              </span>
            </Col>
            <Col lg="6" className={'order-1 order-lg-2 text-lg-right'}>
              <div className="social-list__holder">
                <span>Follow us on:</span>
                <ul className={'social-list'}>
                  {socialMedia.map(socialMediItem => (
                    <li
                      key={socialMediItem.icon}
                      className={'social-list__item'}
                    >
                      <a
                        href={socialMediItem.url}
                        className={'social-list__link'}
                      >
                        <i className={`icon icon-${socialMediItem.icon}`}></i>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
