import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import LocationDetailsFormContainer from '../../../components/Forms/AddLocationForm/LocationDetailsForm'
import { locationActions } from '../../../_actions'

const LocationDetails = ({ history }) => {
  const {
    storeValues,
    contentValues
  } = useSelector(state => ({
    storeValues: state.locations.newLocation.formValues,
    contentValues: state.locations.content
  }))
  const dispatch = useDispatch()
  const {
    goToNext,
    updateNewLocationValues
  } = (useCallback(() => ({
    goToNext: params => dispatch(locationActions.goToNext()),
    updateNewLocationValues: params => dispatch(locationActions.updateNewLocationValues(params)),
  }), [dispatch]))()

  const onSuccess = (val) => {
    updateNewLocationValues(val)
    goToNext()
  }

  return (
    <div className="details-location">
      <h4>Tell us about your location</h4>
      <LocationDetailsFormContainer defaultValues={storeValues} contentValues={contentValues} onSuccess={onSuccess} />
    </div>
  );
};

export default LocationDetails;
