import React from 'react'
import { withRouter } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Form, FormGroup, Input } from 'reactstrap'
import { newsActions } from '../../../_actions/news.actions'

const NewsSearch = ({ history }) => {
  const {
    keyword
  } = useSelector(state => state.news)
  const dispatch = useDispatch()
  const { setSearchKey } = {
    setSearchKey: payload => dispatch(newsActions.setSearchKey(payload))
  }

  const regex = RegExp(/\/news\//)
  const isPostPage = regex.test(history.location.pathname)

  const submit = (e) => {
    e.preventDefault()
  }

  const changeHandler = e => {
    e.preventDefault();
    if (isPostPage) history.push('/news')
    const value = e.target.value
    setSearchKey(value)
    return false;
  }

  return (
    <Form onSubmit={submit}>
      <FormGroup className="news-search__group">
        <i className="icon-search news-search__icon" />
        <Input className="news-search__input" value={keyword}  onChange={changeHandler} placeholder="Search"/>
      </FormGroup>
    </Form>
  )
}

export default withRouter(NewsSearch)
