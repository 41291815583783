import React from 'react'
import {
  Card,
  CardTitle,
  CardBody,
  CardText,
  CardImg,
} from 'reactstrap'
import { useSelector } from 'react-redux'
import './styles.scss'

const LocationDetailPreview = () => {
  const location = useSelector((state) => state.locations.singleLocation)

  const {
    powerSupply = {},
    parking = {},
    wifi = {},
    waterSource = {},
    city,
    county,
    name,
    imageFeaturedUrls,
    description = '',
    filmingAvailability = {},
    id
  } = location

  return (
    <Card className="location">
      {imageFeaturedUrls && <CardImg top width="100%" src={imageFeaturedUrls} />}
      <CardBody>
        <CardTitle tag={'h4'}>
          {name}
        </CardTitle>
        <CardText>
          <small>
            {[city, county].filter(e => e).join(', ') || null}
          </small>
        </CardText>
        <CardText>{description && description.length > 24 ? description.slice(0, 312) + '...' : description}</CardText>
        {id && powerSupply.name && <div>
          <div>
            <CardText><strong>Availability:</strong> {filmingAvailability.name}</CardText>
             <CardText className="text-item">
              {`${[powerSupply.name, parking.name, wifi.name, waterSource.name].filter(e => e).join(', ')} & more...`}
            </CardText>
          </div>
        </div>}
      </CardBody>
    </Card>
  )
}

export default LocationDetailPreview
