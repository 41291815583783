import React from 'react'

import LocationType from './LocationType'
import LocationDetails from './LocationDetails'
import BuillingDetails from './BuillingDetails'
import Confirmation from './Confirmation'

const Routes = ({ currentStep }) => {
  switch(currentStep) {
    case 1:
      return <LocationType />

    case 2:
      return <LocationDetails />

    case 3:
      return <BuillingDetails />

    case 4:
      return <Confirmation />

    default:
      return <LocationType />
  }
}

export default Routes
