import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Container } from 'reactstrap'

const SectionTitle = ({ title, children, customClass = 'text-center', containerClass = '' }) => {
  return (
      <Container className={containerClass}>
        <Row>
          <Col>
            <h1 className={`font-weight-black ${customClass} section-title`}>
              {title}
            </h1>
            {children}
          </Col>
        </Row>
      </Container>

  )
}

SectionTitle.propTypes = {
  title: PropTypes.string,
  customClass: PropTypes.string,
  containerClass: PropTypes.string,
  children: PropTypes.node,
}

export default SectionTitle
