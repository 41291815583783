import React, {useState} from 'react'
import Select from 'react-select'
import { Label } from 'reactstrap'
import './styles.scss'

const FormSelect = ({
  options = [],
  className = 'select-field-container',
  classNamePrefix = 'select-field',
  isDisabled,
  onInputChange,
  maximum,
  label = true,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const option = options.find(o => (typeof o.value === 'string' ? o.value.trim() : o.value) === (typeof props.value === 'string' ? props.value.trim() : props.value))
  const multiOptions = props.isMulti && props.values[props.name] && props.values[props.name].map((val) => {
    return options.find(o => (o.value === val))
  })
  const activeValue = props.isMulti ? multiOptions : option || null
  const handleMultiChange = evt => {
    const nextValue = evt && evt.map(option => option.value)
     props.setValues(maximum && props.values[props.name] && props.values[props.name].length >= maximum + 1 ?  {...props.values } : {...props.values, [props.name]: nextValue })
  }
  const handleChange = props.isMulti ?
    handleMultiChange :
    option => {
      if (props.onChange) {
        props.onChange(props.name, (option && option.value) || '', (option && option.label) || '')
      }
  }
  const handleBlur = () => {
    if (props.onBlur) {
      props.onBlur(props.name, true)
    }
  }

  const isMax = maximum && props.values[props.name] && props.values[props.name].length >= maximum ? 'maximum' : ''

  return (
    <>
      {label && <Label className={`${label ? 'select-label' : ''}`} htmlFor={props.name} >{props.placeholder}</Label>}
      <Select
        {...props}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        options={options}
        className={`${className} ${isOpen ? 'is-open' : ''} ${props.error && props.touched ? 'error' : ''} ${props.isMulti ? 'multi-select' : ''} ${isMax}`}
        classNamePrefix={classNamePrefix}
        onChange={handleChange}
        onBlur={handleBlur}
        onInputChange={onInputChange}
        value={activeValue}
        isDisabled={isDisabled}
      />
      {props.error && props.touched && (
        <div className="invalid-feedback">{props.error}</div>
      )}
    </>
  )
}

export default FormSelect
