import { newsConstants } from '../_constants/news.constants';

const initialState = {
  page: 1,
  perPage: 12,
  categoryId: null,
  archive_id: false,
  keyword: '',
  categories: {
    isLoaded: false,
    isError: false,
    data: []
  },
  dates: {
    isLoaded: false,
    isError: false,
    data: []
  },
  list: {
    isLoaded: false,
    isError: false,
    isLoading: false,
    data: []
  },
  latest: {
    isLoaded: false,
    isError: false,
    isLoading: false,
    data: []
  }
};

export default function(state = initialState, action = {}){
  switch (action.type) {
    case newsConstants.CATEGORY_REQUEST:
      return {
        ...state,
        categories: {
          isLoaded: false,
          isError: false,
          data: []
        }
      };

    case newsConstants.CATEGORY_SUCCESS:
      return {
        ...state,
        categories: {
          isLoaded: true,
          isError: false,
          data: action.payload
        }
      };

    case newsConstants.CATEGORY_FAILURE:
      return {
        ...state,
        categories: {
          isLoaded: false,
          isError: action.error,
          data: []
        }
      };

    case newsConstants.DATES_REQUEST:
      return {
        ...state,
        dates: {
          isLoaded: false,
          isError: false,
          data: []
        }
      };

    case newsConstants.DATES_SUCCESS:
      return {
        ...state,
        dates: {
          isLoaded: true,
          isError: false,
          data: action.payload.data.reverse()
        }
      };

    case newsConstants.DATES_FAILURE:
      return {
        ...state,
        dates: {
          isLoaded: false,
          isError: action.error,
          data: []
        }
      };

    case newsConstants.LIST_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isLoaded: false,
          isError: false
        }
      };

    case newsConstants.LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
          isLoaded: true,
          isError: false,
          data: state.list.data.concat(action.payload.data)
        }
      };

    case newsConstants.LIST_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          isLoaded: false,
          isError: action.payload,
          data: []
        }
      };

    case newsConstants.POST_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isLoaded: false,
          isError: false,
          isLoading: true
        }
      };

    case newsConstants.POST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isLoaded: true,
          isError: false,
          isLoading: false,
          data: state.list.data.concat([ action.payload ])
        }
      };

    case newsConstants.POST_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          isLoaded: false,
          isLoading: false,
          isError: action.payload
        }
      };

    case newsConstants.CLEAR_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          isLoaded: false,
          isError: false,
          data: []
        }
      };

    case newsConstants.INCREASE_PAGE:
      return {
        ...state,
        keyword: '',
        page: state.page + 1
      };

    case newsConstants.CHANGE_CATEGORY:
      return {
        ...state,
        keyword: '',
        page: 1,
        categoryId: action.payload
      };

    case newsConstants.CHANGE_FILTER:
      const { categoryId, perPage, page } = action.payload;
      return {
        ...state,
        keyword: '',
        page,
        perPage,
        categoryId
      };

    case newsConstants.CHANGE_DATE:
      return {
        ...state,
        keyword: '',
        archive_id: action.payload
      };

    case newsConstants.LATEST_NEWS_REQUEST:
      return {
        ...state,
        latest: {
          ...state.latest,
          isLoaded: false,
          isError: false,
          isLoading: true
        }
      };

    case newsConstants.LATEST_NEWS_SUCCESS:
      return {
        ...state,
        latest: {
          ...state.latest,
          ...action.payload,
          isLoaded: true,
          isError: false,
          isLoading: false
        }
      };

    case newsConstants.LATEST_NEWS_FAILURE:
      return {
        ...state,
        latest: {
          ...state.latest,
          isLoaded: false,
          isLoading: false,
          isError: action.payload
        }
      };

    case newsConstants.SEARCH_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isLoaded: false,
          isError: false
        }
      };

    case newsConstants.SEARCH_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
          to: 500,
          isLoaded: true,
          isError: false,
          data: action.payload.data
        }
      };

    case newsConstants.SEARCH_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          isLoaded: false,
          isError: action.payload,
          data: []
        }
      };

    case newsConstants.SET_SEARCH_KEY:
      return {
        ...state,
        keyword: action.payload,
      }

    default:
      return state;
  }
}
