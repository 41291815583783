import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useSelector, useDispatch  } from 'react-redux'
import { locationActions } from '../../../_actions'
import { Container, Row, Col, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Map, Marker } from 'google-maps-react'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/scss/image-gallery.scss'
import AddToFavorite from '../../../components/_common/AddToFavorites'
import Preloader from '../../../components/_common/Preloader'
import Accordion from '../../../components/_common/Accordion'
import Ameneties from './Ameneties'
import { normalizeAmeneties, initMap } from '../../../_helpers'
import ShareOnBlock from '../../../components/_common/ShareOnBlock'
import ContactAutorized from './ContactBar/ContactAutorized'
import NonAutorized from './ContactBar/ContactNotAutorized'
import ContactModal from './ContactBar/ContactModal'
import { useMediaQuery } from 'react-responsive'
import './style.scss'


const LocationPage = props => {
  const location = useSelector(state => state.locations.singleLocation)
  const locationsState = useSelector(state => state.locations)
  const isLogged = useSelector(state => state.authentication.loggedIn)
  const dispatch = useDispatch()
  const loadLocation = () => {
    dispatch(locationActions.getById(props.match.params.id))
  }
  const [modal, setModal] = useState(false)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' })
  const setStateModal = (state) => () => {
    setModal(state)
  }

  const [isOpen, setOpen] = useState(false)
  const [height, setHeight] = useState(null)
  const [heightItem, setHeightItem] = useState(null)
  const [isGoogleMapsLoaded, trigger] = useState(false)


  const ref = useRef(null)

  const handler = useCallback((e) => {
    setHeight( handler.nodes.list && handler.nodes.list.offsetHeight)
    setHeightItem( handler.nodes.item && handler.nodes.item.offsetHeight)
  }, [])

  const r = useCallback((list) => {
    handler.nodes = {
      list,
      item: ref.current
    }
    handler()
    window.addEventListener('resize', handler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => () => {
    window.removeEventListener('resize', handler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpenClick = () => {
    setOpen(!isOpen)
  }

  const [modalIsOpen, setModalNotAuth] = useState(false)
  const [activeTab, setActiveTab] = useState('')

  const modalClose = () => {
    setModalNotAuth(false)
  }

  const modalOpen = () => {
    setModalNotAuth(true)
  }

  const actionHandler = (activeTab) => () => {
    setModal(false)
    setActiveTab(activeTab)
    setModalNotAuth(true)
  }


  useEffect(() => {
    loadLocation()
    let isSubscribed = true
    initMap().then(() => {
      if(isSubscribed) trigger(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => isSubscribed = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if(!location){
    props.history.push('/locations')
    return null
  }

  const features = location.id &&  [
    {headerTitle: 'Special features', content: `${'Futher info: '} ${location.keywords.map(item => item.name).join(', ')}`},
    {headerTitle: 'Filming availability', content: `${'Futher info: '} ${location.filmingAvailability.name}${location.filmingDetails ? ', ' + location.filmingDetails : ''}`},
    {headerTitle: 'Parking availability', content: `${'Futher info: '} ${location.parking.name}${location.parkingDetails ? ', '+ location.parkingDetails : ''}`},
    {headerTitle: 'Additional information', content: `${'Futher info: '} ${location.additionalInfo.map(item => item.name).join(', ')}`},
    {headerTitle: 'Floor plans', content: location.pdfUrls.map((item, idx) => (`<a href=${item} target="_blank" rel="noopener noreferrer">PDF ${idx + 1} </a>`))},
  ]

  const normalizePhoto = () =>  {
    return [...location.imageFeaturedUrls, ...location.imageUrls]
      .map(item => ({
        src: item,
        original: item,
        thumbnail: item
    }))
  }

  const videoPlayer = () => {
    switch (location.videoType) {
      case 'matterport':
        return <iframe
          width="853"
          height="480"
          src={location.videoUrl}
          frameBorder="0"
          allowFullScreen
          allow="vr"
          title="This is a unique title"
        />
      case 'vimeo':
        return <iframe
          width="853"
          height="480"
          src={location.videoUrl}
          frameBorder="0"
          allowFullScreen
          allow="autoplay"
          title="This is a unique title"
        />
      case 'youtube':
        return <iframe
          width="560"
          height="315"
          src={location.videoUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="This is a unique title"
        />
      default:
        return <iframe
          width="560"
          height="315"
          src={location.videoUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="This is a unique title"
        />
    }
  }

  const addToFavorite = (id) => {
    dispatch(locationActions.addFavoriteMain(id))
  }

  const removeFromFavorite = (id) => {
    dispatch(locationActions.removeFavoriteMain(id))
  }

  const _handleClick = () => {
    location.isFavourite ? removeFromFavorite(location.id) : addToFavorite(location.id)
  }

  const pathMove = () => {
    const { fromLocations, searchLocation, searchCategory, category } = props.location.state || {}
    switch (fromLocations) {
      case 'locations':
        const string = category || searchLocation ? `to ${[category && category.label, searchLocation && searchLocation].filter(e => e).join(', ')}` : 'to locations'
        return <Link className="gallery-link" to={`/locations?search-location=${searchLocation || ''}&search-category=${searchCategory || ''}`}>
          Back <b>{isLogged ? string : 'to locations'}</b>
        </Link>
      case 'favorites':
        return <Link className="gallery-link" to='/favorites'>
          Back <b>{`to favorites`}</b>
        </Link>
      case 'location_list':
        return <Link className="gallery-link" to='/location_list'>
          Back <b>{`to my locations`}</b>
        </Link>
      case '/':
        return <Link className="gallery-link" to='/'>
          Back <b>{`to home page`}</b>
        </Link>
      default: return <Link className="gallery-link" to='/locations'>
          Back <b>to locations</b>
        </Link>
    }
  }


  return (
    <section className="section-content single-handle-content">
      <Container>
        <Preloader status={locationsState.loading}/>
        {locationsState.isError && <p className="text-warning">We got some error</p>}
        {!locationsState.loading && !locationsState.isError && <div>
        <ContactModal
          modal={modal}
          setModalState={setStateModal}
          location={location}
          actionHandler={actionHandler}
          modalIsOpen={modalIsOpen}
          activeTab={activeTab}
          modalOpen={modalOpen}
          modalClose={modalClose}
        />
        <Row>
          <Col className="col-12 col-lg-9">
            <div className="gallery-content">
              <div className="title">
                <div className="gallery-title">
                  <h3>{isLogged ? location.name : 'Ref ' + location.id}</h3>
                  {pathMove()}
                </div>
                <div className="right">
                  {isLogged ? <div className="icon-holder">
                    <AddToFavorite isFavourite={location.isFavourite} id={location.id} action={_handleClick} />
                  </div> : null}
                  {isTabletOrMobile ? <Button color="primary" className="btn-enquire text-uppercase" onClick={setStateModal(true)}>Enquire</Button> : null}
                </div>
              </div>
              <div>
                {location.id && (!!location.imageFeaturedUrls.length || !!location.imageUrls.length) && <ImageGallery showThumbnails={location.imageUrls.length > 1} items={normalizePhoto()} showBullets={false} showPlayButton={false} showIndex={true} />}
              </div>
              <div className="gallery-box">
                <h3 className="gallery-box-title">About</h3>
                <p>
                  {location.description}
                </p>
                {location.webUrl && <p><strong><span>Website: </span><a href={`http://${location.webUrl}`} target="_blank">{location.webUrl}</a></strong></p>}
                <hr />
                <div className="keyword-list-wrapper"  style={{height: isOpen ? 'auto' : heightItem * 2, overflow: 'hidden'}}>
                  <ul className="keyword-list" ref={r}>
                    {location.keywords && location.keywords.map((item, index) => {
                      return <div ref={!index ? ref : null} className="keywords-item-wrapper"  key={index}><li className="keywords-item" >{item.name}</li></div>
                    })}
                  </ul>
                </div>
                {height > heightItem * 2 && <div className="text-center"><Button color="transparent" size="sm" className="btn btn-link"  onClick={handleOpenClick}>{isOpen ? 'Show less' : 'Show more'}</Button></div>}

              </div>
              <div className="gallery-box">
                <h3 className="gallery-box-title">Key information</h3>
                {location.id && <Ameneties location={normalizeAmeneties(location)}/>}
              </div>
              <div className="gallery-box accordion-box">
                {location.id && <Accordion cards={features}/>}
              </div>
              {isLogged && isGoogleMapsLoaded ? <div className="gallery-box">
                {location.id &&  <div className="google-map">
                  <Map
                    google={window.google}
                    initialCenter={{
                      lat: location.latitude,
                      lng: location.longitude
                    }}
                    zoom={8}
                    fullscreenControl={false}
                    mapTypeControl={false}
                    streetViewControl={false}
                  >
                    <Marker
                      name={location.name}
                      title={location.name}
                      position={{lat: location.latitude, lng: location.longitude}}
                    />
                  </Map>
                </div>}
              </div> : null}

              {location && location.videoUrl && <div className="gallery-box iframe-holder">
                <h3 className="gallery-box-title">Virtual tour</h3>
                {videoPlayer()}
              </div>}
            </div>
          </Col>
          <Col className="col-12 col-lg-3">
            <div className="sticky-sidebar">
              {!isTabletOrMobile && <div className="contact-side-bar">
                <h3 className="contact-title">Contact this location</h3>
                {isLogged ? <ContactAutorized location={location}/> : <NonAutorized />}
              </div>}
              <div className="contact-social">
                <ShareOnBlock />
              </div>
            </div>
          </Col>
        </Row>
        </div>}
      </Container>
    </section>
  )
}

export default LocationPage
