import React from 'react'
import { Formik } from 'formik'
import ContactManagerForm from './ContactManagerForm'
import { contactManagerSchema } from '../ValidationSchema/index'
import { formValidationActions } from '../../../_actions'
import { useDispatch } from 'react-redux'

const ContactFormContainer = props => {
  const dispatch = useDispatch()

  const onSubmit = (val, { resetForm }) => {
    dispatch(formValidationActions.sendDataLocationManager(val))
    resetForm({
      name: '',
      email: '',
      contact_number: '',
      location_link: '',
      location_address: '',
      preferred_time: '',
      enquire: '',
    })
  }
  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        contact_number: '',
        location_link: '',
        location_address: '',
        preferred_time: '',
        enquire: '',
      }}
      enableReinitialize
      validationSchema={contactManagerSchema}
      onSubmit={onSubmit}
    >
      {({ submitForm, isSubmitting, values, handleReset, ...props }) => {
        return (
          <ContactManagerForm
            isSubmitting={isSubmitting}
            values={values}
            {...props}
          />
        )
      }}
    </Formik>
  )
}

export default ContactFormContainer
