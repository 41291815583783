import React from 'react'
import FloatingLabel from 'floating-label-react'
import {Input} from 'reactstrap'
import './styles.scss'

const FormInput = ({
  type,
  name,
  placeholder,
  className,
  touched,
  errMessage,
  ...props
}) => { 
      
  if(type === 'checkbox' || type === 'radio'){
    return (
      <Input
        id={name}
        name={name}
        placeholder={placeholder}
        type={type}
        className={className}
        value={props.value}
        onChange={props.onChange}
        touched={touched ? touched.toString() : ''}
        {...props}
      />
    )
  }
  return (
    <FloatingLabel
      id={name}
      label={name}
      name={name}
      placeholder={placeholder}
      type={type}
      className={`custom-float-label ${className || ''}`}
      value={props.value}
      onChange={props.onChange}
      touched={touched ? touched.toString() : ''}
      component={type === 'textarea' ? 'textarea' : 'input'}
      {...props}
    />
  )
}

export default FormInput;
