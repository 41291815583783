import React from 'react'
import './styles.scss'

const Ameneties = props => {
  const { location } = props
  return (
    <div className="ameneties-container">
      <ul className="listreset">
        {location.map((item, index) => {
          return (
          <li className={item.status ? "amenetiesItem" : "amenetiesItem disabled"} key={index}>
            <i className={`icon icon-${item.icon}`}/>
            {item.description}
          </li>)
        })}
      </ul>
    </div> 
  )
}

export default Ameneties
