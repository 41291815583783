import React from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import './styles.scss'
import {useDispatch} from 'react-redux';
import {locationActions} from '../../_actions/location.actions';

const ResetModal = props => {
  const dispatch = useDispatch()

  const {
    modal,
    setModalState,
    modalClose,
    gotoRoute
  } = props

const confirm = () => {
  gotoRoute('/add-location')
  dispatch(locationActions.resetNewLocationValues())
  modalClose()
}

  return(
    <>
      <Modal isOpen={modal} toggle={setModalState(false)} className="contact-modal modal-dialog-centered">
        <ModalBody>
          <Button className="btn-close" color="transparent" onClick={setModalState(false)}><i className="icon-times"></i></Button>
          <ModalHeader>
            Confirmation
          </ModalHeader>
          <div className="contact-holder not-authorised">
            <i className="icon icon-info-circle"></i>
            <span>
               All unsaved data will be lost. Are you sure you want to create new location?
              </span>
          </div>
          <div className="btns">
            <Button className="text-uppercase" color="outline-primary" onClick={confirm}>YES</Button>
            <Button className="text-uppercase" color="primary" onClick={modalClose}>NO</Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ResetModal
