import React, { useEffect, useState } from 'react'
import { Container, Row, Col, FormGroup, Form } from 'reactstrap'
import SectionTitle from '../../components/_common/SectionTitle/SectionTitle'
import HeroBgImage from '../../components/_common/HeroBgImage/HeroBgImage'
import CardFavorite from '../../components/_common/Card/CardFavorite'
import HeroImage from '../../assets/images/account-hero-img.jpg'
import PaginationComponent from '../../components/_common/PaginationComponent'
import { useSelector, useDispatch } from 'react-redux'
import Preloader from '../../components/_common/Preloader'
import { searchFavorites } from '../../_helpers'
import FormSelect from '../../components/_common/FormSelect/FormSelect'

const sortBy = [{label: 'Date added', value: 'date'}, {label: 'Name', value: 'name'}]

const Favorites = props => {
  const searchParams = new URLSearchParams(window.location.search)
  const [searchSort, setSearchSort] = useState(searchParams.get('search-sort'))
  const [searchSelect, setSearchSelect] = useState(searchParams.get('category_ids'))
  const locations = useSelector(state => state.locations.favoritesList.data)
  const categories = useSelector(state => state.locations.favoritesList.categories)
  const locationsState = useSelector(state => state.locations)
  const dispatch = useDispatch()
  const loadLocations = () => {
    searchFavorites(dispatch)
  }

  const categoryOptions = categories && categories.reduce((result, key, idx) => {
    if(idx === 0) result.push({label: 'All', value: '' })
    result.push({label: key.name, value: key.id })
    return result
  }, [])

  const changePage = (page) => {
    const {history} = props
    const {search, pathname} = window.location
    const params = new URLSearchParams(search)
    params.set('page', page)
    history.replace(`${pathname}?${params.toString()}`)
    setTimeout(() => {
      searchFavorites(dispatch)
    })
  }

  const sortSelect = (value, searchParam) =>  {
    setSearchSort(searchParam)
    const {history} = props
    const {search, pathname} = window.location
    const params = new URLSearchParams(search)
    params.set('search-sort', searchParam)
    history.replace(`${pathname}?${params.toString()}`)
    setTimeout(() => {
      searchFavorites(dispatch)
    })
  }


  const sortCategorySelect = (value, id) => {
    setSearchSelect(id)
    const {history} = props
    const {search, pathname} = window.location
    const params = new URLSearchParams(search)
    params.set('category_ids', id)
    history.replace(`${pathname}?${params.toString()}`)
    setTimeout(() => {
      searchFavorites(dispatch)
    })
  }

  useEffect(() => {
    loadLocations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <HeroBgImage backgroundImg={HeroImage} type={'simple'} />
      <Preloader status={locationsState.loading}/>
      {!locationsState.loading && !locationsState.isError &&
      <>
        <section className="section-content favourites-area">
          <SectionTitle title="My Favourites" containerClass="sort-heading">
            <Form className="sort-form">
              <Row form className={'justify-content-end'}>
                <FormGroup>
                  <FormSelect
                    name='category'
                    id='category'
                    value={searchSelect}
                    onChange={sortCategorySelect}
                    placeholder="Category"
                    options={categoryOptions}
                    label={'false'}
                  />
                </FormGroup>
                <FormGroup className="sortBy">
                  <FormSelect
                    id={'sortBy'}
                    name={'sortBy'}
                    options={sortBy}
                    placeholder={'Sort By'}
                    onChange={sortSelect}
                    value={searchSort}
                    label={'false'}
                  />
                </FormGroup>
              </Row>
            </Form>
          </SectionTitle>

          <Container>

            <Row>
              {locations && locations.map((card, index) => (
                <Col lg={4} key={card.id} className={'favour'}>
                  <CardFavorite data={card} />
                </Col>
              ))}
            </Row>
            <Row>
              <Col>
                {!!locationsState.favoritesList.total && <PaginationComponent
                  totalItems={locationsState.favoritesList.total}
                  pageSize={locationsState.favoritesList.per_page}
                  activePage={locationsState.favoritesList.current_page}
                  previousPageText={'<'}
                  nextPageText={'>'}
                  maxPaginationNumbers={Math.ceil(locationsState.favoritesList.total / 6)}
                  navContainerClass={
                    'd-flex align-items-center justify-content-center'
                  }
                  onSelect={changePage}
                />}
              </Col>
            </Row>
          </Container>
        </section>
      </>}
    </>
  )
}

export default Favorites
