import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Label, Spinner } from 'reactstrap'
import { Form, Field } from 'formik'
import { Elements } from 'react-stripe-elements'
import RequestButton from '../../../_common/RequestButton'
import FormikInput from '../../../_common/FormikInput/FormikInput'
import AddressForm from '../../AddressForm/AddressForm'
import StripeForm from '../../StripeForm/StripeForm'
import { optionsActions, locationActions } from '../../../../_actions'
import BuillingPlan from './BuillingPlan'
import PaymentCards from '../../../PaymentCards'

const LocationTypeForm = ({ submitForm, isSubmitting, onChange, ...props }) => {
  const { formOptions, storeValues, cardId } = useSelector(store => ({
    formOptions: store.formOptions,
    storeValues: store.locations.newLocation.formValues,
    cardId: store.billing.activeCard.id
  }))

  const dispatch = useDispatch()
  const loadLocationTypes = () => dispatch(optionsActions.loadLocationTypes())
  const goTo = payload => dispatch(locationActions.goTo(payload))

  useEffect(() => {
    if (!storeValues.locationType) {
      goTo(1)
    }
    if (!formOptions.locationTypes.isLoad) {
      loadLocationTypes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (cardId) {
      props.setFieldValue('cardId', cardId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardId])

  const isAddressSameHandler = value => {
    if (value) {
      props.setValues({
        ...props.values,
        postCode: storeValues.postCode || '',
        building: storeValues.building || '',
        addressLine1: storeValues.addressLine1 || '',
        addressLine2: storeValues.addressLine2 || '',
        city: storeValues.city || '',
        county: storeValues.county || '',
        latitude: storeValues.latitude || '',
        longitude: storeValues.longitude || '',
      })
    } else {
      props.setValues({
        ...props.values,
        postCode: '',
        building: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        county: '',
        latitude: '',
        longitude: ''
      })
    }
    props.setFieldValue('isAddressSame', value)
  }

  const currentLocationType = formOptions.locationTypes.data.find(
    item => item.id === storeValues.locationType
  )


  const totalPrice = props.values.paymentPeriod === 'month' && currentLocationType ? currentLocationType.monthPrice : props.values.paymentPeriod === 'year' && currentLocationType ? currentLocationType.yearPrice : '0'

  return (
    <Form onSubmit={props.handleSubmit}>
      {formOptions.locationTypes.isLoad ? (
        <div>
          <Row>
            <Col xs={12} className="location-panel">
              <BuillingPlan
                currentLocationType={currentLocationType}
                fieldData={formOptions.paymentPeriod.data}
                name="paymentPeriod"
                totalPrice={totalPrice}
                value={props.values.paymentPeriod}
                error={props.errors.paymentPeriod}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                placeholder={formOptions.paymentPeriod.data.name}
                touched={props.touched.paymentPeriod}
                options={formOptions.paymentPeriod.data.options}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} className="location-panel">
              <h5 className="location-detail-title">Billing address</h5>
              <Row>
                <Col sm={6}>
                  <Label className="check-item">
                    <span className="lable">Same as my location address</span>
                    <Field
                      component={FormikInput}
                      name="isAddressSame"
                      type="checkbox"
                      error={props.errors.isAddressSame}
                      onChange={() => isAddressSameHandler(true)}
                      onBlur={props.setFieldTouched}
                      touched={props.touched.isAddressSame}
                      checked={props.values.isAddressSame ? 'checked' : ''}
                    />
                    <span className="check"></span>
                  </Label>
                </Col>
                <Col sm={6}>
                  <Label className="check-item">
                    <span className="lable">Add a new address</span>
                    <Field
                      component={FormikInput}
                      name="isAddressSame"
                      type="checkbox"
                      error={props.errors.isAddressSame}
                      onChange={() => isAddressSameHandler(false)}
                      onBlur={props.setFieldTouched}
                      touched={props.touched.isAddressSame}
                      checked={props.values.isAddressSame ? '' : 'checked'}
                    />
                    <span className="check"></span>
                  </Label>
                </Col>
              </Row>
              <AddressForm {...props} />
            </Col>
          </Row>

          <Row>
            <Col xs={12} className="location-panel">
              <h5 className="location-detail-title">Payment method</h5>
              <PaymentCards activateOption={true} removeOption={false} />
              <h5 className="location-detail-title">Add new card</h5>
              <Elements>
                <StripeForm/>
              </Elements>
              {props.errors.cardId && props.touched.cardId ? <div className={'invalid-feedback'}>{props.errors.cardId}</div> : null}
            </Col>
          </Row>

          <Row>
            <Col xs={12} className="location-panel with-chk-txt">
              <h5 className="location-detail-title">Billing address</h5>
              <div className="chk-row clearfix">
                <Label className="check-item">
                  <Field
                    component={FormikInput}
                    name="declaration"
                    type="checkbox"
                    error={props.errors.isAddressSame}
                    onChange={props.handleChange}
                    onBlur={props.setFieldTouched}
                    touched={props.touched.declaration}
                    checked={props.values.declaration ? 'checked' : ''}
                  />
                  <span className="check privacy-check"></span>
                </Label>
                <p className="privacy-description">
                  By signing up to a location listing subscription, you agree to I have read the{' '}
                  <Link to="/terms" target="_blank">
                    terms and conditions
                  </Link>{' '}
                  and that the details you have submitted will be used to create your user account
                  in accordance with our {' '}
                  <Link to="/privacy" target="_blank">
                    privacy policy
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
          <Row className="total-payment-holder">
            <h6>Total: &#163;{totalPrice}</h6>
            <RequestButton
              isLoading={props.isRequestSended}
              className="text-uppercase"
              type="submit">
              make payment
            </RequestButton>
            {props.type === 'success' && (
              <span className={`text-${props.type}`}>Thanks, your message has been sent</span>
            )}
          </Row>
        </div>
      ) : formOptions.locationTypes.isError ? (
        <p>Failed to load plans</p>
      ) : (
        <Spinner color="dark" size="lg" />
      )}
    </Form>
  )
}

export default LocationTypeForm
