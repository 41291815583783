import { validationConstants } from '../_constants'
import { formService } from '../_services/contact.service'

export const formValidationActions = {
  sendData,
  sendDataLocation,
  sendDataLocationManager
}

function sendData(data) {
  return dispatch => {
    formService.sendForm(data).then(message => {
      dispatch(success(message.text))
    })
  }

  function success(message) {
    return { type: validationConstants.SUCCESS, message }
  }

  // function error(message) {
  //   return { type: validationConstants.ERROR, message }
  // }

  // function clear() {
  //   return { type: validationConstants.CLEAR }
  // }
}

function sendDataLocation(data, id) {
  return dispatch => {
    formService.sendFormLocation(data, id).then(message => {
      dispatch(success(message.text))
    }).catch(e => dispatch(error(e.text)))
  }

  function error(message) {
    return { type: validationConstants.ERROR, message }
  }

  function success(message) {
    return { type: validationConstants.SUCCESS, message }
  }
}


function sendDataLocationManager(data) {
  return dispatch => {
    formService.sendFormLocationManager(data).then(message => {
      dispatch(success(message.text))
    }).catch(e => dispatch(error(e.text)))
  }

  function error(message) {
    return { type: validationConstants.ERROR, message }
  }

  function success(message) {
    return { type: validationConstants.SUCCESS, message }
  }
}