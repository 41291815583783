import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap'
import { withRouter } from 'react-router'
import AuthModal from '../_common/Modals/AuthModal'
import classNames from 'classnames'
import './styles.scss'
import ForgotModal from '../_common/Modals/ForgotModal'
import {locationActions} from '../../_actions';
import {useDispatch, useSelector} from 'react-redux';
import ResetModal from './ResetModal';

const socialList = ['facebook', 'twitter-square', 'instagram']

const Header = props => {
  const isIndustrial = useSelector(state => state.authentication.is_industrial)

  const [isSticky, setHeaderSticky] = useState(false)
  const [isOpen, toggle] = useState(false)
  const headerClasses = classNames('app-header fixed-top', {
    'home-page-header': props.match.isExact,
    'isHeader-bg': isSticky === true,
  })
  const toggleBtnView = () => {
    return props.loggedIn ? (
      <>
        <UncontrolledDropdown setActiveFromChild>
          <DropdownToggle tag="a" className="nav-link btn btn-outline-primary" caret>
            <i className={'icon icon-user-cog d-lg-none'}/>
            <span className={'navLink-text'}>My Account</span>
          </DropdownToggle>
          <DropdownMenu className="sub-nav">
            <DropdownItem
              tag={Link}
              to="/account"
              className={'btn'}
              onClick={closeNavbar}
            >
              <span className={'navLink-text'}>My Account</span>
            </DropdownItem>
            {!isIndustrial && <DropdownItem
              tag={Link}
              to="/location_list"
              className={'btn'}
              onClick={closeNavbar}
            >
              <span className={'navLink-text'}>My Listing</span>
            </DropdownItem>}
            <DropdownItem
              tag={Link}
              to="/profile"
              className={'btn'}
              onClick={closeNavbar}
            >
              <span className={'navLink-text'}>My Details</span>
            </DropdownItem>
            <DropdownItem
              tag={Link}
              to="/favorites"
              className={'btn'}
              onClick={closeNavbar}
            >
              <span className={'navLink-text'}>My Favourites</span>
            </DropdownItem>
            {!isIndustrial && <DropdownItem
              tag={Link}
              to="/billing"
              className={'btn'}
              onClick={closeNavbar}
            >
              <span className={'navLink-text'}>Billing</span>
            </DropdownItem>}
            <DropdownItem
              tag={Link}
              to="/documentation"
              className={'btn'}
              onClick={closeNavbar}
            >
              <span className={'navLink-text'}>Documentation</span>
            </DropdownItem>
            <DropdownItem
              to=""
              className="d-none d-lg-block log-holder"
              tag={Link}
              onClick={logout}
            >
              <i className={'icon icon-sign-out-alt'}/>
              <span className={'navLink-text'}>Log Out</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    ) : (
      <NavLink
        className={'btn btn-outline-primary'}
        onClick={() => gotoRoute('/')}
      >
        <i className={'icon icon-user-cog d-lg-none'}/>
        <span className={'navLink-text'}>SIGN IN</span>
      </NavLink>
    )
  }

  useEffect(() => {
    const header = document.getElementById('header')
    const sticky = header.offsetTop
    const scrollCallBack = window.addEventListener('scroll', () => {
      setHeaderSticky(window.pageYOffset > sticky)
    })
    return () => {
      window.removeEventListener('scroll', scrollCallBack)
    }
  }, [])

  const openNavbar = () => {
    toggle(true)
  }

  const closeNavbar = () => {
    toggle(false)
  }

  const logout = () => {
    props.logout()
    closeNavbar()
  }

  const {
    stepsNav
  } = useSelector(store => ({
    stepsNav: store.locations.newLocation.stepsNav,
  }))

  const dispatch = useDispatch()
  const [modal, setModal] = useState(false)
  const setStateModal = (state) => () => {
    setModal(state)
  }

  const modalClose = () => {
    setModal(false)
  }


  const gotoRoute = pathToRoute => {
    if (!props.loggedIn) {
      !props.modalIsOpen ? props.modalOpen({path: pathToRoute, tab: pathToRoute === '/add-location' ? 'new_account' : 'login' }) : props.modalClose()
    } else {
      props.history.push(pathToRoute)
      closeNavbar()
    }
  }

  const listLocation = () => () => {
    if(stepsNav.currentStep === 2 || stepsNav.currentStep === 3) {
      setModal(true)
    }else {
      gotoRoute('/add-location')
      dispatch(locationActions.resetNewLocationValues())
    }
  }



  return (
    <header className={headerClasses} id={'header'}>
      <ResetModal
        modal={modal}
        setModalState={setStateModal}
        gotoRoute={gotoRoute}
        modalClose={modalClose}
      />
      <Navbar light expand="lg">
        <Link className="navbar-brand" to="/">kays location logo</Link>
        <NavbarToggler onClick={openNavbar} />
        <Collapse isOpen={isOpen} navbar>
          <div className="toggler-holder">
            <NavbarToggler
              onClick={closeNavbar}
              className={'navbar-toggler__close'}
            />
          </div>
          <div className={'nav-container'}>
            <Nav navbar>
              <NavItem className="nav-home">
                <NavLink onClick={closeNavbar} tag={Link} to="/">
                  <i className={'icon icon-home d-lg-none'}/>
                  <span className={'navLink-text'}>Home</span>
                </NavLink>
              </NavItem>
              <NavItem className="nav-about">
                <NavLink onClick={closeNavbar} tag={Link} to="/about">
                  <i className={'icon icon-info-circle d-lg-none'}/>
                  <span className={'navLink-text'}>About</span>
                </NavLink>
              </NavItem>
              <NavItem className="nav-news">
                <NavLink onClick={closeNavbar} tag={Link} to="/all-categories">
                  <i className={'icon icon-newspaper d-lg-none'}/>
                  <span className={'navLink-text'}>All Locations</span>
                </NavLink>
              </NavItem>
              <NavItem className="nav-location">
                {!isIndustrial && <NavLink
                  color="primary"
                  className={'btn btn-primary btn-white'}
                  // onClick={() => gotoRoute('/location_list')}
                  // onClick={() => {
                  //   gotoRoute('/add-location')
                  //   dispatch(locationActions.resetNewLocationValues())
                  // }}
                  onClick={listLocation()}

                >
                  <i className={'icon icon-map-marked-alt d-lg-none'}/>
                  <span className={'navLink-text'}>List your location</span>
                </NavLink>}
                <AuthModal
                  isOpen={props.modalIsOpen}
                  activeTab={'new_account'}
                  modal={{ open: props.modalOpen, close: props.modalClose }}
                />
                <ForgotModal/>
              </NavItem>
              <NavItem className="nav-account">{toggleBtnView()}</NavItem>
              {props.loggedIn && <NavItem className="d-lg-none">
                <NavLink
                  color="primary"
                  className={'btn btn-primary'}
                  onClick={logout}
                >
                  <i className={'icon icon-map-marked-alt'}/>
                  <span className={'navLink-text'}>Log out</span>
                </NavLink>
              </NavItem>}
            </Nav>
            <ul className="social-list">
              {socialList.map((icon, index) => (
                <li className="social-list__item" key={icon + index}>
                  <a href="/" className="social-list__link">
                    <i className={`icon icon-${icon}`}/>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </Collapse>
      </Navbar>
    </header>
  )
}

export default withRouter(Header)
