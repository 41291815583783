import React, {Component} from 'react';
import { connect } from 'react-redux'
import {CardExpiryElement, CardCvcElement, CardNumberElement, injectStripe} from 'react-stripe-elements';
import FormInput from '../../_common/FormInput/FormInput';
import { billingActions } from '../../../_actions/billing.actions'
import './styles.scss';

class StripeForm extends Component {
  state = {
    complete: false,
    defaultPayment: this.props.defaultOption,
    cardName: '',
    cardNameError: '',
    cardNumberError: '',
    cardExpiryError: '',
    cardCvcError: '',
    errorMessage: '',
    isLoading: false
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({isLoading: true, complete: false, errorMessage: ''})
    const stripe = await this.props.stripe.createToken({type: 'card', name: this.state.cardName}) || {};
    if (stripe.token) {
      this.props.addCard(stripe.token.id, this.state.defaultPayment)
        .then(() => this.setState({complete: true}))
        .catch(err => this.setState({errorMessage: err.toString()}))
        .finally(() => this.setState({isLoading: false}))
    }
  }

  handleStripeChange = (stripe = {}) => {
    this.setState({errorMessage: ''})
    if (stripe.elementType === 'cardNumber') {
      this.setState({cardNumberError: stripe.error ? stripe.error.message : ''})
    }
    if (stripe.elementType === 'cardExpiry') {
      this.setState({cardExpiryError: stripe.error ? stripe.error.message : ''})
    }
    if (stripe.elementType === 'cardCvc') {
      this.setState({cardCvcError: stripe.error ? stripe.error.message : ''})
    }
  }

  handleInputChange = ({target}) => {
    this.setState({cardName: target.value})
  }

  handleCheckboxChange = () => {
    this.setState({defaultPayment: !this.state.defaultPayment})
  }

  render() {
    const {complete, cardName, cardNumberError, cardExpiryError,
      cardCvcError, errorMessage, defaultPayment, isLoading} = this.state;

    return (
      <div>
        <div className="stripe-form row">
          {errorMessage && <div className="invalid-feedback col-12">{errorMessage}</div>}
          <label className="stripe-form-item col-lg-6">
            <div className="stripe-form-label">Card number</div>
            <CardNumberElement placeholder="xxxx - xxxx - xxxx - xxxx" onChange={this.handleStripeChange} />
            {cardNumberError && <div className="invalid-feedback">{cardNumberError}</div>}
          </label>
          <label className="stripe-form-item col col-md-6 col-lg-3">
            <div className="stripe-form-label">Expiry date (mm/yy)</div>
            <CardExpiryElement onChange={this.handleStripeChange} />
            {cardExpiryError && <div className="invalid-feedback">{cardExpiryError}</div>}
          </label>
          <label className="stripe-form-item col col-md-6 col-lg-3">
            <div className="stripe-form-label">CVC code</div>
            <CardCvcElement onChange={this.handleStripeChange} />
            {cardCvcError && <div className="invalid-feedback">{cardCvcError}</div>}
          </label>
          <label className="stripe-form-item col-lg-6">
            <div className="stripe-form-label">Name on card</div>
            <FormInput value={cardName} onChange={this.handleInputChange} />
          </label>

          <div className="bottom-holder col-lg-6">
            {this.props.defaultOption
              ?
              <div className="bottom-col">
              <label className="stripe-form-item check-item">
                <input className="stripe-form-checkbox-input form-check-input" checked={defaultPayment} onChange={this.handleCheckboxChange} type="checkbox"/>
                <span className="check"></span>
                <div className="stripe-form-checkbox-indicator"/>
                <div className="stripe-form-label check-lable">This will be my default payment method</div>
              </label>
              </div>
              :
              <div className="info-detail-holder d-flex align-items-center bottom-col">
                <span className="icon-holder">
                  <i className="icon-info-circle" />
                </span>
                <p>Future payments will be taken either a month or year after your first payment date depending on your subscription plan.</p>
              </div>
            }
            {/* <div className="button-holder">
              <button className="btn btn-primary" disabled={isLoading} onClick={this.handleSubmit}>
                {isLoading ? 'Loading...' : 'Add new card'}
              </button>
            </div> */}
          </div>
        </div>
        <div className="row">
          <div className="button-holder col">
            <button className="btn btn-primary" disabled={isLoading} onClick={this.handleSubmit}>
              {isLoading ? 'Loading...' : 'Add new card'}
            </button>
          </div>
        </div>
        {complete && <span className="text-success">Card successfully added</span>}
      </div>
    );
  }
}

const mapDispatchToProps = {
  addCard: billingActions.addNewCard,
}

export default injectStripe(
  connect(
    null,
    mapDispatchToProps
  )(StripeForm)
)
