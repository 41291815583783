import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Container } from 'reactstrap'
import SectionTitle from '../../components/_common/SectionTitle/SectionTitle'
import HeroBgImage from '../../components/_common/HeroBgImage/HeroBgImage'
import HeroImage from '../../assets/images/faq-hero-img.jpg'
import { locationCategoriesActions } from '../../_actions/location-categories.actions'
import Accordion from '../../components/_common/Accordion'

const useFetching = (fetch, dispatch) => {
  useEffect(() => {
    dispatch(fetch())
  }, [fetch, dispatch])
}

const AllLocationCategories = ({filterByName = '', skipFilterName = false}) => {
  const {data, isLoading} = useSelector(({locationCategories}) => ({
    isLoading: locationCategories.alphabet_list.isLoading,
    data: locationCategories.children_list.data,
  }), shallowEqual)

  useFetching(locationCategoriesActions.fetchLocationCategories, useDispatch())

  return (
    <>
      <HeroBgImage backgroundImg={HeroImage} type={'simple'} />
      <section className="section-content">
        <Container>
          <SectionTitle customClass="page-title" title={'All location categories'} />
          <div className="location-categories-accordion">
            {isLoading ? 'Loading...' :
              <Accordion columnCount={4} cards={data}/>
            }
          </div>
        </Container>
      </section>
    </>
  )
}

export default AllLocationCategories
