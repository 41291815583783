import { modalConstants } from '../_constants'

export function modal(
  state = {
    modalIsOpen: false,
    locationModalIsOpen: false,
    forgotModal: false,
    activeTab: ''
  },
  action
) {
  switch (action.type) {
    case modalConstants.OPEN:
      return {
        ...state,
        activeTab: action.payload.tab,
        modalIsOpen: true,
        pathTo: action.payload.path,
      }
    case modalConstants.CLOSE:
      return {
        ...state,
        modalIsOpen: false,
        activeTab: '',
        pathTo: ''
      }

    case modalConstants.SET_ACTIVE_TAB:
    return {
      ...state,
      activeTab: action.activeTab
    }

    case modalConstants.CLEAR:
      return {}
    case modalConstants.OPEN_LOCATION_MODAL:
      return {
        ...state,
        locationModalIsOpen: true,
        locationId: action.locationId,
      }
    case modalConstants.CLOSE_LOCATION_MODAL:
      return {
        ...state,
        locationModalIsOpen: false,
        locationId: null,
      }
    case modalConstants.OPEN_FORGOT_MODAL:
      return {
        ...state,
        forgotModal: true
      }
    case modalConstants.CLOSE_FORGOT_MODAL:
      return {
        ...state,
        forgotModal: false,
      }
    default:
      return state
  }
}
