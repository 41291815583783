import React from 'react'

const CityIcon = ({ fill = '#000', width = '100%', height = width, className = '' }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 640 512"
    >
      <path
        fill={fill}
        d="M132 368H92c-6.62 0-12 5.37-12 12v40c0 6.63 5.38 12 12 12h40c6.62 0 12-5.37 12-12v-40c0-6.63-5.38-12-12-12zm0-96H92c-6.62 0-12 5.37-12 12v40c0 6.63 5.38 12 12 12h40c6.62 0 12-5.37 12-12v-40c0-6.63-5.38-12-12-12zm0-96H92c-6.62 0-12 5.37-12 12v40c0 6.63 5.38 12 12 12h40c6.62 0 12-5.37 12-12v-40c0-6.63-5.38-12-12-12zm96 192h-40c-6.62 0-12 5.37-12 12v40c0 6.63 5.38 12 12 12h40c6.62 0 12-5.37 12-12v-40c0-6.63-5.38-12-12-12zm0-96h-40c-6.62 0-12 5.37-12 12v40c0 6.63 5.38 12 12 12h40c6.62 0 12-5.37 12-12v-40c0-6.63-5.38-12-12-12zm0-96h-40c-6.62 0-12 5.37-12 12v40c0 6.63 5.38 12 12 12h40c6.62 0 12-5.37 12-12v-40c0-6.63-5.38-12-12-12zm192 96h-40c-6.62 0-12 5.37-12 12v40c0 6.63 5.38 12 12 12h40c6.62 0 12-5.37 12-12v-40c0-6.63-5.38-12-12-12zm0-96h-40c-6.62 0-12 5.37-12 12v40c0 6.63 5.38 12 12 12h40c6.62 0 12-5.37 12-12v-40c0-6.63-5.38-12-12-12zm0-96h-40c-6.62 0-12 5.37-12 12v40c0 6.63 5.38 12 12 12h40c6.62 0 12-5.37 12-12V92c0-6.63-5.38-12-12-12zm128 288h-40c-6.62 0-12 5.37-12 12v40c0 6.63 5.38 12 12 12h40c6.62 0 12-5.37 12-12v-40c0-6.63-5.38-12-12-12zm0-96h-40c-6.62 0-12 5.37-12 12v40c0 6.63 5.38 12 12 12h40c6.62 0 12-5.37 12-12v-40c0-6.63-5.38-12-12-12zm68-80H512V24c0-13.26-10.75-24-24-24H312c-13.25 0-24 10.74-24 24v72h-64V8c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v88h-64V8c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v88H24c-13.25 0-24 10.74-24 24v384c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8V128h288V32h160v192h128v280c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8V216c0-13.26-10.75-24-24-24z"
      />
    </svg>
  )
}

export default CityIcon
