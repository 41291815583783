import { optionsConstants } from '../_constants/form-options.constants'
import { optionsService } from '../_services/form-options.service'
import { convertUncerscoreObject } from '../_helpers'

const loadLocationTypes = () => {
  const loadStart = () => ({
    type: optionsConstants.LOCATION_TYPES_OPTIONS_REQUEST
  })

  const loadSuccess = types => ({
    type: optionsConstants.LOCATION_TYPES_OPTIONS_SUCCESS,
    payload: types
  })

  const loadFailure = error => ({
    type: optionsConstants.LOCATION_TYPES_OPTIONS_FAILURE,
    error
  })

  return dispatch => {
    dispatch(loadStart())
    optionsService
      .loadLocationTypes()
      .then(types => {
        const camelCaseOptions = convertUncerscoreObject(types)
        // eslint-disable-next-line no-unused-vars
        const convertedOptions = camelCaseOptions.map(item => ({
            ...item,
            label: item.name,
            value: item.id,
            description: item.description
        }))
        dispatch(loadSuccess(convertedOptions))
      })
      .catch(error => {
        dispatch(loadFailure(error))
      })
  }
}

const makeOptions = response => {
  return {
    name: response.name,
    options: response.items.map(item => ({ id: item.id, label: item.name, value: item.id }))
  }
}

const loadLocationSettings = () => {
  const loadStart = () => ({
    type: optionsConstants.LOCATION_DETAILS_REQUEST
  })

  const loadSuccess = locations => ({
    type: optionsConstants.LOCATION_DETAILS_SUCCESS,
    payload: locations
  })

  const loadFailure = error => ({
    type: optionsConstants.LOCATION_DETAILS_FAILURE,
    error
  })

  return dispatch => {
    dispatch(loadStart())
    optionsService
      .loadDetails()
      .then(options => {
        const camelCaseOptions = convertUncerscoreObject(options)
        let convertedOptions = {}
        // eslint-disable-next-line no-unused-vars
        for (let key in camelCaseOptions) {
          convertedOptions[key] = makeOptions(camelCaseOptions[key])
        }
        dispatch(loadSuccess(convertedOptions))
      })
      .catch(error => {
        dispatch(loadFailure(error))
      })
  }
}

const makeKeywordOptions = (key, response) => {
  return {
    name: key,
    options: response.map(item => ({ id: item.id, label: item.name, value: item.id }))
  }
}

const loadGroupedKeywords = () => {
  const loadStart = () => ({
    type: optionsConstants.LOCATION_KEYWORDS_REQUEST
  })

  const loadSuccess = locations => ({
    type: optionsConstants.LOCATION_KEYWORDS_SUCCESS,
    payload: locations
  })

  const loadFailure = error => ({
    type: optionsConstants.LOCATION_KEYWORDS_FAILURE,
    error
  })

  return dispatch => {
    dispatch(loadStart())
    optionsService
      .loadGroupedKeywords()
      .then(options => {
        const camelCaseOptions = convertUncerscoreObject(options)
        let convertedOptions = {}
        // eslint-disable-next-line no-unused-vars
        for (let key in camelCaseOptions) {
          if ( !key ) continue
          convertedOptions[key] = makeKeywordOptions(key ,camelCaseOptions[key])
        }
        dispatch(loadSuccess(convertedOptions))
      })
      .catch(error => {
        dispatch(loadFailure(error))
      })
  }
}

export const optionsActions = {
  loadLocationTypes,
  loadLocationSettings,
  loadGroupedKeywords
}
