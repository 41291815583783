import React, { useState, useEffect } from 'react'
import { Button, Form, FormGroup, Container } from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { useDispatch  } from 'react-redux'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { searchLocations, history } from '../../../_helpers'
import LocationPlaces from '../../../components/LocationPlaces'
import FormSelect from '../../../components/_common/FormSelect/FormSelect'
import DropDownFilters from './DropDowns/DropDownFilters'
import DropDownCategory from './DropDowns/DropDownCategory'
import DropDownResponsive from './DropDowns/DropDownResponsive'
import './styles.scss'
import LocationCategorySelect from '../../../components/LocationCategorySelect'
import { optionsActions } from '../../../_actions'


const radiusOptions = [
  { value: 1, label: '1 mile' },
  { value: 5, label: '5 miles' },
  { value: 15, label: '15 miles' },
  { value: 30, label: '30 miles' },
  { value: 50, label: '50 miles' },
  { value: 100, label: '100+ miles' }
]


const FilterBar = () => {
  const {search, pathname} = window.location
  const params = new URLSearchParams(search)
  const loadGroupedKeywords = () => dispatch(optionsActions.loadGroupedKeywords())
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const dispatch = useDispatch()
  const [isOpen, toggle] = useState(false)
  const [isOpenCategory, toogleCategory] = useState(false)
  const [isOpenResponsive, toggleResponsive] = useState(false)
  const [radius, setRadius] = useState(0)
  const [location, setLocation] = useState(params.get('search-location'))
  const [category, setCategory] = useState('')

  const [feature, setFeature] = useState('')
  const [style, setStyle] = useState('')
  const [interior, setInterior] = useState('')
  const [keyword, setKeyword] = useState('')

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    setRadius(+searchParams.get('search-radius') || 0)
    setLocation(searchParams.get('search-location') || null)
    setCategory(+searchParams.get('search-category') || null)
    loadGroupedKeywords()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  const toggleFilters = () => {
    toggle(!isOpen)
  }

  const toggleFiltersCategory = () => {
    toogleCategory(!isOpenCategory)
  }

  const toggleFiltersResponsive = () => {
    toggleResponsive(!isOpenResponsive)
  }

  const updateLocationSearch = (key, value) => {
    params.set(key, value)
    history.replace(`${pathname}?${params.toString()}`)
  }

  const changeLocation = (option) => {
    geocodeByAddress(option)
      .then(results => getLatLng(results[0]))
      .then(({lat, lng}) => {
        setLocation(option)
        updateLocationSearch('search-location', option)
        updateLocationSearch('longitude', lng)
        updateLocationSearch('latitude', lat)
      })

  }

  const changeRadius = (name, value) => {
    setRadius(value)
    updateLocationSearch('search-radius', value)
  }

  const changeCategory = (name, value) => {
    setCategory(+value)
    updateLocationSearch('search-category', value)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    searchLocations(dispatch)
    isOpen && toggle(!isOpen)
  }

  const defaultRadiusOption = radiusOptions.find(item => item.value === radius);

  return (
    <section className="filter-content">
      <Container>
         <Form onSubmit={onSubmit} inline className="justify-content-between flex-nowrap" name="filter-content">
           <div className="filter-content-item wide has-border pl-0">
              <FormGroup>
                <LocationPlaces value={location} searchLocation={location} locationSelect={changeLocation} setLocation={setLocation} placeholder={'Anywhere'} />
              </FormGroup>
           </div>
          <div className="filter-content-item wide medium">
            {isTabletOrMobile ? 
            <Button className="btn-transparent pr-2 pl-2" onClick={toggleFiltersCategory}>Category</Button> :
            <FormGroup>
              <LocationCategorySelect isClearable={true} placeholder="Choose category" onChange={changeCategory} value={category}/>
            </FormGroup> 
            }
          </div>
          {!isTabletOrMobile && <div className="filter-content-item small transparent-select">
            <FormGroup>
              <FormSelect 
                className="radius-select"
                value={radius}
                placeholder="Search Radius"
                isDisabled={!location || location.length === 0}
                onChange={location ? changeRadius : null}
                options={radiusOptions}
                defaultValue={defaultRadiusOption}
                label={false}
              />
            </FormGroup>
          </div>}
          <div className="filter-content-item small transparent-select filter-opener pr-0 pr-md-1">
            {isTabletOrMobile ? 
              <Button className="btn-transparent pr-0 pl-0 btn-filter" onClick={toggleFiltersResponsive}>
                <i className="icon-sliders-h"></i>
              </Button> :
              <Button className="btn-transparent" onClick={toggleFilters}>Filters</Button>
            }
          </div>
          {!isTabletOrMobile && <div className="filter-content-item small pr-0">
            <button className="w-100 btn btn-primary">
              SEARCH
            </button>
          </div>}
        </Form>
        {isOpen &&
         <DropDownFilters
            toggleFilters={toggleFilters}
            setLocation={setLocation}
            setCategory={setCategory}
            feature={feature}
            style={style}
            interior={interior}
            setFeature={setFeature}
            setStyle={setStyle}
            setInterior={setInterior}
        />}
        {isOpenCategory && 
          <DropDownCategory
            toogleCategory={toogleCategory}
            changeCategory={changeCategory}
            category={category}
        />}
        {isOpenResponsive && 
          <DropDownResponsive 
            toggleFiltersResponsive={toggleFiltersResponsive}
            location={location}
            changeRadius={changeRadius}
            radiusOptions={radiusOptions}
            defaultRadiusOption={defaultRadiusOption}
            updateLocationSearch={updateLocationSearch}
            style={style}
            interior={interior}
            keyword={keyword}
            radius={radius}
            setStyle={setStyle}
            setInterior={setInterior}
            setKeyword={setKeyword}
            setLocation={setLocation}
            setCategory={setCategory}
            setFeature={setFeature}
          />}
      </Container>
    </section>
  )
}

export default FilterBar
