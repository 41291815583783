import React from 'react'
import UserRegisterForm from './UserRegisterForm'
import { authValidationSchema } from '../ValidationSchema/index'
import { userActions } from '../../../_actions'
import { Formik } from 'formik'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

const RegisterForm = props => {
  const onSubmit = (val, { resetForm }) => {
    props.register(val, props.pathTo, props.history.push, resetForm)
  }
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        password_confirmation: '',
        consent: true,
      }}
      enableReinitialize
      validationSchema={authValidationSchema}
      onSubmit={onSubmit}
    >
      {({ submitForm, isSubmitting, values, errors, handleReset }) => {
        return <UserRegisterForm close={props.close} type={props.type} toggle={props.toggle} errors={errors} {...props} />
      }}
    </Formik>
  )
}

const mapStateToProps = state => {
  const {
    registration: { registering },
    modal: { pathTo },
  } = state
  return { registering, pathTo }
}

const mapDispatchToProps = {
  register: userActions.register,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RegisterForm))
