import { locationConstants } from '../_constants'

const initialState = {
  isEditFiles: false,
  newLocation: {
    formValues: {
      additionalInfo: [],
      addressLine1: '',
      addressLine2: '',
      ageStyle: [],
      buillingAddressLine1: '',
      buillingAddressLine2: '',
      buillingBuilding: '',
      buillingCity: '',
      buillingCounty: '',
      buillingLatitude: '',
      buillingLongitude: '',
      buillingPostCode: '',
      bedroomsNo: 0,
      building: '',
      city: '',
      contactName: '',
      county: '',
      declaration: false,
      description: '',
      emailAddress: '',
      filmingAvailability: 0,
      filmingDetails: '',
      id: null,
      interiorStyle: [],
      latitude: 0,
      locationCategory: 0,
      locationSetting: 0,
      locationType: 0,
      longitude: 0,
      isAddressSame: true,
      name: '',
      parkingDetails: '',
      parking: 0,
      paymentPeriod: '',
      phone: '',
      phone_2: '',
      phone_2ProfessionalsOnly: true,
      phoneProfessionalsOnly: true,
      postCode: '',
      powerSupply: 0,
      specificFeatures: [],
      videoType: 0,
      videoUrl: '',
      waterSource: 0,
      wifi: 0,
      imageUrls: [],
      imageFeaturedUrls: [],
      pdfUrls: []
    },
    locationFetch: {
      isLoad: false,
      isError: false,
      isLoading: false,
      data: {}
    },
    isProcessing: false,
    isFailure: false,
    stepsNav: {
      currentStep: 1,
      routes: [
        {
          id: 1,
          title: 'Location Type',
          description: 'Select your location type and select a category',
          isValid: false
        },
        {
          id: 2,
          title: 'Location Details',
          description: 'Tell us all about your location',
          isValid: false
        },
        {
          id: 3,
          title: 'Billing & payment',
          description: 'Select your Billing preferences and make your payment',
          isValid: false
        },
        {
          id: 4,
          title: 'Confirmation',
          description: null,
          isValid: false
        }
      ]
    }
  },
  list: [],
  favoritesList: [],
  myList: [],
  nameList: [],
  featuredList: [],
  singleLocation: {
    loadingFavoritesMain: false
  },
  loadingName: false,
  isErrorName: false,
  loading: true,
  isError: false,
  targetCoords: { lat: 54.82197, lng: -5.418672 },
  content: {
    imageUrls: [],
    imageFeaturedUrls: [],
    pdfUrls: [],
    errorImageUrls: '',
    errorImageFeaturedUrls: ''
  }
}

export function locations(state = initialState, action){
  switch (action.type) {
    case locationConstants.GET_LOCATIONS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case locationConstants.GET_LOCATIONS_SUCCESS:
      const { data, ...resp } = action.payload
         return {
        ...state,
        ...resp,
        loading: false,
        favoritesList: [],
        myList: [],
        list: data,
        isError: false
      }

    case locationConstants.GET_LOCATIONS_FAILURE:
      return {
        ...state,
        isError: true,
        loading: false
      }

    case locationConstants.GET_FEATURED_LOCATIONS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case locationConstants.GET_FEATURED_LOCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        featuredList: action.payload,
        isError: false
      }

    case locationConstants.GET_FEATURED_LOCATIONS_FAILURE:
      return {
        ...state,
        isError: true,
        loading: false
      }


    case locationConstants.GET_SPECIFIC_LOCATION_REQUEST:
      return {
        ...state,
        loadingName: true
      }

    case locationConstants.GET_SPECIFIC_LOCATION_SUCCESS:
      return {
        ...state,
        loadingName: false,
        nameList: action.payload,
        isErrorName: false
      }

    case locationConstants.GET_SPECIFIC_LOCATION_FAILURE:
      return {
        ...state,
        isErrorName: true,
        loadingName: false
      }

    case locationConstants.CLEAR_LOCATION_LIST:
      return {
        ...state,
        nameList: []
      }

    case locationConstants.GET_FAVORITE_REQUEST:
      return {
        ...state,
        loading: true
      }

    case locationConstants.GET_FAVORITE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [],
        myList: [],
        favoritesList: action.payload,
        isError: false
      }

    case locationConstants.GET_FAVORITE_FAILURE:
      return {
        ...state,
        isError: true,
        loading: false
      }

    case locationConstants.CHANGE_FAVORITE_VALUE:
      return {
        ...state,
        list: state.list.map(item => {
          if(item.id === action.id){
            item.is_favourite = !item.is_favourite
            item.loadingFavorites = false
          }
          return item
        }),
      }

    case locationConstants.CHANGE_FAVORITE_VALUE_MAIN:
      return {
        ...state,
        singleLocation: {
          ...state.singleLocation,
          isFavourite: !state.singleLocation.isFavourite,
          loadingFavoritesMain: false
        },
      }

    case locationConstants.LOAD_FAVORITE:
      return {
        ...state,
        list: state.list.map(item => {
          if(item.id === action.id){
            item.loadingFavorites = true
          }
          return item
        }),
      }

    case locationConstants.LOAD_FAVORITE_MAIN:
      return {
        ...state,
        singleLocation: {
          ...state.singleLocation,
          loadingFavoritesMain: true
        },
      }

    case locationConstants.GET_MY_LOCATIONS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case locationConstants.GET_MY_LOCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [],
        favoritesList: [],
        myList: action.payload,
        isError: false
      }
    case locationConstants.GET_MY_LOCATIONS_FAILURE:
      return {
        ...state,
        isError: true,
        loading: false
      }

    case locationConstants.SET_TARGET_COORDS:
      return {
        ...state,
        targetCoords: action.payload
      }

    case locationConstants.GET_LOCATION_IDS_REQUEST:
      return {}
    case locationConstants.GET_LOCATION_IDS_SUCCESS:
      return {
        ...state,
        singleLocation: action.payload,
        loading: false,
        isError: false,
        content: {
          ...state.content,
          imageUrls: action.payload.imageUrls,
          imageFeaturedUrls: action.payload.imageFeaturedUrls,
          pdfUrls: action.payload.pdfUrls
        }
      }

    case locationConstants.GET_LOCATION_IDS_FAILURE:
      return {}

    case locationConstants.SET_NEW_LOCATION_TYPE:
      return {
        ...state,
        newLocation: {
          ...state.newLocation,
          formValues: {
            ...state.newLocation.formValues,
            locationType: action.payload
          }
        }
      }

    case locationConstants.UPDATE_NEW_LOCATION_FORM_VALUES:
      return {
        ...state,
        newLocation: {
          ...state.newLocation,
          formValues: {
            ...state.newLocation.formValues,
            ...action.payload
          }
        }
      }

    case locationConstants.LOCATION_IS_EDIT_FILES:
      return {
        ...state,
        isEditFiles: action.payload,
        content: {
          ...state.content,
          imageFeaturedUrls: (!state.content.imageFeaturedUrls.length ? state.newLocation.formValues.imageFeaturedUrls : state.content.imageFeaturedUrls),
        }
      }

    case locationConstants.SET_IMAGE_FEATURED_URLS_ERROR:
      return  {
        ...state,
        content: {
          ...state.content,
          errorImageFeaturedUrls: action.payload
        }
      }

    case locationConstants.ADD_LOCATION_FILE:
      return {
        ...state,
        content: {
          ...state.content,
          [action.payload.key]: [...state.content[action.payload.key], action.payload.id],
          errorImageFeaturedUrls: (action.payload.key === 'imageFeaturedUrls') ? '' : state.content.errorImageFeaturedUrls,
          errorImageUrls: (action.payload.key === 'imageUrls') ? '' : state.content.errorImageUrls
        }
      }

    case locationConstants.UPDATE_LOCATION_FILES:
      const files = state.content[action.payload.key]
      return {
        ...state,
        content: {
          ...state.content,
          [action.payload.key]: files.filter(fileId => fileId !== action.payload.id)
        }
      }

    case locationConstants.RESET_LOCATION_FORM:
      return {
        ...state,
        newLocation: {
          ...state.newLocation,
          formValues: initialState.newLocation.formValues,
          stepsNav: initialState.newLocation.stepsNav
        },
        content: {
          imageUrls: [],
          imageFeaturedUrls: [],
          pdfUrls: [],
          errorImageUrls: '',
          errorImageFeaturedUrls: ''
        }
      }

    case locationConstants.LOCATION_FETCH_REQUEST:
      return {
        ...state,
        newLocation: {
          ...state.newLocation,
          locationFetch: {
            isLoad: false,
            isError: false,
            isLoading: true,
            data: {}
          }
        }
      }

    case locationConstants.LOCATION_FETCH_SUCCESS:
      return {
        ...state,
        newLocation: {
          ...state.newLocation,
          formValues: {
            ...state.newLocation.formValues,
            id: action.payload.id
          },
          locationFetch: {
            isLoad: true,
            isError: false,
            isLoading: false,
            data: action.payload
          }
        },
        singleLocation: {
          ...state.singleLocation,
          ...action.payload
        }
      }

    case locationConstants.LOCATION_FETCH_FAILURE:
      return {
        ...state,
        newLocation: {
          ...state.newLocation,
          locationFetch: {
            isLoad: true,
            isLoading: false,
            isError: action.payload,
            data: {}
          }
        }
      }

    case locationConstants.GO_TO_NEXT:
      // for protect going to invalid step
      const updatedRoutes = state.newLocation.stepsNav.routes.map(item => {
        if (item.id <= (state.newLocation.stepsNav.currentStep + 1)) {
          return {
            ...item,
            isValid: true
          }
        } else {
          return item
        }
      })

      return {
        ...state,
        newLocation: {
          ...state.newLocation,
          stepsNav: {
            ...state.newLocation.stepsNav,
            currentStep: state.newLocation.stepsNav.currentStep + 1,
            routes: updatedRoutes
          }
        }
      }

    case locationConstants.GO_TO:
      // for protect going to invalid step
      const goTo = state.newLocation.stepsNav.routes[action.payload - 1].isValid ? action.payload : state.newLocation.stepsNav.currentStep

      return {
        ...state,
        newLocation: {
          ...state.newLocation,
          stepsNav: {
            ...state.newLocation.stepsNav,
            currentStep: goTo
          }
        }
      }

    case locationConstants.PULL_PREV_LOCATION_REQUEST:
      return {
        ...state,
        newLocation: {
          ...state.newLocation,
          isProcessing: true
        }
      }

    case locationConstants.PULL_PREV_LOCATION_FAILURE:
      return {
        ...state,
        newLocation: {
          ...state.newLocation,
          isProcessing: false,
          isFailure: action.payload
        }
      }

    case locationConstants.PULL_PREV_LOCATION_SUCCESS:
      // for protect going to invalid step
      const defaultStepRoutes = {
        currentStep: action.payload.nextStep
      }
      defaultStepRoutes['routes'] = state.newLocation.stepsNav.routes.map(item => {
        if (item.id <= action.payload.nextStep ) {
          return {
            ...item,
            isValid: true
          }
        } else {
          return item
        }
      })

      return {
        ...state,
        newLocation: {
          ...state.newLocation,
          formValues: {
            ...state.newLocation.formValues,
            ...action.payload.formValues
          },
          stepsNav: defaultStepRoutes,
          isProcessing: false,
          isFailure: false
        }
      }

    default:
      return state
  }
}
