import { modalActions } from './modal.actions'
import { locationConstants } from '../_constants/location.constants'
import { locationsService } from '../_services/locations.service'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { locationService } from '../_services/location.service'
import { convertUncerscoreObject } from '../_helpers'

function getCoords(address){
  const setCoords = (coords) => ({
    type: locationConstants.SET_TARGET_COORDS,
    payload: coords
  })

  return (dispatch) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => dispatch(setCoords(latLng)))
      .catch((error) => console.error('Error', error))
  }
}

function loadLocations(params){
  const loadStart = () => ({
    type: locationConstants.GET_LOCATIONS_REQUEST
  })

  const loadSuccess = (locations) => ({
    type: locationConstants.GET_LOCATIONS_SUCCESS,
    payload: locations
  })

  const loadFailure = (error) => ({
    type: locationConstants.GET_LOCATIONS_FAILURE,
    error
  })

  return (dispatch) => {
    dispatch(loadStart())
    locationsService
      .loadLocations(params)
      .then((locations) => {
        dispatch(loadSuccess(locations))
      })
      .catch((error) => {
        dispatch(loadFailure(error))
      })
  }
}

function loadFeaturedLocations(params){
  const loadStart = () => ({
    type: locationConstants.GET_FEATURED_LOCATIONS_REQUEST
  })

  const loadSuccess = (locations) => ({
    type: locationConstants.GET_FEATURED_LOCATIONS_SUCCESS,
    payload: locations
  })

  const loadFailure = (error) => ({
    type: locationConstants.GET_FEATURED_LOCATIONS_FAILURE,
    error
  })

  return (dispatch) => {
    dispatch(loadStart())
    locationsService
      .loadFeaturedLocations(params)
      .then((locations) => {
        dispatch(loadSuccess(locations))
      })
      .catch((error) => {
        dispatch(loadFailure(error))
      })
  }
}

function getById(id, isAny){
  const loadStart = () => ({
    type: locationConstants.GET_LOCATIONS_REQUEST
  })

  const loadSuccess = (location) => ({
    type: locationConstants.GET_LOCATION_IDS_SUCCESS,
    payload: location
  })

  const loadFailure = (error) => ({
    type: locationConstants.GET_LOCATIONS_FAILURE,
    error
  })

  return (dispatch) => {
    dispatch(loadStart())
    locationsService
      .loadSingleLocation(id, isAny)
      .then((location) => {
        const response = convertUncerscoreObject(location)
        dispatch(loadSuccess(response))
      })
      .catch((error) => {
        dispatch(loadFailure(error))
      })
  }
}

const setErrorFeatured = (val) => (dispatch) => {
  dispatch({type: locationConstants.SET_IMAGE_FEATURED_URLS_ERROR, payload: val})
}

function getUsersLocations(params) {
  const loadStart = () => ({
    type: locationConstants.GET_MY_LOCATIONS_REQUEST
  })

  const loadSuccess = locations => ({
    type: locationConstants.GET_MY_LOCATIONS_SUCCESS,
    payload: locations
  })

  const loadFailure = error => ({
    type: locationConstants.GET_MY_LOCATIONS_FAILURE,
    error
  })

  return dispatch => {
    dispatch(loadStart())
    locationsService
      .loadMyLocations(params)
      .then(
        locations => {
          dispatch(loadSuccess(locations))
        }
      ).catch(error => {
      dispatch(loadFailure(error))
    })
  }
}

function removeMyLocation(id, params) {
  const loadSuccess = id => ({
    type: locationConstants.REMOVE_MY_LOCATION_SUCCESS,
    payload: id
  })

  const loadFailure = error => ({
    type: locationConstants.REMOVE_MY_LOCATION_FAILURE,
    error
  })

  return dispatch => {
    locationsService
      .removeMyLocation(id)
      .then(
        id => {
          dispatch(loadSuccess(id))
          dispatch(getUsersLocations(params))
        }
      ).catch(error => {
      dispatch(loadFailure(error))
    })
  }
}

function getUsersFavoriteLocations(params) {
  const loadStart = () => ({
    type: locationConstants.GET_FAVORITE_REQUEST
  })

  const loadSuccess = locations => ({
    type: locationConstants.GET_FAVORITE_SUCCESS,
    payload: locations
  })

  const loadFailure = error => ({
    type: locationConstants.GET_FAVORITE_FAILURE,
    error
  })

  return dispatch => {
    dispatch(loadStart())
    locationsService
      .loadFavoriteLocations(params)
      .then(
        locations => {
          dispatch(loadSuccess(locations))
        }
      ).catch(error => {
      dispatch(loadFailure(error))
    })
  }
}

const changeFavoriteValue = (id) => ({
  type: locationConstants.CHANGE_FAVORITE_VALUE,
  id
})

const changeFavoriteValueMain = (id) => ({
  type: locationConstants.CHANGE_FAVORITE_VALUE_MAIN,
  id
})

const loadFavorite = (id) => ({
  type: locationConstants.LOAD_FAVORITE,
  id
})

const loadFavoriteMain = () => ({
  type: locationConstants.LOAD_FAVORITE_MAIN
})

function addFavoriteMain(id) {
  const loadFailure = error => ({
    type: locationConstants.ADD_TO_FAVORITE_ERROR,
    error
  })
  return dispatch => {
    dispatch(loadFavoriteMain())
    locationsService
      .addToFavorite(id)
      .then(
        () => {
          dispatch(changeFavoriteValueMain(id))
        }
      ).catch(error => {
      dispatch(loadFailure(error))
    })
  }
}

function removeFavoriteMain(id) {
  const loadFailure = error => ({
    type: locationConstants.REMOVE_FAVORITE_ERROR,
    error
  })

  return dispatch => {
    dispatch(loadFavoriteMain())
    locationsService
      .removeFromFavorite(id)
      .then(
        () => {
          dispatch(changeFavoriteValueMain(id))
        }
      ).catch(error => {
      dispatch(loadFailure(error))
    })
  }
}

function addToFavorite(id) {
  const loadFailure = error => ({
    type: locationConstants.ADD_TO_FAVORITE_ERROR,
    error
  })
  return dispatch => {
    dispatch(loadFavorite(id))
    locationsService
      .addToFavorite(id)
      .then(
        () => {
          dispatch(changeFavoriteValue(id))
        }
      ).catch(error => {
      dispatch(loadFailure(error))
    })
  }
}

function confirmDeleteFavorite(locationIds){
  return (dispatch) => {
    dispatch(modalActions.openGenericAlert(locationIds))
  }
}

function removeFromFavorite(id, formFavorite) {
  const loadFailure = error => ({
    type: locationConstants.REMOVE_FAVORITE_ERROR,
    error
  })

  return dispatch => {
    dispatch(loadFavorite(id))
    locationsService
      .removeFromFavorite(id)
      .then(
        () => {
          dispatch(changeFavoriteValue(id))
          if (formFavorite) dispatch(getUsersFavoriteLocations())
        }
      ).catch(error => {
      dispatch(loadFailure(error))
    })
  }
}

const setNewLocationType = (payload) => {
  return (dispatch) =>
    dispatch({
      type: locationConstants.SET_NEW_LOCATION_TYPE,
      payload
    })
}

const updateNewLocationValues = (payload) => {
  return (dispatch) =>
    dispatch({
      type: locationConstants.UPDATE_NEW_LOCATION_FORM_VALUES,
      payload
    })
}

const resetNewLocationValues = () => {
  return (dispatch) =>
    dispatch({
      type: locationConstants.RESET_LOCATION_FORM
    })
}

const createLocation = (params) => {
  const { onSuccess } = params

  const createQuery = {
    type: locationConstants.LOCATION_FETCH_REQUEST
  }

  const querySuccess = (payload) => ({
    type: locationConstants.LOCATION_FETCH_SUCCESS,
    payload
  })

  const queryFailed = (payload) => ({
    type: locationConstants.LOCATION_FETCH_FAILURE,
    payload
  })

  return (dispatch) => {
    dispatch(createQuery)
    locationService
      .createNewLocation(params)
      .then((data) => {
        const preparedObject = convertUncerscoreObject(data)
        dispatch(querySuccess(preparedObject))
        onSuccess()
      })
      .catch((error) => {
        dispatch(queryFailed(error.message || error))
      })
  }
}

const updateLocation = (params) => {
  const filteredParams = {}
  // eslint-disable-next-line no-unused-vars
  for (let key in params) {
    if (params[key] && params[key] !== '') {
      filteredParams[key] = params[key]
    }
  }
  const preparedObject = convertUncerscoreObject(filteredParams, true)

  const { onSuccess } = params

  const createQuery = {
    type: locationConstants.LOCATION_FETCH_REQUEST
  }

  const querySuccess = (payload) => ({
    type: locationConstants.LOCATION_FETCH_SUCCESS,
    payload
  })

  const queryFailed = (payload) => ({
    type: locationConstants.LOCATION_FETCH_FAILURE,
    payload
  })

  return (dispatch) => {
    dispatch(createQuery)
    locationService
      .updateNewLocation(preparedObject)
      .then((data) => {
        const preparedObject = convertUncerscoreObject(data)
        dispatch(querySuccess(preparedObject))
        onSuccess()
      })
      .catch((error) => {
        dispatch(queryFailed(error.message || error))
      })
  }
}

const goToNext = (payload) => {
  return (dispatch) =>
    dispatch({
      type: locationConstants.GO_TO_NEXT,
      payload
    })
}

const goTo = (payload) => {
  return (dispatch) =>
    dispatch({
      type: locationConstants.GO_TO,
      payload
    })
}

const pullPrevLocation = (id) => {
  const createQuery = {
    type: locationConstants.PULL_PREV_LOCATION_REQUEST
  }

  const querySuccess = (payload) => ({
    type: locationConstants.PULL_PREV_LOCATION_SUCCESS,
    payload
  })

  const queryFailed = payload => ({
    type: locationConstants.PULL_PREV_LOCATION_FAILURE,
    payload
  })

  return (dispatch) => {
    dispatch(createQuery)
    locationService
      .pullPrevLocation(id)
      .then(payload => {

        const sortedKeywords = {}
        payload.keywords.forEach(item => {

          if (Array.isArray(sortedKeywords[item.type])) {
            sortedKeywords[item.type].push(item.id)
          } else {
            sortedKeywords[item.type] = [item.id]
          }
        })

        const addedKeywordsByBroup = { ...payload, ...sortedKeywords}
        const response = convertUncerscoreObject(addedKeywordsByBroup)

        const filteredValues = {}
        let nextStep = {}

        // eslint-disable-next-line no-unused-vars
        for (let key in response) {
          if ((response[key] && !Array.isArray(response[key])) || (Array.isArray(response[key]) && response[key].length)) {
            switch (key) {
              case 'bedrooms':
                filteredValues['bedroomsNo'] = response[key].id
                break
              case 'locationTypeId':
                filteredValues['locationType'] = response[key].id
                break
              case 'categories':
                filteredValues['locationCategory'] = response[key][0].id
                break
              case 'powerSupply':
              case 'waterSource':
              case 'locationSetting':
              case 'parking':
              case 'filmingAvailability':
              case 'wifi':
                filteredValues[key] = response[key].id
                break
              case 'postcode':
                filteredValues['postCode'] = response[key]
                break
              case 'additionalInfo':
                filteredValues[key] = response[key].map((item) => item.id)
                break
              case 'videoType':
                const selected = ['youtube', 'vimeo', 'matterport'].indexOf(response[key]) + 1
                filteredValues[key] = selected
                break
              default:
                filteredValues[key] = response[key]
                break
            }
          }
        }

        if (filteredValues.locationCategory) {
          if (filteredValues.name) {
            nextStep = 3
          } else {
            nextStep = 2
          }
        } else {
          nextStep = 1
        }
        dispatch(querySuccess({ formValues: filteredValues, nextStep }))
      })
      .catch(error => {
        dispatch(queryFailed(error.message || error))
      })
  }
}

const addLocationFile = (id, key) => (dispatch) => {
  dispatch({type: locationConstants.ADD_LOCATION_FILE, payload: {id, key}})
}

const updateLocationFiles = (id, key) => (dispatch) => {
  dispatch({type: locationConstants.UPDATE_LOCATION_FILES, payload: {id, key}})
}

const locationIsEditFiles = (val) => (dispatch) => {
  dispatch({type: locationConstants.LOCATION_IS_EDIT_FILES, payload: val})
}

const clearLocationList = () => (dispatch) => {
  dispatch({type: locationConstants.CLEAR_LOCATION_LIST})
}

function getLocationByName(name) {
  const loadStart = () => ({
    type: locationConstants.GET_SPECIFIC_LOCATION_REQUEST
  })

  const loadSuccess = (locations) => ({
    type: locationConstants.GET_SPECIFIC_LOCATION_SUCCESS,
    payload: locations
  })

  const loadFailure = (error) => ({
    type: locationConstants.GET_SPECIFIC_LOCATION_FAILURE,
    error
  })

  return (dispatch) => {
    dispatch(loadStart())
    locationsService
      .loadLocations({name})
      .then((locations) => {
        dispatch(loadSuccess(locations))
      })
      .catch((error) => {
        dispatch(loadFailure(error))
      })
  }
}

export const locationActions = {
  createLocation,
  updateLocation,
  loadLocations,
  loadFeaturedLocations,
  getById,
  getUsersLocations,
  removeMyLocation,
  getUsersFavoriteLocations,
  addToFavorite,
  removeFromFavorite,
  confirmDeleteFavorite,
  setNewLocationType,
  updateNewLocationValues,
  resetNewLocationValues,
  getCoords,
  pullPrevLocation,
  goToNext,
  goTo,
  addLocationFile,
  updateLocationFiles,
  locationIsEditFiles,
  setErrorFeatured,
  addFavoriteMain,
  removeFavoriteMain,
  getLocationByName,
  clearLocationList
}
