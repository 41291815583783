import { generalConstants } from '../_constants/config.constants'
import { handleFormErrors } from '../_helpers/errors'

const getLocationByPostalCode = ({ postalCode, building = false}) => {
  const address = building ? building : false
	const getAddressForResp = (address) => {
		const address1 = address.split(',')[0]
		const address2 = address.split(',')[1]
		const address3 = address.split(',')[2]
		const address4 = address.split(',')[3]
		return (address1 + ',' + address2 + ',' + address3 + ',' + address4).trim();
	}

  const requestOptions = {
    method: 'GET',
    // mode: 'no-cors',
  }
  return (
    fetch(`https://api.getAddress.io/find/${postalCode ? postalCode.replace(/ /g,'') : ''}${address ? '/' + getAddressForResp(address)  : ''}?api-key=${generalConstants.GET_ADDRESS_API_KEY}${address ? '&expand=true' : ''}`, requestOptions)
      .then(handleResponse)
  )
}

function handleResponse(response) {
	return response.text().then((text) => {
		let data = text && JSON.parse(text)
		if (!response.ok) {
			const error = handleFormErrors(data)
			return Promise.reject(error)
		}
		return data
	})
}

const addressService = {
	getLocationByPostalCode
}

export default addressService
