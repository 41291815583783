import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik } from 'formik'

import LocationDetailsForm from './LocationDetailsForm'
import { locationDetailsSchema } from '../../ValidationSchema/index'
import { locationActions, optionsActions } from '../../../../_actions'


const LocationDetailsFormContainer = ({ defaultValues, contentValues, onSuccess, ...props }) => {
  const {
    formOptions,
    formContent,
    locations
  } = useSelector(store => ({
    formOptions: store.formOptions,
    formContent: store.locations.content,
    locations: store.locations.newLocation.formValues
  }))
  const dispatch = useDispatch()
  const {
    goTo,
    updateForm,
    loadLocationSettings,
    loadGroupedKeywords,
  } = (useCallback(() => ({
    goTo: params => dispatch(locationActions.goTo(params)),
    updateForm: params => dispatch(locationActions.updateLocation(params)),
    loadLocationSettings: () => dispatch(optionsActions.loadLocationSettings()),
    loadGroupedKeywords: () => dispatch(optionsActions.loadGroupedKeywords())
  }), [dispatch]))()
  useEffect(() => {
    if (!formOptions.additionalInfo.isLoad) loadLocationSettings()
    // if (formOptions.locationSetting.isLoading) loadLocationSettings()
    loadLocationSettings()
    if (!formOptions.ageStyle.isLoad) loadGroupedKeywords()
    if (!defaultValues.id) {
      goTo(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = (val, { resetForm }) => {
    if (!formContent.imageFeaturedUrls.length) {
      dispatch(locationActions.setErrorFeatured('Please upload master photo'))
    }

    if (!formContent.imageFeaturedUrls.length) {
      return;
  }

    const successHandler = () => {
      onSuccess(val)
    }
    const updateRequestOptions = {}
    let keywords = []
    // eslint-disable-next-line no-unused-vars
    for(let key in val){
      // here i will use fall-through
      switch(key){
        case 'ageStyle':
        case 'interiorStyle':
        case 'specificFeatures':
          keywords = [...keywords, ...val[key]]
          break;

        case 'postCode':
          updateRequestOptions['postcode'] = val[key]
          break;

        case 'bedroomsNo':
          updateRequestOptions['bedrooms'] = val[key]
          break;
        case 'imageUrls':
        case 'imageFeaturedUrls':
        case 'pdfUrls':
          break;

        default:
          updateRequestOptions[key] = val[key]
      }
    }
    updateRequestOptions['keyword_id'] = keywords
    // add  on load function in props
    updateRequestOptions['onSuccess'] = successHandler
    updateForm(updateRequestOptions)
  }
  return (
    <Formik
      initialValues={{
        additionalInfo: defaultValues.additionalInfo || [],
        addressLine1: defaultValues.addressLine1 || '',
        addressLine2: defaultValues.addressLine2 || '',
        ageStyle: defaultValues.ageStyle || [],
        bedroomsNo: defaultValues.bedroomsNo || 0,
        building: defaultValues.building || '',
        city: defaultValues.city || '',
        contactName: defaultValues.contactName || '',
        county: defaultValues.county || '',
        description: defaultValues.description || '',
        emailAddress:defaultValues.emailAddress || '',
        filmingAvailability: defaultValues.filmingAvailability || 0,
        filmingDetails: defaultValues.filmingDetails || '',
        interiorStyle: defaultValues.interiorStyle,
        latitude: defaultValues.latitude || 0,
        locationSetting: defaultValues.locationSetting || 0,
        longitude: defaultValues.longitude || 0,
        name: defaultValues.name || '',
        parkingDetails: defaultValues.parkingDetails || '',
        parking: defaultValues.parking || 0,
        phone: defaultValues.phone || '',
        phone_2: defaultValues.phone_2 || '',
        phoneProfessionalsOnly: defaultValues.phoneProfessionalsOnly || true,
        phone_2ProfessionalsOnly: defaultValues.phone_2ProfessionalsOnly || true,
        postCode: defaultValues.postCode || '',
        powerSupply: defaultValues.powerSupply || 0,
        specificFeatures: defaultValues.specificFeatures || [],
        videoType: defaultValues.videoType || 0,
        videoUrl: defaultValues.videoUrl || '',
        waterSource: defaultValues.waterSource || 0,
        wifi: defaultValues.wifi || 0,
        id: defaultValues.id || null,
      }}
      enableReinitialize
      validationSchema={locationDetailsSchema(locations.locationType)}
      onSubmit={onSubmit}
    >
      {({ submitForm, isSubmitting, values, handleReset, ...props }) => {
        return (
          <LocationDetailsForm
            submitForm={submitForm}
            isSubmitting={isSubmitting}
            values={values}
            {...props}
          />
        )
      }}
    </Formik>
  )
}

export default LocationDetailsFormContainer
