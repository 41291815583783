import React, { useEffect } from 'react'
import { Col, Container, Row } from 'reactstrap'
import HeroBgImage from '../../components/_common/HeroBgImage/HeroBgImage'
import SectionTitle from '../../components/_common/SectionTitle/SectionTitle'
import HeroImage from '../../assets/images/account-hero-img.jpg'
import CardMyLocation from '../../components/_common/Card/CardMyLocation'
import { useDispatch, useSelector } from 'react-redux'
import PaginationComponent from '../../components/_common/PaginationComponent'
import { searchMy } from '../../_helpers'
import Preloader from '../../components/_common/Preloader'
import './styles.scss'

const MyLocationList = props => {
  const locationsState = useSelector(state => state.locations)
  const locations = useSelector(state => state.locations.myList.data)
  const dispatch = useDispatch()

  useEffect(() => {
    searchMy(dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changePage = (page) => {
    const {history} = props
    const {search, pathname} = window.location
    const params = new URLSearchParams(search)
    params.set('page', page)
    history.replace(`${pathname}?${params.toString()}`)
    setTimeout(() => {
      searchMy(dispatch)
    })
  }

  return (
    <>
      <HeroBgImage backgroundImg={HeroImage} type={'simple'} />
      <Preloader status={locationsState.loading}/>
      {!locationsState.loading && !locationsState.isError &&
      <div className="listing-area">
        <section className="section-content">
          <Container>
            <SectionTitle title={`My Location Listings`} />
            <div className="locations-list">
              {(!locations || locations.length === 0) && <p className="text-warning">No results</p>}
              {locations && locations.map((item) => (
                <div key={item.id} className={'locate-item'}>
                  <CardMyLocation location={item} />
                </div>
              ))}
            </div>
          </Container>
        </section>
        <Container>
          <Row>
            <Col>
              {!!locationsState.myList.total && <PaginationComponent
                totalItems={locationsState.myList.total}
                pageSize={locationsState.myList.per_page}
                activePage={locationsState.myList.current_page}
                previousPageText={'<'}
                nextPageText={'>'}
                maxPaginationNumbers={Math.ceil(locationsState.myList.total / 15)}
                navContainerClass={
                  'd-flex align-items-center justify-content-center'
                }
                onSelect={changePage}
              />}
            </Col>
          </Row>
        </Container>
      </div>}
    </>
  )
}

export default MyLocationList
