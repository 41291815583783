import { documentsConstants } from '../_constants/documents.constants'

const initialState = {
  isLoaded: false,
  isError: false,
  isLoading: false,
  data: [],
}

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case documentsConstants.DOCUMENTS_REQUEST:
      return {
        ...state,
        isLoaded: false,
        isError: false,
        data: [],
      }

    case documentsConstants.DOCUMENTS_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isError: false,
        data: action.payload.data,
      }

    case documentsConstants.DOCUMENTS_FAILURE:
      return {
        ...state,
        isLoaded: false,
        isError: action.error,
        data: [],
      }

    default:
      return state
  }
}
