import React, {useState} from 'react'
import { Container, Row, Col } from 'reactstrap'
import HeroBgImage from '../../../components/_common/HeroBgImage/HeroBgImage'
import SearchForm from '../../../components/_common/SearchForm/SearchForm'
import SearchFormSpecific from '../../../components/_common/SearchForm/SearchFormSpecific'
import HeroImage from '../../../assets/images/home-hero-img.jpg'
import './HeroSection.scss'
import { useMediaQuery } from 'react-responsive'

const HeroSection = props => {
  const [search, setSearch] = useState(true)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' })

  const changeSearch = () => {
    setSearch(!search)
  }

  return (
    <section className="hero-section">
      <HeroBgImage backgroundImg={HeroImage} isHome={props.isHome} />
      <Container className="hero-section__holder">
        <Row>
          <Col sm="12">
            <div className="hero-section__content">
              <h1 className="hero-section__title">The UK’s leading filming & photography locations database</h1>
              {!isTabletOrMobile  && <p className="hero__text">
                From castles to caves, warehouses to windmills, Kays have
                thousands of top locations
              </p>}
            </div>
          </Col>
          <Col sm="12">
            {search ? <SearchForm /> : <SearchFormSpecific />}
          </Col>
          <div className="specific-location">
            <span onClick={changeSearch}>{search ? 'Looking for a specific location? click here' : 'Looking to search all locations? click here'}</span>
          </div>
        </Row>
      </Container>
    </section>
  )
}

export default HeroSection
