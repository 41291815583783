import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App/App'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import { registerPlugin } from 'react-filepond'
import { Provider } from 'react-redux'
import { Router } from 'react-router'
import { history } from './_helpers'
import { store } from './_helpers/store'
import * as serviceWorker from './serviceWorker'
import ScrollToTop from './components/_common/ScrollToTop'

// setup fake backend
// import { configureFakeBackend } from './_helpers'
// configureFakeBackend()

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <ScrollToTop />
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
