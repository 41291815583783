import React, {useEffect} from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useMediaQuery } from 'react-responsive'


import CustomBuildSwiper from '../../../components/_common/CustomBuildSwiper/CustomBuildSwiper'
import Preloader from '../../../components/_common/Preloader'

import './styles.scss'
import { useDispatch, useSelector } from 'react-redux'
import { searchFeaturedLocations } from '../../../_helpers'
import RecentlyCard from '../../../components/_common/Card/RecentlyCard'

const RecentlyLocationsSlider = () => {
  const locations = useSelector(state => state.locations.featuredList)

  const locationsState = useSelector(state => state.locations)

  const isTable = useMediaQuery({ query: '(max-width: 768px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 580px)' })

  const quantityItems = () => {
    if(isTable && isMobile){
      return 2
    } else if (!isMobile && isTable){
      return 3
    }else{
      return 4
    }
  };


  const dispatch = useDispatch()
  useEffect(() => {
    searchFeaturedLocations(dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (locationsState.loading) return <Preloader status={true} />
  return (
    <section className="section-content news">
      <Container>
        <Row>
          <Col>
            <h2 className="text-center section-content__title">Recently Added Locations</h2>
          </Col>
        </Row>
      </Container>
      <div className={`swiper-custom__container`}>
        <CustomBuildSwiper quantity={quantityItems()}>
          {locations.map((item, index) => {
            return (
              <div className="swiper-custom__slide" key={index}>
                <RecentlyCard type={'complex'} data={item} cardProps categories={[]}/>
              </div>
            )
          })}
        </CustomBuildSwiper>
      </div>
    </section>
  )
}

export default RecentlyLocationsSlider