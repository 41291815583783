import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Row, Col, Button } from 'reactstrap'
import "./styles.scss"

import { locationActions } from '../../../_actions'

const Confirmation = ({ history }) => {

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      const resetNewLocationValues = params => dispatch(locationActions.resetNewLocationValues(params))
      resetNewLocationValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Row>
      <Col md={12}>
        <div className="thank-you-page">
          <div className="icon-holder">
            <i className="icon-check-circle"></i>
          </div>
          <h5>Thank you, your payment was successful</h5>
          <p>
            Welcome to Kays Locations. Your location listing will be approved by our team and we aim to make it live on
            our website in 24-48 hours. We'll email you to let you know when your listing is active.
          </p>
          <div className="thank-you-button-holder">
            <Button className="text-uppercase" outline color="primary" onClick={() => history.push('/')}>Home</Button>
            <Button className="text-uppercase" color="primary" onClick={() => history.push('/account')}>My account</Button>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default withRouter(Confirmation)
