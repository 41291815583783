import React, { Component } from 'react'
import { Collapse, CardBody, Card, CardHeader } from 'reactstrap'
import { Link } from 'react-router-dom';
import classnames from 'classnames'
import './styles.scss'

class Accordion extends Component {
  state = { collapse: null }

  toggle = (e) => {
    const event = e.target.dataset.event
    this.setState({
      collapse: this.state.collapse === Number(event) ? null : Number(event),
    })
  }
  render() {
    const { collapse } = this.state
    const { cards = 2 } = this.props

    return cards.map((item, index) => {
      if(item.content && item.content.length === 0) return null
      return (
        <Card
          style={{ marginBottom: '1rem' }}
          key={index}
          className={classnames(
            { active: collapse === index },
            'accordion-item'
          )}
        >
          <CardHeader onClick={this.toggle} data-event={index}>
            <span>{item.headerTitle}</span>
          </CardHeader>
          <Collapse isOpen={collapse === index}>
            {item.linkList ? (
              <CardBody>
                <ul className="accordion-list">
                  {item.linkList.map((item, i) => (
                    <li key={i}>
                      {item.doc && <a href={'#'} onClick={this.props.downloadDocument(item.name)}  target="blank">
                        {item.name}
                      </a>}
                      {item.src && <a href={item.src} target="blank">
                        {item.name}
                      </a>}
                      {item.id && <Link to={`/locations?search-category=${item.id}`}>
                        {item.name}
                      </Link>}
                    </li>
                  ))}
                </ul>
              </CardBody>
            ) : <CardBody dangerouslySetInnerHTML={{__html: item.content}}/>}
          </Collapse>
        </Card>
      )
    })
  }
}

export default Accordion
