import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik } from 'formik'

import LocationTypeForm from './LocationTypeForm'
import { locationBuillingSchema } from '../../ValidationSchema/index'
import { locationActions } from '../../../../_actions/location.actions'
import { capitalizeFirstLetter, authHeader, convertUncerscoreObject } from '../../../../_helpers'
import {generalConstants} from '../../../../_constants/config.constants';
import './styles.scss'


const sendBuillingAddress = body => {
  return fetch(`${generalConstants.BASIC_URL}/billing/address` ,{
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body)
  })
  .then(response =>  response.statusText)
  .catch(error => console.log(error))
}

const sendSendBuillingPlan = body => {
  return fetch(`${generalConstants.BASIC_URL}/billing/subscription` ,{
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body)
  })
  .then(response => response.statusText)
  .catch(error => console.log(error))
}

const BuillingDetailsContainer = props => {
  // temp for infiliny spiner on builling submit form
  const [isPaymentProcessed, setIsPaymentProcessed] = useState(false)
  const {
    storeValues
  } = useSelector(state => ({
    storeValues: state.locations.newLocation.formValues
  }))

  const dispatch = useDispatch()
  const updateNewLocationValues = params => dispatch(locationActions.updateNewLocationValues(params))
  const goToNext = params => dispatch(locationActions.goToNext())

  const toNextStep = () => {
    goToNext()
  }


  const onSubmit = async (val, { resetForm }) => {
    // temp for infiliny spiner on builling submit form
    setIsPaymentProcessed(true)
    val.locationId = storeValues.id
    const updatedForStore = {}
    const updatedAddressRequest = {}
    const updatedPlanRequest = {}
    // eslint-disable-next-line no-unused-vars
    for(let key in val) {
      // here i will use fall-through

      switch(key){
        case 'postCode':
          updatedForStore[`builling${capitalizeFirstLetter(key)}`] = val[key]
          updatedAddressRequest['postcode'] = val[key]
          break;

        case 'addressLine1':
        case 'addressLine2':
        case 'city':
        case 'county':
          updatedForStore[`builling${capitalizeFirstLetter(key)}`] = val[key]
          updatedAddressRequest[key] = val[key]
          break;

        case 'latitude':
        case 'longitude':
        case 'building':
          updatedForStore[`builling${capitalizeFirstLetter(key)}`] = val[key]
          break

        case 'paymentPeriod':
          updatedForStore[key] = val[key]
          updatedPlanRequest['period'] = val[key]
          break;

        case 'cardId':
          updatedPlanRequest['id_card'] = val[key]
          break;
        case 'locationId':
          updatedPlanRequest['id_location'] = storeValues.id
          break;
        default:
          updatedForStore[key] = val[key]
      }
    }
    // update store values
    updateNewLocationValues(updatedForStore)
    try {
      await sendBuillingAddress(convertUncerscoreObject(updatedAddressRequest, true))
      await sendSendBuillingPlan(updatedPlanRequest)
      toNextStep()
    }catch (e) {
      setIsPaymentProcessed(false)
    }
  }

  return (
    <Formik
      initialValues={{
        cardId: '',
        paymentPeriod: storeValues.paymentPeriod,
        addressLine1: storeValues.isAddressSame ? storeValues.addressLine1 : storeValues.buillingAddressLine1,
        addressLine2: storeValues.isAddressSame ? storeValues.addressLine2 : storeValues.buillingAddressLine2,
        building: storeValues.isAddressSame ? storeValues.building : storeValues.buillingBuilding,
        city: storeValues.isAddressSame ? storeValues.city : storeValues.buillingCity,
        county: storeValues.isAddressSame ? storeValues.county : storeValues.buillingCounty,
        postCode: storeValues.isAddressSame ? storeValues.postCode : storeValues.buillingPostCode,
        latitude: storeValues.isAddressSame ? storeValues.latitude : storeValues.buillingLatitude,
        longitude: storeValues.isAddressSame ? storeValues.longitude : storeValues.buillingLongitude,
        isAddressSame: storeValues.isAddressSame,
        declaration: storeValues.declaration,
      }}
      enableReinitialize
      validationSchema={locationBuillingSchema}
      onSubmit={onSubmit}
    >
      {({ submitForm, isSubmitting, values, handleReset, ...props }) => {
        return (
          <LocationTypeForm
            submitForm={submitForm}
            isSubmitting={true}
            values={values}
            isRequestSended={isPaymentProcessed}
            {...props}
          />
        )
      }}
    </Formik>
  )
}

export default BuillingDetailsContainer
