import React, { useEffect } from 'react'
import Accordion from '../../components/_common/Accordion'
import { Container, Row, Col } from 'reactstrap'
import HeroBgImage from '../../components/_common/HeroBgImage/HeroBgImage'
import SectionTitle from '../../components/_common/SectionTitle/SectionTitle'
import { IconBlock } from '../../components/_common/Typography'
import HeroImage from '../../assets/images/documentation.jpg'
import Card from '../../components/_common/Card/Card'
import './styles.scss'
import { useDispatch, useSelector } from 'react-redux'
import { documentsActions } from '../../_actions/documents.actions'


const Documents = () => {
  const dispatch = useDispatch()

  const downloadDocument = (name) => () => {
    dispatch(documentsActions.downloadDocument(name))
  }

  useEffect(() => {
    dispatch(documentsActions.loadDocuments())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const documents = useSelector(state => state.documents.data)

  const cardHolder = documents.map(d => ({ ...d, linkList: [] })).reduce((result, doc) => {
    const item = result.find(i => i && i.headerTitle === doc.category)
    if (item) {
      item.linkList.push({ name: doc.name, doc: doc.link })
    } else {
      result.push({ linkList: [{ name: doc.name, doc: doc.link }], headerTitle: doc.category })
    }
    return result
  }, [])

  return (
    <>
      <HeroBgImage backgroundImg={HeroImage} type={'simple'}/>
      <section className="section-content doc-section">
        <Container>
          <SectionTitle title={`Documentation`}/>
          <Row>
            <Col md={8} className="d-flex">
              <div className="bg-white content-box">
                <IconBlock
                  icon={'book-open'}
                  textContainer={{
                    title: 'Welcome to your documents portal',
                    text: [
                      `We have compiled a series of useful documents for both 
                       Location Owners/Authorised Representatives and Industry 
                       Professionals alike.  These include downloadable word document 
                       Location Agreement Templates, Risk Assessment Templates 
                       & Statement-of-Intent Form as well as a range of helpful 
                       guidelines & information`,
                    ],
                  }}
                  smHidden
                />
              </div>
            </Col>
            <Col className="d-none d-md-block" md={4}>
              <Card
                cardProps
                type={'link'}
                data={{
                  icon: 'info-circle',
                  title: 'Help & Support',
                  excerption:
                    'If you require any help or support from Kays Locations, get in touch!',
                  url: '/help-support',
                  buttonText: 'Read more',
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col className="accordion-block">
              <Accordion downloadDocument={downloadDocument} cards={cardHolder}/>
            </Col>
          </Row>

          <Row className="d-md-none">
            <Col>
              <Card
                cardProps
                type={'link'}
                data={{
                  icon: 'info-circle',
                  title: 'Help & Support',
                  excerption:
                    'If you require any help or support from Kays Locations, get in touch!',
                  url: '/help-support',
                  buttonText: 'Read more',
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Documents
