export const locationCategoriesConstants = {
  GET_LOCATION_ALPHABET_CATEGORIES_REQUEST: 'GET_LOCATION_ALPHABET_CATEGORIES_REQUEST',
  GET_LOCATION_ALPHABET_CATEGORIES_SUCCESS: 'GET_LOCATION_ALPHABET_CATEGORIES_SUCCESS',
  GET_LOCATION_ALPHABET_CATEGORIES_FAILURE: 'GET_LOCATION_ALPHABET_CATEGORIES_FAILURE',

  GET_LOCATION_CATEGORIES_REQUEST: 'GET_LOCATION_CATEGORIES_REQUEST',
  GET_LOCATION_CATEGORIES_SUCCESS: 'GET_LOCATION_CATEGORIES_SUCCESS',
  GET_LOCATION_CATEGORIES_FAILURE: 'GET_LOCATION_CATEGORIES_FAILURE',

  GET_LOCATION_KEYWORDS_REQUEST: 'GET_LOCATION_KEYWORDS_REQUEST',
  GET_LOCATION_KEYWORDS_SUCCESS: 'GET_LOCATION_KEYWORDS_SUCCESS',
  GET_LOCATION_KEYWORDS_FAILURE: 'GET_LOCATION_KEYWORDS_FAILURE',

  GET_LOCATION_CATEGORIES_MASTER_SUCCESS: 'GET_LOCATION_CATEGORIES_MASTER_SUCCESS',
}
