import React from 'react'
import './styles.scss'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const HeroBgImage = props => {
  const heroStyle = {
    backgroundImage: `url(${props.backgroundImg})`,
    ...props.style,
  }

  const className = classNames('hero-section__bg', {
    bgCover: props.bgCover,
    'hero-section__bg--simple': props.type === 'simple',
  })

  return props.isHome ? (
    <div className={className} style={heroStyle}></div>
  ) : (
    <section className="hero-section">
      <div className={className} style={heroStyle}></div>
    </section>
  )
}

HeroBgImage.propTypes = {
  // backgroundImg: PropTypes.string.isRequired,
  bgCover: PropTypes.bool,
}

export default HeroBgImage
