import React from 'react'
import { Switch, Route } from 'react-router-dom'

import ProfileInfo from './ProfileInfo'
import ProfileInfoEdit from './ProfileInfoEdit'

const ProfileRoutes = () => {
  return (
    <Switch>
      <Route path="/profile/edit" component={ProfileInfoEdit} />
      <Route path="/profile" component={ProfileInfo} />
    </Switch>
  )
}

export default ProfileRoutes
