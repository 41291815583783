import React from 'react'
import HeroBgImage from '../../components/_common/HeroBgImage/HeroBgImage'
import SectionTitle from '../../components/_common/SectionTitle/SectionTitle'
import PostWithImage from '../../components/_common/PostWithImage/PostWithImage'
import HeroImage from '../../assets/images/adwertise-hero-img.jpg'
import FullWidthSection from '../../components/_common/FullWidthSection/FullWidthSection'
import {pageData} from '../../_content/advertise'


const About = () => (
  <div className="custom-grid">
    <HeroBgImage backgroundImg={HeroImage} type={'simple'} />
    <div className="about-content bg-white">
      <SectionTitle title={'Advertising with Kays Locations'} />
      {pageData.map((item, index) => (
        <PostWithImage
          data={item}
          key={index}
          sectionBg={index % 2 !== 0 ? 'bg-gray' : ''}
        />
      ))}
      <FullWidthSection
        bgImage={false}
        bgColor={'bg-gray'}
        title={'Interested in advertising your film location on the Kays group platforms?'}
        exception={
          'Our friendly and helpful team at Kays would be happy to offer advice and support'
        }
        button={{ btnText: 'GET IN TOUCH', btnColor: 'primary' }}
      />
    </div>

  </div>
)

export default About
