import React from 'react'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import { Field, Form } from 'formik'
import * as Yup from 'yup'
import { Card, CardTitle, Row, Col, FormGroup, Button } from 'reactstrap'
import FormikInput from '../../../components/_common/FormikInput/FormikInput'
import { userActions } from '../../../_actions'
import AlertCustom from '../../../components/_common/Alert';

const validationSchema = Yup.object().shape({
  old_password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Old password is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('New Password is required'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
})

const ChangePassword = ({ changePassword }) => {
  const onSubmit = (values, formActions) => {
    changePassword(values, formActions)
  }
  return (
    <Formik
      initialValues={{
        old_password: '',
        password: '',
        password_confirmation: '',
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors = {},  status = {} }) => {
        return (
          <Card className="profile-box">
            <CardTitle className="profile-box__title" tag={'h4'}>Change password</CardTitle>
            {status.error && <AlertCustom alert={{type: 'danger', message: `${status.error}`}} />}
            <Form>
              <Row form className="flex-column flex-md-row">
                <Col>
                  <FormGroup>
                    <Field
                      component={FormikInput}
                      type="password"
                      name="old_password"
                      placeholder="Old password"
                      errMessage={errors.old_password}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Field
                      component={FormikInput}
                      type="password"
                      name="password"
                      placeholder="New password"
                      errMessage={errors.password}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Field
                      component={FormikInput}
                      type="password"
                      name="password_confirmation"
                      placeholder="Confirm new password"
                      errMessage={errors.password_confirmation}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    color="primary"
                    className="text-uppercase"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Loading...' : 'Update'}
                  </Button>
                  {status.success && <span className="text-success">Successfully updated</span>}
                </Col>
              </Row>
            </Form>
          </Card>
        )
      }}
    </Formik>
  )
}

const mapDispatchToProps = {
  changePassword: userActions.changePassword
}

export default connect(
  null,
  mapDispatchToProps
)(ChangePassword)
