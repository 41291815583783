import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  CardText,
  Button
} from 'reactstrap'

import { formatDate } from '../../../_helpers/date'
import Preloader from '../../../components/_common/Preloader'
import { newsActions } from '../../../_actions/news.actions'
import ShareOnBlock from '../../../components/_common/ShareOnBlock'
import ImageGallery from 'react-image-gallery'

const NewsArticle = ({
  location,
  categories,
  news,
  loadPost,
}) => {
  if (news.isError) return <p className="text-warning">We got some error</p>
  const slug = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
  if (!news.isLoading && !news.isLoaded && !news.isError) {
    loadPost(slug)
    return <Preloader status={true} />
  }
  if (!news.isLoading && !news.isLoaded && news.isError) return <h3>We have an error: {news.isError}</h3>
  if (news.isLoading && !news.isLoaded && !news.isError) return <Preloader status={true} />
  const post = news.data.find(news => news.slug === slug)
  if (!post) {
    loadPost(slug)
    return <Preloader status={true} />
  }
  const {
    title,
    created_at,
    description,
    category_id,
    content,
    image_featured_urls,
    image_urls,
    link_url,
    link_name
  } = post

  const normalizePhoto = () =>  {
    return [...image_featured_urls, ...image_urls]
      .map(item => ({
        src: item,
        original: item,
        thumbnail: item
      }))
  }

  const category = categories.find(item => item.id === category_id)
  const date = formatDate(new Date(created_at))

  return (
    <Card>
      <div>
       <ImageGallery showThumbnails={image_urls.length > 1} items={normalizePhoto()} showBullets={false} showPlayButton={false} showIndex={true} />
      </div>
      <CardBody>
        <Row>
          <Col>
            <span className="news-article__date">{date}</span>
            {category ? <span className={`news-article__category`}> - { category.name}</span> : null}
          </Col>
        </Row>
          <CardTitle tag={'h4'}>
            {title}
          </CardTitle>
        <CardText>{description}</CardText>
        <div className="action-text" dangerouslySetInnerHTML={{__html: content}}/>

        <a href={`${link_url}`} target="_blank" rel="noreferrer noopener"><Button className="news-article__button" size="sm" color="primary">{link_name}</Button></a>
      </CardBody>
        <ShareOnBlock />
    </Card>
  )
}

const mapStateToProps = state => {
  return {
    news: state.news.list,
    categories: state.news.categories.data
  }
}

const mapDispatchToProps = {
  loadPost: newsActions.loadPost
}

NewsArticle.propTypes = {
  post: PropTypes.object,
}

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsArticle)

