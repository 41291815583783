import React from 'react'
import FormInput from '../FormInput/FormInput'

const FormikInput = (props) => {
  const { field, errMessage, form: { touched, errors }} = props
  props.form.validateOnBlur = false
  return (
    <>
      <FormInput  className={touched[field.name] && errMessage ? 'error' : ''} {...field} {...props}/>
      {field.name === 'contact_number' ? (errors[field.name] || errMessage) && (
        <div className={'invalid-feedback'}>{errors[field.name] || errMessage}</div>
      ) : ((errors[field.name]) || errMessage) && (
        <div className={'invalid-feedback'}>{touched[field.name] && (errors[field.name] || errMessage)}</div>
      )}
    </>
  )
}

export default FormikInput
