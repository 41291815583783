import React from 'react'
import { Formik } from 'formik'
import ContactForm from './ContactForm'
import { connect } from 'react-redux'
import { formValidationActions } from '../../../_actions'
import { contactFormSchema } from '../ValidationSchema/index'

const ContactFormContainer = ({ formValidation, sendData }) => {
  const onSubmit = (val, { resetForm }) => {
    sendData(val)
    resetForm({
      name: '',
      email: '',
      contact_number: '',
      location_link: '',
      message: '',
    })
  }
  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        contact_number: '',
        location_link: '',
        message: '',
      }}
      enableReinitialize
      validationSchema={contactFormSchema}
      onSubmit={onSubmit}
    >
      {({ submitForm, isSubmitting, values, handleReset,  ...props }) => {
        return (
          <ContactForm
            isSubmitting={isSubmitting}
            values={values}
            {...props}
          />
        )
      }}
    </Formik>
  )
}

const mapStateToProps = props => {
  const { formValidation } = props
  return { formValidation }
}

const mapDispatchToProps = {
  sendData: formValidationActions.sendData,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactFormContainer)
