import React from 'react'
import { Button, Row, Col, FormGroup } from 'reactstrap'
import FormikInput from '../../_common/FormikInput/FormikInput'
import { Field, Form } from 'formik'
import { connect } from 'react-redux'

const ContactForm = ({ errors, touched, submitForm, isSubmitting, handleSubmit,  ...props }) => {

  return (
    <Form>
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Field
              component={FormikInput}
              type="text"
              name="name"
              placeholder="Your full name"
              id="fullName"
              className={errors.name && touched.name ? 'error-input' : null}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Field
              component={FormikInput}
              type="email"
              name="email"
              placeholder="Your email address"
              id="UserEmail"
              className={errors.email && touched.email ? 'error-input' : null}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Field
              component={FormikInput}
              type="tel"
              name="contact_number"
              placeholder="Your contact number"
              id="contactNumber"
              className={errors.contact_number && touched.contact_number ? 'error-input' : null}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Field
              component={FormikInput}
              type="url"
              name="location_link"
              placeholder="Location name/ID (if applicable)"
              id="linkToLocPage"
              className={errors.link && touched.link ? 'error-input' : null}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Field
              component={FormikInput}
              type="textarea"
              name="message"
              placeholder="How can we help? "
              id="message"
              cols="30"
              rows="7"
              resize={'none'}
              className={''}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="submit-row">
            <Button
              color="primary"
              className="text-uppercase"
              type="submit"
              onClick={submitForm}
            >
              Send
            </Button>
            {props.type === 'success' && (
              <span className={`text-${props.type}`}>
                Thanks, your message has been sent
              </span>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  )
}

const mapStateToProps = state => {
  const { type, message } = state.formValidation
  return { type, message }
}

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(ContactForm)
