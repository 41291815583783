import React from 'react'
import { useDispatch } from 'react-redux'
import { Form, FormGroup, Col, Container } from 'reactstrap'
import LocationCategorySelect from '../../../../components/LocationCategorySelect'
import { searchLocations } from '../../../../_helpers'

import '../styles.scss'


const DropDownFilters = (props) => {
  const dispatch = useDispatch()
  const {changeCategory, category, toogleCategory} = props
  
  const onSubmit = (e) => {
    e.preventDefault()
    searchLocations(dispatch)
    toogleCategory()
  }

  return (
    <div className="modal-filter">
      <Container>
        <Form onSubmit={onSubmit} inline>
          <Col className="col-12">
            <h3>Change location category</h3>
          </Col>
          <Col className="col-12">
            <FormGroup>
              <LocationCategorySelect isClearable={true} placeholder='Choose category' onChange={changeCategory} value={category}/>
            </FormGroup>
          </Col>

          <Col className="col-12">
            <button className="btn btn-primary">SEARCH</button>
          </Col>
        </Form>
      </Container>
    </div>
  )
}

export default DropDownFilters
