import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import { formatDate } from '../../_helpers/date'

import { newsActions } from '../../_actions/news.actions';

const LastNews = ({
  news,
  loadLatest
}) => {
  useEffect(() => {
    loadLatest()
  }, [loadLatest])

  const cutTitle = title => {
    return title.length < 60 ? title : `${title.substring(1, 60)}...`
  }
  
  return (
    <div>
      <h4 className="footer__title">Latest news</h4>
      <ul className="latest-news">
        {news.isError ? (
          <li><p className="text-warning">We got some error</p></li>
        ) : news.data.map((item, index) => {
            if (index >= 3) return null
            return (
              <li key={item.id}>
                <span className="last-news__date">{formatDate(new Date(item.published_at), 'DM')}: </span>
                <Link to={`/news/${item.slug}`}>{cutTitle(item.title)}</Link>
              </li>
            )
          })
        }
      </ul>
    </div>
  );
};

const mapStateToProps = state => ({
  news: state.news.latest
});

const mapDispatchToProps = {
  loadLatest: newsActions.loadLatest
};

export default connect(mapStateToProps, mapDispatchToProps)(LastNews);
