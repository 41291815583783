import React from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import './styles.scss'

const ConfirmModal = props => {
  const {
    modal,
    setModalState,
    modalClose,
    confirmRemove
  } = props

  return(
    <>
      <Modal isOpen={modal} toggle={setModalState(false)} className="contact-modal modal-dialog-centered">
          <ModalBody>
            <Button className="btn-close" color="transparent" onClick={setModalState(false)}><i className="icon-times"></i></Button>
            <ModalHeader>
              REQUEST REMOVAL
            </ModalHeader>
            <div className="contact-holder not-authorised">
              <i className="icon icon-info-circle"></i>
              <span>
                We will review your request to remove this
                listing and will be in touch with you very soon.
              </span>
            </div>
            <div className="btns">
              <Button className="text-uppercase" color="outline-primary" onClick={confirmRemove}>REMOVE</Button>
              <Button className="text-uppercase" color="primary" onClick={modalClose}>CANCEL</Button>
            </div>
          </ModalBody>
      </Modal>
    </>
  )
}

export default ConfirmModal
