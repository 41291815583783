import React from 'react'
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap'
import HeroSection from './HeroSection/HeroSection'
import CardSection from './CardSection/CardSection'
import SectionContent from './SectionContent/SectionContent'
import FullWidthSection from './FullWidthSection/FullWidthSection'
// import NewsSlider from './NewsSlider'
import BackgroundImg from '../../assets/images/fullwidth-bg.jpg'
import RecentlyLocationsSlider from './RecentlyLocationsSlider'

const categories = [
  { title: 'Penthouse Apartments', image: 'penthouse-apartments.jpg', id: 136 },
  { title: 'Castles', image: 'сastles.jpg', id: 174 },
  { title: 'Manor Houses', image: 'manor-houses.jpg', id: 133 },
  { title: 'Private Estates', image: 'private-estates.jpg', id: 178 },
  { title: 'Golf Courses', image: 'racecourses.jpg', id: 153 },
  { title: 'Hotels', image: 'hotels.jpg', id: 37 },
]

const searches = [
  { title: 'Castles in North Wales', image: 'north-wales-castle.jpg', id: 174, longitude: -3.8080783000000338, latitude: 53.0711149, name: 'North Wales', radius: 50},
  { title: 'Rooftop Terraces in London', image: 'rooftop-terrace.jpg', id: 213, longitude: 0.12775829999998223, latitude: 51.5073509, name: 'London'},
  { title: 'Cottages in The Cotswolds', image: 'cottages-costwolds.jpg', id: 113, longitude: -1.8433086000000003, latitude: 51.8330311, name: 'Cotswolds' },
  { title: 'Tudor Houses in Merseyside', image: 'houses-merseyside.jpg', id: 133, longitude: -2.9436643999999887, latitude: 53.3976041, name: 'Merseyside' },
  { title: 'Hotels in England', image: 'hotels.jpg', id: 37, longitude: -1.1743197000000691, latitude: 52.3555177, name: 'England', radius: 100 },
  { title: 'Mansions in South West', image: 'mansions-in-south-west.jpg', id: 133, longitude: -0.5595643999999993, latitude: 51.17812, name: 'South East', radius: 150 },
]

const Home = () => (
  <>
    <HeroSection isHome />
    <CardSection
      data={categories}
      title={'Some of our most popular categories'}
      type="simple"
      footer={
        <div className="text-center">
          <Link to="/all-categories">
            <Button color={'primary'}>VIEW ALL CATEGORIES</Button>
          </Link>
        </div>
      }
    />
    <SectionContent />
    <CardSection
      data={searches}
      title={'Popular searches on Kays Locations'}
      type="simple"
    />
    <FullWidthSection bgImage={BackgroundImg} />
    {/*<NewsSlider />*/}
    <RecentlyLocationsSlider />
  </>
)

export default Home
