import React from 'react'
import { useDispatch } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { withRouter } from 'react-router-dom'

import { locationActions } from '../../_actions'

const Navigation = ({ history, stepsNav }) => {

  const dispatch = useDispatch()
  const goTo = payload => dispatch(locationActions.goTo(payload))

  const navHandler = id => {
    goTo(id)
  }

  const currantStep = stepsNav.routes.find((item) => item.id === stepsNav.currentStep)
  return (
    <ul className="steps-nav">
      {stepsNav.routes.map((item) => (
        <li
          key={item.id}
          onClick={() => navHandler(item.id)}
          className={`step-item ${item.id === currantStep.id ? 'step-item_current' : ''} ${!item.isValid
            ? 'step-item_dissabled'
            : ''}`}
        >
          <Row>
            <Col md={4} lg={3}>
              <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <span className="step-item__counter">{item.id}</span>
              </div>
            </Col>
            <Col md={8} lg={9} className="nav-description">
              <h4 className="step-item__title">{item.title}</h4>
              {item.description ? <p className="step-item__description">{item.description}</p> : null}
            </Col>
          </Row>
        </li>
      ))}
    </ul>
  )
}

export default withRouter(Navigation)
