import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button } from 'reactstrap'
import './styles.scss'

const FullWidthSection = ({ bgImage }) => (
  <section
    className="section-content fullwidth-bg-section industry"
    style={{ backgroundImage: `url(${bgImage})` }}
  >
    <Container>
      <Row>
        <Col className="info-holder">
          <h2 className="info-holder__title">
            Industry professionals sign-up
          </h2>
          <p>
            If you are an industry professional and would like to apply to
            become a member of the Kays Locations user-group please
            contact us by clicking below.
          </p>
          <Link to="/industry_professionals_area">
            <Button color={'light'}>Contact Us</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  </section>
)

export default FullWidthSection
