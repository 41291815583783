import React from 'react'
import CustomCard from '../../../components/_common/Card/Card'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import CarouselSlider from '../../../components/_common/CarouselSlider'
import { useMediaQuery } from 'react-responsive'



const CardSection = ({ data, title, type, footer, carousel }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const sliderData = data.map(item => {
    return {
      url: require(`../../../assets/images/${item.image}`),
      to: `/locations?search-category=${item.id}&longitude=${item.longitude || ''}&latitude=${item.latitude || ''}&search-location=${item.name || ''}&search-radius=${item.radius || ''}`,
      caption: item.title,
      id: item.id
    }
  })

  return (
    <section className="section-content card-content">
      <Container>
        <h2 className="text-center section-content__title">{title}</h2>
        {!isTabletOrMobile ?
        <Row>
          {data.map((item, index) => {
            return (
              <Col md={6} lg={4} key={item.title + index}>
                {item.id ? <Link to={`/locations?search-category=${item.id}&longitude=${item.longitude || ''}&latitude=${item.latitude || ''}&search-location=${item.name || ''}&search-radius=${item.radius || ''}`}>
                  <CustomCard
                    type={type}
                    data={item}
                    cardProps={{ tag: 'a' }}
                    style={{ height: '200px' }}
                  />
                  </Link> :
                  <CustomCard
                    type={type}
                    data={item}
                    cardProps={{ tag: 'a' }}
                    style={{ height: '200px' }}
                  />}
              </Col>
            )
          })}
        </Row>  : <CarouselSlider photos={sliderData} showIndicators={true}/>}
        {footer && <div className="section-footer">
          {footer}
        </div>}
      </Container>
    </section>
  )
}
export default CardSection
