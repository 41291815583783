import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Card,
  CardTitle,
  CardBody,
  CardText,
} from 'reactstrap'

import { formatDate } from '../../../_helpers/date'
import './styles.scss'

const NewsCard = ({
  categories,
  type,
  data,
  data: {
    id,
    title,
    published_at,
    description,
    category_id,
    slug,
    image_featured_urls
  },
  style,
}) => {
  const category = categories.find(item => item.id === category_id)
  const imagePath = image_featured_urls && image_featured_urls[0] ? image_featured_urls[0] : require(`../../../assets/images/slider-img-1.jpg`)
  const date = formatDate(new Date(published_at))
  return (
    <Card className={`card card-news`}>
      <Link to={`news/${slug}`} className={`card__image-wrap`}>
        <div className="card__image" style={{ backgroundImage: `url(${imagePath})`}}/>
      </Link>
      <CardBody>
        <CardTitle tag={'h4'} className={`card-${type}__title`}>
          <Link to={`news/${slug}`}>
            {title}
          </Link>
        </CardTitle>

        <CardText className={`card-${type}-meta`}>
          <small className={`card-${type}-meta__date`}>{date}</small>
          {category ? <span className={`card-${type}-meta__category`}>{category.name}</span> : null}
        </CardText>
        <CardText className="card-news__description">{description.length < 190 ? description : `${description.substring(1, 190)}...`}</CardText>
      </CardBody>
    </Card>
  )
}

NewsCard.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object.isRequired,
}

export default NewsCard
