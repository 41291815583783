import { locationCategoriesConstants } from '../_constants/location-categories.constants';

const initialState = {
  alphabet_list: {
    data: [],
    isLoading: false
  },
  main_list: {
    data: [],
    isLoading: false,
    loaded: false
  },
  keyword_list: {
    data: [],
    isLoading: false,
    loaded: false
  },
  children_list: {
    data: [],
    isLoading: false,
    loaded: false
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case locationCategoriesConstants.GET_LOCATION_ALPHABET_CATEGORIES_REQUEST:
      return {
        ...state,
        alphabet_list: {
          ...state.alphabet_list,
          isLoading: true
        }
      };

    case locationCategoriesConstants.GET_LOCATION_ALPHABET_CATEGORIES_FAILURE:
      return {
        ...state,
        alphabet_list: {
          ...state.alphabet_list,
          isLoading: false
        }
      };

    case locationCategoriesConstants.GET_LOCATION_ALPHABET_CATEGORIES_SUCCESS:
      return {
        ...state,
        alphabet_list: {
          ...state.alphabet_list,
          isLoading: false,
          data: action.payload
        }
      };

    case locationCategoriesConstants.GET_LOCATION_CATEGORIES_REQUEST:
      return {
        ...state,
        main_list: {
          ...state.main_list,
          isLoading: true
        }
      };

    case locationCategoriesConstants.GET_LOCATION_CATEGORIES_FAILURE:
      return {
        ...state,
        main_list: {
          ...state.main_list,
          isLoading: false
        }
      };

    case locationCategoriesConstants.GET_LOCATION_CATEGORIES_SUCCESS:
      return {
        ...state,
        main_list: {
          ...state.main_list,
          isLoading: false,
          loaded: true,
          data: action.payload
        }
      };

    case locationCategoriesConstants.GET_LOCATION_CATEGORIES_MASTER_SUCCESS:
      return  {
        ...state,
        children_list: {
          ...state.main_list,
          isLoading: false,
          loaded: true,
          data: action.payload
        }
      };

    case locationCategoriesConstants.GET_LOCATION_KEYWORDS_REQUEST:
      return {
        ...state,
        keyword_list: {
          ...state.keyword_list,
          isLoading: true
        }
      };

    case locationCategoriesConstants.GET_LOCATION_KEYWORDS_FAILURE:
      return {
        ...state,
        keyword_list: {
          ...state.keyword_list,
          isLoading: false
        }
      };

    case locationCategoriesConstants.GET_LOCATION_KEYWORDS_SUCCESS:
      return {
        ...state,
        keyword_list: {
          ...state.keyword_list,
          isLoading: false,
          loaded: true,
          data: action.payload
        }
      };

    default:
      return state;
  }
}
