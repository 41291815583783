import React, { Component } from 'react'
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselCaption,
  CarouselIndicators
} from 'reactstrap'
import {history} from '../../../_helpers'


class CarouselSlider extends Component {
  state = {
    activeIndex: 0,
    items: this.props.photos
  }

  onExiting = () => {
    this.animating = true
  }

  onExited = () => {
    this.animating = false
  }

  next = () => {
    if (this.animating) return
    const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  previous = () => {
    if (this.animating) return
    const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex = (newIndex) => {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex })
  }

  onSlideClick = (item) => () => {
    if (item.to && item.id) history.push(item.to);
  }

  render() {
    const { activeIndex, items } = this.state
    const { showIndicators } = this.props
    const images = items.map(i => i.url);

    const slides = items.map((item, idx) => {
      return (
        <CarouselItem
          className="custom-tag"
          tag="div"
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={idx}
        >
          {item.to && <CarouselCaption captionText={item.caption} />}
          <img src={item.url} style={{width: '100%'}} alt={""} onClick={this.onSlideClick(item)} />
        </CarouselItem>
      )
    })


    return (
      <div className="card-slider">
        <style>
          {
            `.custom-tag {
                max-width: 100%;
                height: 200px;
              }`
          }
        </style>
        <Carousel
          items={images}
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
          interval={false}
          keyboard={false}
        >
          {showIndicators && <CarouselIndicators items={images} activeIndex={activeIndex} onClickHandler={this.goToIndex}/>}
          {slides}
          {slides.length > 1 &&
            <>
              <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
              <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
            </>
          }
        </Carousel>
      </div>
    )
  }
}


export default CarouselSlider