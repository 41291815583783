import React from 'react'
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import './styles.scss'
import { modalActions, userActions } from '../../../_actions'
import { Field, Formik } from 'formik'
import FormikInput from '../FormikInput/FormikInput'
import { passwordResetSchema } from '../../Forms/ValidationSchema/'

const ForgotModal = () => {
  const modalState = useSelector(state => state.modal)
  const resetState = useSelector(state => state.authentication)
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(modalActions.closeForgotModal())
  }

  const onSubmit = (values) => {
    dispatch(userActions.resetPassword(values.email))
  }

  const reset = () => {
    dispatch(userActions.changeResetInput())
  }
    return (
      <Modal isOpen={modalState.forgotModal} backdrop={'static'} centered>
        <div className="modal-header modal-header--alt">
          <button
            onClick={closeModal}
            className="modal-header__toggler"
          />
        </div>
        <ModalBody className={'modal-body--alt'}>
          <Formik
            initialValues={{
              email: ''
            }}
            enableReinitialize
            validationSchema={passwordResetSchema}
            onSubmit={onSubmit}
            validate={reset}
          >
            {({ submitForm, isSubmitting, values, handleReset, setFieldValue, errors }) => (
              <div className='forgot-pass-modal'>
                <FormGroup>
                  <Field
                    component={FormikInput}
                    type="email"
                    name="email"
                    placeholder="Email address"
                    errMessage={errors.email || (resetState.resetError ? 'email not exist' : '')}
                  />
                </FormGroup>
                {resetState.resetSuccess && <div>{'Success reset'}</div>}
                <Button
                  type='submit'
                  color="primary"
                  className={'w-100 text-uppercase font-bold'}
                  onClick={submitForm}
                >
                  {'Reset password'}
                </Button>
              </div>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    )
  }

export default ForgotModal

