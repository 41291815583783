import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Row,
  Col
} from 'reactstrap';
import Preloader from '../Preloader';
import './styles.scss';

const InfiniteScroll = ({ children, handler, status, isLoadable }) => {
  const loadMore = () => {
    handler()
  }

  return (
      <Row className="infility-scroll">
        <Col xs={12}>
          {children}
        </Col>
        <Col xs={12} className="infility-scroll__load-more">
          {status ? (
            <Preloader status={true} />
          ) : 
            isLoadable ? <Button className="text-uppercase" color="primary" size="lg" onClick={loadMore}>Load More</Button> : null
          }
        </Col>
      </Row>
  )
}

InfiniteScroll.propTypes = {
  children: PropTypes.element,
  handler: PropTypes.func,
  status: PropTypes.bool
}

export default InfiniteScroll
