export const cardData = [
  {
    icon: 'question-circle',
    title: 'Frequently Asked Questions ',
    excerption:
      'Find out if your support query has already been answered in our FAQs ',
    url: '/faq',
    buttonText: 'Read Faqs',
  },
  {
    icon: 'users',
    title: 'Contact Our Team',
    excerption:
      'If you need to raise a query with our in house team then get in touch',
    url: '/contact',
    buttonText: 'GET IN TOUCH',
  },
  {
    icon: 'book-open',
    title: 'Documentation Portal',
    excerption:
      'We’ve put together a section of very useful documents and templates ',
    url: '/documentation',
    buttonText: 'Visit Portal',
  },
  {
    icon: 'camera',
    title: 'Photography Advice',
    excerption:
      'We’ve shared top tips on photography and getting the most from your listing',
    buttonText: 'View PDF',
    downloadLink: 'kays_photography'
  },
  {
    icon: 'rocket',
    title: 'Getting Started ',
    excerption:
      'Our ‘Getting Started’ PDF gives you all the info you’ll need to create a great listing',
    buttonText: 'View PDF',
    downloadLink: 'get_start_file'
  },
  {
    icon: 'file-alt',
    title: 'Our Terms & Conditions ',
    excerption:
      'T&Cs covering the products and services of Kays Locations and their use',
    url: '/terms',
    buttonText: 'READ MORE',
  },
]
