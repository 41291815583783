import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { Form } from 'formik'

import LocationCategorySelect from '../../../LocationCategorySelect'
import LocationTypesRadio from '../../../LocationTypesRadio'
import RequestButton from '../../../_common/RequestButton'
import './styles.scss'

const LocationTypeForm = ({ submitForm, isSubmitting, onChange, ...props }) => {
  const { isRequestSended } = useSelector(state => ({
    isRequestSended: state.locations.newLocation.locationFetch.isLoading
  }))

  return (
    <Form className="location-form" onSubmit={props.handleSubmit}>
      <div className="">
        <LocationTypesRadio {...props} />
      </div>
      <div className="location-item">
        <Row>
          <Col md={6}>
            <div>
              <h3 className="search-title">Location category</h3>
              <LocationCategorySelect
                {...props}
                isClearable={false}
                error={props.errors.locationCategory}
                touched={props.touched.locationCategory}
                onChange={props.setFieldValue}
                name="locationCategory"
                value={props.values.locationCategory}
                placeholder="Select your location category"
                filterByName={props.values.locationType ? 'Residential' : ''}
                skipFilterName={+props.values.locationType === 1}
              />
            </div>
          </Col>
          <Col md={6} className="info-text">
            <div className="icon-holder">
              <i className="icon-exclamation-circle"/>
            </div>
            <p className="margin-bottom">Choosing the right master category is essential to successful enquiries. Please select what you feel is most appropriate. You will be able to add special features and style information in the next section.</p>
          </Col>
        </Row>
      </div>
      <div className="clearfix">
        <RequestButton isLoading={ isRequestSended } className="text-uppercase location-btn" type="submit">
          continue
        </RequestButton>
        {props.type === 'success' && <span className={`text-${props.type}`}>Thanks, your message has been sent</span>}
      </div>
    </Form>
  )
}

export default LocationTypeForm
