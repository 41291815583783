import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { locationCategoriesActions } from '../../_actions/location-categories.actions'
import FormSelect from '../_common/FormSelect/FormSelect'
import { useFetching } from '../../_helpers/hooks';

const LocationCategorySelect = ({filterByName = '', skipFilterName = false, ...props}) => {
  const {data, loaded, loading} = useSelector(({locationCategories}) => ({
    data: locationCategories.main_list.data,
    loaded: locationCategories.main_list.loaded,
    loading: locationCategories.main_list.isLoading,
  }), shallowEqual)
  const options = data.filter(cat => {
    const res = cat.parentCatName.toLowerCase().includes(filterByName.toLowerCase());
    return skipFilterName ? !res: res;
  })
  useFetching(locationCategoriesActions.fetchLocationCategories, useDispatch(), loaded)
  return (
    <FormSelect
      {...props}
      isDisabled={loading}
      isLoading={loading}
      options={options}
      label = {false}
    />
  )
}

export default LocationCategorySelect
