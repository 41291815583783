import { optionsConstants } from '../_constants/form-options.constants'

const initialState = {
  locationTypes: {
    isLoad: false,
    isError: false,
    isLoading: false,
    data: []
  },
  powerSupply: {
    isLoad: false,
    isError: false,
    isLoading: false,
    data: {}
  },
  parking: {
    isLoad: false,
    isError: false,
    isLoading: false,
    data: {}
  },
  filmingAvailablity: {
    isLoad: false,
    isError: false,
    isLoading: false,
    data: {}
  },
  locationSetting: {
    isLoad: false,
    isError: false,
    isLoading: false,
    data: {}
  },
  waterSource: {
    isLoad: false,
    isError: false,
    isLoading: false,
    data: {}
  },
  wifi: {
    isLoad: false,
    isError: false,
    isLoading: false,
    data: {}
  },
  bedrooms: {
    isLoad: false,
    isError: false,
    isLoading: false,
    data: {}
  },
  additionalInfo: {
    isLoad: false,
    isError: false,
    isLoading: false,
    data: {}
  },
  ageStyle: {
    isLoad: false,
    isError: false,
    isLoading: false,
    data: []
  },
  interiorStyle: {
    isLoad: false,
    isError: false,
    isLoading: false,
    data: []
  },
  specificFeatures: {
    isLoad: false,
    isError: false,
    isLoading: false,
    data: []
  },
  videoType: {
    data: {
      options: [
        {
          id: 1,
          label: 'YouTube',
          value: 1
        },
        {
          id: 2,
          label: 'Vimeo',
          value: 2
        },
        {
          id: 3,
          label: 'Matterport Virtual tour',
          value: 3
        }
      ]
    }
  },
  paymentPeriod: {
    data: {
      name: 'Select monthly or annual payment',
      options: [
        {
          id: 1,
          label: 'Monthly payment',
          value: 'month'
        },
        {
          id: 2,
          label: 'Annual payment',
          value: 'year'
        },
      ]
    }
  }
}

export default (state = initialState, action = {}) => {
  const successState = {
    isLoad: true,
    isError: false,
    isLoading: false
  }
  const requestState = {
    isLoad: false,
    isError: false,
    isLoading: true,
    data: []
  }
  const failureState = {
    isLoad: false,
    isError: action.payload,
    isLoading: false,
    data: []
  }
  switch (action.type) {
    case optionsConstants.LOCATION_TYPES_OPTIONS_REQUEST:
      return {
        ...state,
        locationTypes: {
          isLoad: false,
          isError: false,
          isLoading: true,
          data: []
        }
      }

    case optionsConstants.LOCATION_TYPES_OPTIONS_SUCCESS:
      return {
        ...state,
        locationTypes: {
          isLoad: true,
          isError: false,
          isLoading: false,
          data: action.payload
        }
      }

    case optionsConstants.LOCATION_TYPES_OPTIONS_FAILURE:
      return {
        ...state,
        locationTypes: {
          ...state.locationTypes,
          isLoad: false,
          isError: action.payload,
          isLoading: false
        }
      }

    case optionsConstants.LOCATION_DETAILS_REQUEST:
      return {
        ...state,
        powerSupply: requestState,
        parking: requestState,
        locationSetting: requestState,
        filmingAvailablity: requestState,
        waterSource: requestState,
        wifi: requestState,
        bedrooms: requestState,
        additionalInfo: requestState
      }

    case optionsConstants.LOCATION_DETAILS_SUCCESS:
      return {
        ...state,
        powerSupply: {
          ...successState,
          data: action.payload.powerSupply
        },
        parking: {
          ...successState,
          data: action.payload.parking
        },
        filmingAvailablity: {
          ...successState,
          data: action.payload.filmingAvailability
        },
        locationSetting: {
          ...successState,
          data: action.payload.locationSetting
        },
        waterSource: {
          ...successState,
          data: action.payload.waterSource
        },
        wifi: {
          ...successState,
          data: action.payload.wifi
        },
        bedrooms: {
           ...successState,
          data: action.payload.bedrooms
        },
        additionalInfo: {
          ...successState,
         data: action.payload.additionalInfo
       },
      }

    case optionsConstants.LOCATION_KEYWORDS_REQUEST:
      return {
        ...state,
        powerSupply: requestState,
        parking: requestState,
        locationSetting: requestState,
        filmingAvailablity: requestState,
        waterSource: requestState,
        wifi: requestState
      }

    case optionsConstants.LOCATION_DETAILS_FAILURE:
      return {
        ...state,
        powerSupply: failureState,
        parking: failureState,
        locationSetting: failureState,
        filmingAvailablity: failureState,
        waterSource: failureState,
        wifi: failureState,
        additionalInfo: failureState,
      }

    case optionsConstants.LOCATION_KEYWORDS_SUCCESS:
      return {
        ...state,
        ageStyle: {
          ...successState,
          data: action.payload.ageStyle
        },
        interiorStyle: {
          ...successState,
          data: action.payload.interiorStyle
        },
        specificFeatures: {
          ...successState,
          data: action.payload.specificFeatures
        }
      }

    case optionsConstants.LOCATION_KEYWORDS_FAILURE:
      return {
        ...state,
        ageStyle: failureState,
        interiorStyle: failureState,
        specificFeatures: failureState
      }

    default:
      return state
  }
}
