import { documentsConstants } from '../_constants/documents.constants'
import { documentsService } from '../_services/documents.service'

const loadDocuments = () => {
  const loadStart = () => ({
    type: documentsConstants.DOCUMENTS_REQUEST
  })

  const loadSuccess = categories => ({
    type: documentsConstants.DOCUMENTS_SUCCESS,
    payload: categories
  })

  const loadFailure = error => ({
    type: documentsConstants.DOCUMENTS_FAILURE,
    error
  })

  return dispatch => {
    dispatch(loadStart())
    documentsService
      .loadDocuments()
      .then(
        documents => {
          dispatch(loadSuccess(documents))
        }
      ).catch(error => {
      dispatch(loadFailure(error))
    })
  }
}

const downloadDocument = (name) => {
  const loadStart = () => ({
    type: documentsConstants.DOWNLOAD_DOCUMENT_REQUEST
  })

  // const loadSuccess = categories => ({
  //   type: documentsConstants.DOWNLOAD_DOCUMENT_SUCCESS,
  //   payload: categories
  // })
  //
  // const loadFailure = error => ({
  //   type: documentsConstants.DOWNLOAD_DOCUMENT_FAILURE,
  //   error
  // })

  return dispatch => {
    dispatch(loadStart())
    documentsService
      .downloadDocument(name)
    //   .then(
    //     documents => {
    //       dispatch(loadSuccess(documents))
    //     }
    //   ).catch(error => {
    //   dispatch(loadFailure(error))
    // })
  }
}

export const documentsActions = {
  loadDocuments,
  downloadDocument
}