import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'

// import

const PostWithImage = ({
  data: {
    articleTitle,
    description,
    image: {
      imageSrc,
      imageAlt = 'img description',
      width = '100%',
      height = '100%',
    },
    imagePosition,
  },
  sectionBg,
}) => {
  return (
    <section className={`section-content  ${sectionBg ? sectionBg : ''}`}>
      <Container>
        <Row>
          <Col
            md={6}
            className={imagePosition === 'right' ? 'order-1' : 'order-2'}
          >
            <article className={'article-content'}>
              <h5 className={'article-content__title'}>{articleTitle}</h5>
              {description.map((item, index) => (
                <p dangerouslySetInnerHTML={{__html: item}} key={index}/>
              ))}
            </article>
          </Col>
          <Col
            md={6}
            className={`text-center img-wrapper ${
              imagePosition === 'right' ? 'order-2' : 'order-1'
            }`}
          >
            <img src={imageSrc} alt={imageAlt} width={width} height={height} />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

PostWithImage.propTypes = {
  articleTitle: PropTypes.string,
  description: PropTypes.array,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  imagePosition: PropTypes.oneOf(['imageRight', 'imageLeft']),
}

export default PostWithImage
