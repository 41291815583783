import * as Yup from 'yup'


const phoneHelper = Yup.lazy((val = '') => {
  if (!val.startsWith('0')) {
    return Yup.string().length(0, 'Should start with zero').required('Phone number is required')
  } else if (val.startsWith('020')) {
    return Yup.string().matches(/^020-\d{4}\s?\d{4}$/, 'Enter 020-XXXX XXXX || 0XXXX-XXX XXX')
  } else if (val.match(/^\d{4}-/)) {
    return Yup.string().matches(/^\d{4}-\d{3}\s?\d{4}$/, 'Enter 0XXX-XXX XXXX')
  }
  return Yup.string().matches(/^\d{5}-\d{3}\s?\d{3}$/, 'Enter 0XXXX-XXX XXX')
})

const phone_2Helper = Yup.lazy((val = '') => {
  if (!val.startsWith('0')) {
    return Yup.string().length(0, 'Should start with zero')
  } else if (val.startsWith('020')) {
    return Yup.string().matches(/^020-\d{4}\s?\d{4}$/, 'Enter 020-XXXX XXXX || 0XXXX-XXX XXX')
  } else if (val.match(/^\d{4}-/)) {
    return Yup.string().matches(/^\d{4}-\d{3}\s?\d{4}$/, 'Enter 0XXX-XXX XXXX')
  }
  return Yup.string().matches(/^\d{5}-\d{3}\s?\d{3}$/, 'Enter 0XXXX-XXX XXX')
})

const GBPostCode = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/
const videoUrl = new RegExp(/^^(http:\/\/|https:\/\/)(player\.vimeo\.com|vimeo\.com|youtu\.be|www\.youtube\.com|my\.matterport\.com)\/([\w\\/]+)([\\?].*)?$/)

const addressSchema = {
  postCode: Yup.string()
    .matches(GBPostCode, 'You should input correct UK postal code')
    .max(8, 'UK postal code length: ranging from six to eight characters (including a space)')
    .required('UK postal code is required'),
  building: Yup.string().required('Home is required')
  // addressLine1: Yup.string()
  //   .required
}

export const authValidationSchema = () =>
  Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
    consent: Yup.bool().oneOf([true], 'Field is required')
  })
export const loginValidationSchema = () =>
  Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required')
  })

export const passwordResetSchema = () =>
  Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email is required')
  })

export const contactManagerSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Your name is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    contact_number: phoneHelper,
    // location_link: Yup.string()
    //   .url('Enter valid url string')
    //   .required('Link to your Kays Location page is required'),
    location_address: Yup.string().required('Your address is required')
  })

export const contactFormSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Your name is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    contact_number: phoneHelper,
    // link: Yup.string()
    //   .url('Enter valid url string')
    //   .required('Link to your Kays Location page is required'),
    message: ''
  })

export const contactFormLocationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Your name is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    contact_number: phoneHelper,
    message: ''
  })

export const locationDetailsSchema = (type) => {
  return Yup.object().shape({
    name: Yup.string()
      .max(55, 'Name can contain maximum 55 characters')
      .required('Location Name is required field'),
    ...addressSchema,
    contactName: Yup.string()
      .max(255, 'Contact name can contain maximum 255 characters')
      .required('Contact name is required field'),
    emailAddress: Yup.string()
      .email('Required valid email address')
      .required('Contact Email address is required field'),
    phone: phoneHelper,
    phone_2: phone_2Helper,
    phoneProfessionalsOnly: Yup.bool().required(),
    phone_2ProfessionalsOnly: Yup.bool(),
    ageStyle: Yup.array(Yup.number())
      .typeError('Please select one of the options')
      .max(3, 'You can select maximum three options')
      .required('Age or style of your location is required field'),
    interiorStyle: Yup.array(Yup.number())
      .typeError('Please select one of the options')
      .max(8, 'You can select not more then 8 interior style options')
      .required('Interior style is required field'),
    locationSetting: Yup.number()
      .moreThan(0, 'Please select one of the options')
      .required('Location setting is required field'),
    ...(type === 2 ? {bedroomsNo: Yup.number()
        .moreThan(0, 'Please select one of the options')
        .required('No of bedrooms is required field')
    } : {}),
    specificFeatures: Yup.array(Yup.number()).typeError('Please select one of the options').required('Special Features is required field'),
    powerSupply: Yup.number()
      .moreThan(0, 'Please select one of the options')
      .required('Power supply is required field'),
    waterSource: Yup.number()
      .moreThan(0, 'Please select one of the options')
      .required('Water source is required field'),
    wifi: Yup.number()
      .moreThan(0, 'Please select one of the options')
      .required('Wifi is required field'),
    parking: Yup.number()
      .moreThan(0, 'Please select one of the options')
      .required('Parking availability is required field'),
    parkingDetails: Yup.string().max(255, 'Parking details can contain maximum 255 characters'),
    filmingAvailability: Yup.number()
      .moreThan(0, 'Please select one of the options')
      .required('Filming availability is required field'),
    filmingDetails: Yup.string().max(255, 'Filming availability can contain maximum 255 characters'),
    additionalInfo: Yup.array(Yup.number()).typeError('Please select one of the options'),
    description: Yup.string()
      .max(1200, 'In description allowed maximum 1200 characters')
      .required(),
    videoType: Yup.number(),
    videoUrl: Yup.string()
      .matches(videoUrl, 'No valid link')
  })
}

export const locationTypeSchema = () =>
  Yup.object().shape({
    locationType: Yup.number()
      .moreThan(0, 'Please select location type')
      .required('Please select location type'),
    locationCategory: Yup.number().typeError('Please select one of the options').moreThan(0, 'Please select one of the options').required('Please select master category')
  })

export const profileFormSchema = () =>
  Yup.object().shape({
    ...addressSchema
  })

export const locationBuillingSchema = () =>
  Yup.object().shape({
    paymentPeriod: Yup.string()
      .required('Please, select billing plan'),
    ...addressSchema,
    declaration: Yup.bool()
      .oneOf([true], 'You have to accept declaration')
      .required('You have to accept declaration'),
    cardId: Yup.string()
      .ensure()
      .min(1, 'Please add card or select among existing')
      .required('Please add card or select among existing')
  })

export const industryFormSchema = () =>
  Yup.object().shape({
      first_name: Yup.string().required('First name is required'),
      last_name: Yup.string().required('Surname is required'),
      job: Yup.string().required('Job is required'),
      email: Yup.string().email('Email is invalid').required('Email is required'),
      phone: phoneHelper,
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
      ...addressSchema
  })
