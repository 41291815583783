import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Spinner } from 'reactstrap'
import { billingActions } from '../../_actions/billing.actions'
import './styles.scss'

const CardList = ({cards, loading, onActivateCard, onRemoveCard, showRemove = true}) => {
  const handleCardActivate = id => (e) => {
    e.preventDefault();
    onActivateCard(id);
  }

  const handleCardRemove = id => (e) => {
    e.preventDefault();
    onRemoveCard(id);
  }

  return (
    <ul className="payment-cards-list">
      {cards.map((card = {}) => (
        <li className={`payment-card-item ${card.default ? 'active-card' : ''}`} key={card.id}>
          <div className="payment-card-holder">
            <i className="payment-card-icon icon icon-credit-card"/>
            <div className="payment-card-info">
              <div className="txt-top">
                <div className="payment-card-end"><b>Card ending in</b> **** {card.last4}</div>
                
                <div className="payment-card-expire">
                  <b>Expires</b> {card.exp_month}/{card.exp_year}
                </div>

              </div>
              <div className="txt-bottom">
                <div className="payment-card-brand">{card.brand}</div>
                  {showRemove && <div className="payment-card-remove">
                  <button onClick={handleCardRemove(card.id)}>
                    <i className="icon-trash-alt"/>
                    Remove card
                  </button>
                </div>}
              </div>
            </div>
            <div onClick={handleCardActivate(card.id)} className="payment-card-checkbox"/>
          </div>
        </li>
      ))}
      {loading && <li className="payment-card-loader"><Spinner/></li> }
    </ul>
  )
}

const PaymentCards = (props) => {
  const dispatch = useDispatch();
  const {cards, cardsLoading, cardUpdating} = useSelector(({billing}) => ({
    cards: billing.cards,
    cardsLoading: billing.cardsLoading,
    cardUpdating: billing.cardUpdating
  }))
  const removeCard = id => dispatch(billingActions.removeCard(id))
  const activateCard = id => {
    props.activateOption
      ? dispatch(billingActions.activateCard(id))
      : dispatch(billingActions.defaultCard(id))
  }
  useEffect(() => {
    dispatch(billingActions.fetchCards())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (cardsLoading) {
    return <Spinner/>
  }

  return (
      <div className="payment-cards">
        {cards.length
          ? <CardList
            showRemove={props.removeOption}
            loading={cardUpdating}
            onRemoveCard={removeCard}
            onActivateCard={activateCard}
            cards={cards} />
          : <p>Empty card list</p>
        }
      </div>
  )
}

export default PaymentCards
