import React from 'react'
import { Button, FormGroup, FormText, NavLink } from 'reactstrap'
import FormCheckbox from '../../_common/FormCheckbox/FormCheckbox'
import FormikInput from '../../_common/FormikInput/FormikInput'
import { Field, Form } from 'formik'

const UserRegisterForm = ({ toggle, errors = {}, ...props }) => {

  return (
    <Form className={props.type}>
      <FormGroup>
        <Field
          component={FormikInput}
          type="email"
          name="email"
          placeholder="Email address"
          id="createUserEmail"
          errMessage={errors.email}
        />
      </FormGroup>
      <FormGroup>
        <Field
          component={FormikInput}
          type="password"
          name="password"
          id="createPassword"
          placeholder="Create password"
          errMessage={errors.password}
        />
      </FormGroup>
      <FormGroup>
        <Field
          component={FormikInput}
          type="password"
          name="password_confirmation"
          id="confirmPassword"
          placeholder="Confirm your password"
          errMessage={errors.password_confirmation}
        />
      </FormGroup>
      <FormGroup>
        <Field
          component={FormCheckbox}
          id="agreeCheckbox"
          labelTmpl={'privacyPolicy'}
          name={'consent'}
          close={props.close}
        />
      </FormGroup>
      <Button
        type="submit"
        color="primary"
        onClick={props.handleReset}
        className={'w-100 text-uppercase font-bold'}
      >
        {props.registering ? 'Loading...' : 'Create my account'}
      </Button>
      <FormText color="muted" className={'text-center'}>
        Already have an account?
        <NavLink
          onClick={() => {
            toggle(props.type)
          }}
        >
          {' '}
          Log-in
        </NavLink>
      </FormText>
    </Form>
  )
}

export default UserRegisterForm
