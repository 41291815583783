import React from 'react'
import { Swiper, Navigation, Pagination } from 'swiper/dist/js/swiper.esm.js'
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom.js'
import 'react-id-swiper/lib/styles/scss/swiper.scss'
import './styles.scss'

const CustomBuildSwiper = props => {
  // const [swiper, updateSwiper] = useState(null)
  // const goNext = () => {
  //   if (swiper !== null) {
  //     swiper.slideNext()
  //   }
  // }
  // const goPrev = () => {
  //   if (swiper !== null) {
  //     console.log(swiper.destroy())
  //   }
  // }

  const params = {
    pagination: '.swiper-pagination',
    slidesPerView: 3,
    paginationClickable: true,
    spaceBetween: 15,
    Swiper,
    Pagination,
    modules: [Navigation, Pagination],
    // pagination: {
    //   el: '.swiper-pagination',
    //   type: 'bullets',
    // },
    navigation: {
      nextEl: `.swiper-button-prev ${props.children.length < props.quantity ? 'is-hidden' : ''}`,
      prevEl: `.swiper-button-next ${props.children.length < props.quantity ? 'is-hidden' : ''}`,
    },

    breakpoints: {
      580: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
    },
    // on: {
    //   // init: function() {},
    // },
  }

  return (
    <div className="swiper__container">
      {/* getSwiper={updateSwiper */}
      <ReactIdSwiperCustom {...params}>{props.children}</ReactIdSwiperCustom>
    </div>
  )
}

export default CustomBuildSwiper
