import React from 'react'
import { Col, Row, Button, FormGroup, NavLink, FormText } from 'reactstrap'
import FormikInput from '../../_common/FormikInput/FormikInput'
import FormCheckbox from '../../_common/FormCheckbox/FormCheckbox'
import { Field, Form } from 'formik'
import { modalActions } from '../../../_actions'
import { useDispatch } from 'react-redux'

const UserLoginForm = ({ type, toggle, errors, ...props }) => {
  const dispatch = useDispatch()
  const openForgotModal = () => {
    dispatch(modalActions.openForgotModal())
  }
  return (
    <Form className="login">
      <FormGroup>
        <Field
          component={FormikInput}
          type="email"
          name="email"
          placeholder="Email address"
          id="loginUserEmail"
          errMessage={errors.email}
        />
      </FormGroup>
      <FormGroup>
        <Field
          component={FormikInput}
          type="password"
          name="password"
          id="loginPassword"
          placeholder="Password"
          errMessage={errors.password}
        />
      </FormGroup>
      <Row form className="d-flex align-items-center group-2">
        <Col className={'col-6'}>
            <Field
              component={FormCheckbox}
              label="Remember me"
              id="rememberMe"
              name="remember"
            />
        </Col>
        <Col className={'col-6 justify-content-end d-flex'}>
          <NavLink
            className={'ml-0'}
            onClick={() => {
             props.close()
             openForgotModal()
           }}>
            Forgotten password?
          </NavLink>
        </Col>
      </Row>
      <Button
        type={'submit'}
        color="primary"
        onClick={props.handleReset}
        className={'w-100 text-uppercase font-bold'}
      >
        {props.authentication.loggingIn ? 'Loading...' : 'Log-in'}
      </Button>
      <FormText className={'text-center'}>
        New to Kays Locations?{' '}
        <NavLink
          onClick={() => {
            toggle('new_account')
          }}
        >
          Create an account
        </NavLink>
      </FormText>
    </Form>
  )
}

export default UserLoginForm
