import React from 'react'
import PropTypes from 'prop-types'
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  LinkedinShareButton,
  EmailShareButton,
  // FacebookIcon,
  // TwitterIcon,
  // LinkedinIcon,
  // PinterestIcon,
  // EmailIcon,
} from 'react-share'
import './styles.scss'

const propTypes = {
  size: PropTypes.number,
  shareUrl: PropTypes.string,
}

function ShareOnBlock({
  size = 12,
  shareUrl = String(window.location),
  ...props
}) {
  return (
    <div className={'share-btn__holder'}>
      <span className="share-btn__placeholder">Share on...</span>
      <ul className="share-btn__list" style={{ fontSize: size}}>
        <li className="share-btn__item">
          <FacebookShareButton url={shareUrl}>
            <span className="icon-holder">
              <i className="icon icon-facebook-f"></i>
            </span>
          </FacebookShareButton>
        </li>
        <li className="share-btn__item">
          <TwitterShareButton url={shareUrl}>
            <span className="icon-holder">
              <i className="icon icon-twitter"></i>
            </span>
          </TwitterShareButton>
        </li>
        <li className="share-btn__item">
          <PinterestShareButton
            url={shareUrl}
            media={`${String(window.location)}/${props.pinterestPhoto}`}
          >
            <span className="icon-holder">
              <i className="icon icon-pinterest"></i>
            </span>
          </PinterestShareButton>
        </li>
        <li className="share-btn__item">
          <LinkedinShareButton url={shareUrl}>
            <span className="icon-holder">
              <i className="icon icon-linkedin-in"></i>
            </span>
          </LinkedinShareButton>
        </li>
        <li className="share-btn__item">
          <EmailShareButton url={shareUrl}>
            <span className="icon-holder">
              <i className="icon icon-envelope"></i>
            </span>
          </EmailShareButton>
        </li>
      </ul>
    </div>
  )
}

ShareOnBlock.propTypes = propTypes

export default ShareOnBlock
