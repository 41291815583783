import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icons'
import './styles.scss'

const CustomMedia = ({
  mediaData: { icon, text, iconColor = '#d74c62', size = 40 },
}) => {
  return (
    <div className="media-block">
      <div className="media-block__image">
        <Icon name={icon} fill={iconColor} width={size} />
      </div>
      <div className="media-block__text">
        <p>{text}</p>
      </div>
    </div>
  )
}

CustomMedia.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.number,
}

export default CustomMedia
