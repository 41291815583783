import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, FormGroup, Label } from 'reactstrap'
import { Field, Form } from 'formik'

import FormikInput from '../../../_common/FormikInput/FormikInput'
import FormSelect from '../../../_common/FormSelect/FormSelect'
import AddressForm from '../../AddressForm/AddressForm'
import RequestButton from '../../../_common/RequestButton'
import FormFile from '../../../_common/FormFile'
import './styles.scss'
import {history} from '../../../../_helpers'
import { locationActions } from '../../../../_actions'

const LocationDetailsForm = ({ submitForm, isSubmitting, ...props }) => {
  const {
    formOptions,
    isRequestSended,
    isEditFiles,
    contentValues,
    locations,
    formContent
  } = useSelector(store => ({
    formOptions: store.formOptions,
    isEditFiles: store.locations.isEditFiles,
    isRequestSended: store.locations.newLocation.locationFetch.isLoading,
    contentValues: store.locations.content,
    locations: store.locations.newLocation.formValues,
    formContent: store.locations.content
  }))
  const dispatch = useDispatch()
  const checkImg = () => {
    if (!formContent.imageFeaturedUrls.length) {
      dispatch(locationActions.setErrorFeatured('Please upload master photo'))
    }
  }
  const submitName = history.location.pathname === '/add-location' ? 'continue' : 'update'

  useEffect(() => {
    dispatch(locationActions.locationIsEditFiles(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Form>
      <div className="location-item">
        <h5 className="location-detail-title">Location contact details</h5>
        <Row className="location-row-holder">
          <Col xs={12}>
            <FormGroup>
              <Field
                component={FormikInput}
                type="text"
                name="name"
                value={props.values.name}
                onChange={(e) => {
                  props.handleChange(e)
                }}
                onBlur={props.handleBlur}
                placeholder={locations.locationType === 1 ? 'Location Name' : 'Short description eg: Georgian Town House'}
                touched={props.touched.name}
                errMessage={props.errors.name}
              />
            </FormGroup>
          </Col>
        </Row>
        <div>
          <AddressForm {...props} />
        </div>
        <Row className="location-row-holder">
          <Col md={6}>
            <FormGroup>
              <Field
                component={FormikInput}
                type="text"
                name="contactName"
                value={props.values.contactName}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                placeholder="Location contact name"
                touched={props.touched.contactName}
                errMessage={props.errors.contactName}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Field
                component={FormikInput}
                type="text"
                name="emailAddress"
                value={props.values.emailAddress}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                placeholder="Email address for enquires"
                touched={props.touched.emailAddress}
                errMessage={props.errors.emailAddress}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="location-row-holder">
          <Col md={6}>
            <FormGroup>
              <Field
                component={FormikInput}
                type="text"
                name="phone"
                value={props.values.phone}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                placeholder="Primary phone"
                touched={props.touched.phone}
                errMessage={props.errors.phone}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <div className={`custom-checkbox custom-control detail-checkbox ${props.values.phoneProfessionalsOnly ? 'checked' : ''}`}>
              <FormGroup>
                <Label className="check-item" for="phoneProfessionalsOnly">
                  <span className="lable">Telephone visible to industry professionals only</span>
                  <Field
                    component={FormikInput}
                    type="checkbox"
                    id="phoneProfessionalsOnly"
                    name="phoneProfessionalsOnly"
                    value={props.values.phoneProfessionalsOnly}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    checked={props.values.phoneProfessionalsOnly}
                    touched={props.touched.phoneProfessionalsOnly}
                    className={'custom-control-input'}
                  />
                  <span className="check"></span>
                </Label>
              </FormGroup>
            </div>
          </Col>
        </Row>

        <Row className="location-row-holder">
          <Col md={6}>
            <FormGroup>
              <Field
                component={FormikInput}
                type="text"
                name="phone_2"
                value={props.values.phone_2}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                placeholder="Secondary phone"
                touched={props.touched.phone_2}
                errMessage={props.errors.phone_2}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <div className={`custom-checkbox custom-control detail-checkbox ${props.values.phone_2ProfessionalsOnly ? 'checked' : ''}`}>
              <FormGroup>
                <Label className="check-item" for="phone_2ProfessionalsOnly">
                  <span className="lable">Telephone visible to industry professionals only</span>
                  <Field
                    component={FormikInput}
                    type="checkbox"
                    id="phone_2ProfessionalsOnly"
                    name="phone_2ProfessionalsOnly"
                    value={props.values.phone_2ProfessionalsOnly}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    checked={props.values.phone_2ProfessionalsOnly}
                    touched={props.touched.phone_2ProfessionalsOnly}
                    className={'custom-control-input'}
                  />
                  <span className="check"></span>
                </Label>
              </FormGroup>
            </div>
          </Col>
        </Row>

      </div>

      <div className="location-item">
        <h5 className="location-detail-title">Styles and features</h5>
        <Row className="location-row-holder">
          <Col md={6}>
            <FormGroup>
              <FormSelect
                {...props}
                name="ageStyle"
                isClearable={false}
                value={props.values.ageStyle}
                error={props.errors.ageStyle}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                placeholder="Age or style of your location"
                touched={props.touched.ageStyle}
                options={formOptions.ageStyle.data.options}
                isMulti
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <FormSelect
                {...props}
                isClearable={false}
                name="interiorStyle"
                value={props.values.interiorStyle}
                error={props.errors.interiorStyle}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                placeholder="Interior style"
                touched={props.touched.interiorStyle}
                options={formOptions.interiorStyle.data.options}
                isMulti
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="location-row-holder">
          <Col md={6}>
            <FormGroup>
              <FormSelect
                name="locationSetting"
                value={props.values.locationSetting}
                error={props.errors.locationSetting}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                placeholder="Location setting"
                touched={props.touched.locationSetting}
                options={formOptions.locationSetting.data.options}
              />
            </FormGroup>
          </Col>
          {locations.locationType === 2 && <Col md={6}>
            <FormGroup>
              <FormSelect
                {...props}
                name="bedroomsNo"
                value={props.values.bedroomsNo}
                error={props.errors.bedroomsNo}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                placeholder="No of bedrooms (residential only)"
                touched={props.touched.bedroomsNo}
                options={formOptions.bedrooms.data.options}
              />
            </FormGroup>
          </Col>}
        </Row>
        <Row className="location-row-holder">
          <Col md={6}>
             <FormGroup>
              <FormSelect
                {...props}
                isClearable={false}
                name="specificFeatures"
                error={props.errors.specificFeatures}
                touched={props.touched.specificFeatures}
                placeholder="Special features (select up to 20)"
                options={formOptions.specificFeatures.data.options}
                maximum={20}
                isMulti
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <div className="info-detail-holder d-flex align-items-center justify-content-center">
              <span className="icon-holder">
                <i className="icon-info-circle" />
              </span>
              <p>You can select up to 20 special features that best reflect what your location has to offer</p>
            </div>
          </Col>
        </Row>
      </div>

      <div className="location-item">
        <h5 className="location-detail-title">Key information</h5>
        <Row className="location-row-holder">
          <Col md={6}>
            <FormGroup>
              <FormSelect
                {...props}
                name="powerSupply"
                value={props.values.powerSupply}
                error={props.errors.powerSupply}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                placeholder="Power supply"
                touched={props.touched.powerSupply}
                options={formOptions.powerSupply.data.options}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <FormSelect
                {...props}
                name="waterSource"
                value={props.values.waterSource}
                error={props.errors.waterSource}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                placeholder="Water source"
                touched={props.touched.waterSource}
                options={formOptions.waterSource.data.options}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="location-row-holder">
          <Col md={6}>
            <FormGroup>
              <FormSelect
                name="wifi"
                value={props.values.wifi}
                error={props.errors.wifi}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                placeholder="Wi-fi"
                touched={props.touched.wifi}
                options={formOptions.wifi.data.options}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <FormSelect
                name="parking"
                value={props.values.parking}
                error={props.errors.parking}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                placeholder="Parking availability"
                touched={props.touched.parking}
                options={formOptions.parking.data.options}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="location-row-holder">
          <Col md={12}>
            <FormGroup>
              <Field
                component={FormikInput}
                type="text"
                name="parkingDetails"
                id="parkingDetails"
                className="details-input"
                value={props.values.parkingDetails}
                onChange={(e) => {
                  props.handleChange(e)
                }}
                onBlur={props.handleBlur}
                placeholder="Give details of parking facilities (eg: size of parking area)"
                touched={props.touched.parkingDetails}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="location-row-holder">
          <Col md={6}>
            <FormGroup>
              <FormSelect
                name="filmingAvailability"
                value={props.values.filmingAvailability}
                error={props.errors.filmingAvailability}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                placeholder="Filming availability"
                touched={props.touched.filmingAvailability}
                options={formOptions.filmingAvailablity.data.options}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="location-row-holder">
          <Col md={12}>
            <FormGroup>
              <Field
                component={FormikInput}
                type="text"
                name="filmingDetails"
                id="filmingDetails"
                value={props.values.filmingDetails}
                onChange={(e) => {
                  props.handleChange(e)
                }}
                onBlur={props.handleBlur}
                placeholder="Give details of filming availability"
                touched={props.touched.filmingDetails}
                errMessage={props.errors.filmingDetails}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="location-row-holder">
          <Col md={6}>
            <FormGroup>
              <FormSelect
                {...props}
                isClearable={false}
                name="additionalInfo"
                id="additionalInfo"
                type="text"
                value={props.values.additionalInfo}
                error={props.errors.additionalInfo}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                placeholder="Additional info (tick all that apply)"
                touched={props.touched.additionalInfo}
                options={formOptions.additionalInfo.data.options}
                isMulti
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <div className="info-detail-holder d-flex align-items-center justify-content-center">
              <span className="icon-holder">
                <i className="icon-info-circle" />
              </span>
              <p>
                You can select as many additional info options that apply. Giving accurate details will asure the
                quality of your listing
              </p>
            </div>
          </Col>
        </Row>
      </div>

      <div className="location-item">
        <h5 className="location-detail-title">Photos</h5>
        <Row>
          <div className="form-upload col-12">
            <div className="upload-text">
              <p>
                Upload your featured image. This will be the main image displayed in the search results and your
                gallery.
              </p>
              <span>File types: JPEG, PNG max file size 3mb</span>
            </div>
            <FormFile
              acceptedFileTypes={['image/png', 'image/jpeg']}
              maxFileSize="3MB"
              imagePreviewHeight={220}
              allowMultiple={false}
              maxFiles={1}
              className="form-file"
              files={(contentValues.imageFeaturedUrls.length ? contentValues.imageFeaturedUrls : locations.imageFeaturedUrls).map(source => ({source, options: {type: 'limbo'}}))}
              locationId={props.values.id}
              filesKey="imageFeaturedUrls"
              type="featured"
              isEdit={isEditFiles}
              error={contentValues.errorImageFeaturedUrls}
            />

            <div className="upload-text">
              <p>
                Upload up to 30 images to your gallery. Select images that best show all angels and features of your
                location.
              </p>
              <span>File types: JPEG, PNG max file size 3mb</span>
            </div>

            <FormFile
              imagePreviewHeight={140}
              allowMultiple={true}
              maxFiles={30}
              acceptedFileTypes={['image/png', 'image/jpeg']}
              maxFileSize="3MB"
              className="form-file form-file--multiple"
              files={(contentValues.imageUrls.length ? contentValues.imageUrls : locations.imageUrls).map(source => ({source, options: {type: 'limbo'}}))}
              locationId={props.values.id}
              filesKey="imageUrls"
              type="images"
              isEdit={isEditFiles}
              error={contentValues.errorImageUrls}
            />
          </div>
        </Row>
      </div>

      <div className="location-item">
        <h5 className="location-detail-title">Floor plans</h5>
        <Row>
          <div className="form-upload col-12">
            <div className="upload-text">
              <p>If you have professional floor plans already produced, you may upload up to 8 PDFs.</p>
              <span>File types: PDF max file size 3mb</span>
            </div>
            <FormFile
              imagePreviewHeight={140}
              allowImagePreview={false}
              allowMultiple={true}
              maxFiles={8}
              acceptedFileTypes={['application/pdf']}
              maxFileSize="3MB"
              className="form-file form-file--multiple"
              files={(contentValues.pdfUrls.length ? contentValues.pdfUrls : locations.pdfUrls).map(source => ({source, options: {type: 'limbo'}}))}
              locationId={props.values.id}
              filesKey="pdfUrls"
              type="pdf"
              isEdit={isEditFiles}
            />
          </div>
        </Row>
      </div>

      <div className="location-item description-holder">
        <h5 className="location-detail-title">Description</h5>
        <Row>
          <Col xs={12}>
            <Field
              component={FormikInput}
              type="textarea"
              name="description"
              id="description"
              value={props.values.description}
              onChange={(e) => {
                props.handleChange(e)
              }}
              onBlur={props.handleBlur}
              placeholder="Tell us about your location and its special features..."
              touched={props.touched.description}
              errMessage={props.errors.description}
            />
            <span className="description-note">{1200 - props.values.description.length} characters remaining</span>
          </Col>
        </Row>
      </div>

      <div className="location-item">
        <h5 className="location-detail-title">Videos & virtual tours</h5>
        <span className="location-item-text">
          Include a Youtube/Vimeo video show reel, video walk around or a Matterport 3D virtual tour of the inside of
          your property.
        </span>
        <Row>
          <Col md={6}>
            <FormGroup>
              <FormSelect
                name="videoType"
                id="videoType"
                type="text"
                value={props.values.videoType}
                error={props.errors.videoType}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                placeholder="Please select media type"
                touched={props.touched.videoType}
                options={formOptions.videoType.data.options}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Field
                component={FormikInput}
                type="text"
                name="videoUrl"
                id="videoUrl"
                value={props.values.videoUrl}
                onChange={(e) => {
                  props.handleChange(e)
                }}
                onBlur={props.handleBlur}
                placeholder="URL"
                touched={props.touched.videoUrl}
                errMessage={props.errors.videoUrl}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="clearfix">
        <RequestButton isLoading={isRequestSended} className="text-uppercase location-btn" onClick={checkImg} type="submit">{submitName}</RequestButton>
        {props.type === 'success' && <span className={`text-${props.type}`}>Thanks, your message has been sent</span>}
      </div>
    </Form>
  )
}

export default LocationDetailsForm
