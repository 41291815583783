import React, {useEffect} from 'react'
import { useSelector, useDispatch } from "react-redux"
import { Form, FormGroup, Col, Row, Container } from 'reactstrap'
import FormSelect from '../../../../components/_common/FormSelect/FormSelect'
import { history, searchLocations } from '../../../../_helpers'

import '../styles.scss'
import { optionsActions } from '../../../../_actions'

const DropDownFilters = (props) => {
  const { toggleFilters, setLocation, setCategory, feature, style, interior, setFeature, setStyle, setInterior} = props
  const dispatch = useDispatch()
  const {search, pathname} = window.location
  const params = new URLSearchParams(search)

  const formOptions = useSelector(state => state.formOptions)
  const updateLocationSearch = (feature, style, interior) => {
    const p = [];
    if (feature) p.push(feature)
    if (style) p.push(style)
    if (interior) p.push(interior)
    params.set('search-keywords', p.join())
    history.replace(`${pathname}?${params.toString()}`)
  }

  const changeFeatures = (name, value) => {
    setFeature(value)
    updateLocationSearch(value, style, interior)
  }

  const changeStyle = (name, value) => {
    setStyle(value)
    updateLocationSearch(feature, value, interior)
  }

  const changeInterior = (name, value) => {
    setInterior(value)
    updateLocationSearch(feature, style, value)
  }

  const completeOptions = (options) => {
    const searchParams = new URLSearchParams(window.location.search)
    const keywords = searchParams.get('search-keywords') || ''
    const keywordsList = keywords.split(',').map(a => +a)
    if (keywordsList.length) {
      const option = options && options.find(o => keywordsList.includes(o.id))
      if (option) return option.id
    }
    return null
  }
  const loadGroupedKeywords = () => dispatch(optionsActions.loadGroupedKeywords())

  useEffect(() => {
    setFeature(completeOptions(formOptions.specificFeatures.data.options))
    setStyle(completeOptions(formOptions.ageStyle.data.options))
    setInterior(completeOptions(formOptions.interiorStyle.data.options))

    loadGroupedKeywords()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetOptions = () => {
    params.delete('search-location')
    params.delete('longitude')
    params.delete('latitude')
    params.delete('search-keywords')
    params.delete('search-category')
    params.delete('search-radius')
    params.delete('search-sort')
    setFeature('')
    setStyle('')
    setInterior('')
    setLocation('')
    setCategory('')
    history.replace(`${pathname}?${params.toString()}`)
    searchLocations(dispatch)
    toggleFilters()
  }

  return (
    <div className="modal-filter">
      <Container>
        <Form inline>
          <Row>
            <Col className="col-lg-4">
              <FormGroup>
                <h5>Special features</h5>
                <span className="sub-ttl">Refine your search by key features eg: "Stained glass windows"</span>
                <FormSelect
                  placeholder="Please choose a special feature"
                  options={formOptions.specificFeatures.data.options}
                  onChange={changeFeatures}
                  value={feature}
                  isClearable={true}
                  label={false}
                />
              </FormGroup>
            </Col>
            <Col className="col-lg-4">
              <FormGroup>
                <h5>Age/style or property</h5>
                <span className="sub-ttl">Refine your search by style or period eg: "Victorian"</span>
                <FormSelect
                  placeholder="Please choose an age/style"
                  options={formOptions.ageStyle.data.options}  
                  onChange={changeStyle}
                  value={style}
                  isClearable={true}
                  label={false}
                />
              </FormGroup>
            </Col>
            <Col className="col-lg-4">
              <FormGroup>
                <h5>Interior style</h5>
                <span className="sub-ttl">Refine your search by interior style eg: "Derelict"</span>
                <FormSelect
                  placeholder="Please choose an interior style"
                  options={formOptions.interiorStyle.data.options}
                  onChange={changeInterior}
                  value={interior}
                  isClearable={true}
                  label={false}
                />
              </FormGroup>
            </Col>
            <Col className="col-12 text-right">
              <span className="clear-filters" onClick={resetOptions}>Clear all filters</span>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  )
}

export default DropDownFilters
