import { userConstants } from '../_constants'

const initialState = {
  loggedIn: !!localStorage.getItem('user'),
  loggingIn: false,
  resetError: false,
  resetSuccess: false,
  is_industrial: false,
  user: {
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    county: '',
    postcode: '',
    building: '',
  }
}

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true
      }
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        user: action.user,
        is_industrial: action.user.is_industrial
      }
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false
      }
    case userConstants.LOGOUT:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        user: null,
        is_industrial: false,
      }
    case userConstants.UPDATE:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.updatedUser
        },
      }
    case userConstants.RESET_SUCCESS:
      return {
        ...state,
        resetError: false,
        resetSuccess: true

      }
    case userConstants.RESET_ERROR:
      return {
        ...state,
        resetError: true
      }
    case userConstants.RESET_CHANGE:
      return {
        ...state,
        resetError: false,
        resetSuccess: false,
      }
    default:
      return state
  }
}
