import addressConstants from '../_constants/address.constants';

const initialState = {
  formValues: {
    postalCode: '',
    building: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    county: ''
  },
  addressByCode: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    latitude: false,
    longitude: false,
    addresses: [],
    building: {}
  }
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case addressConstants.CLEAR_ADDRESSES: {
      return {
        ...state,
        addressByCode: {
          ...state.addressByCode,
          addresses: [],
        }
      }
    }
    case addressConstants.GET_ADDRESS_BY_POSTAL_CODE_REQUEST: {
      return {
        ...state,
          addressByCode: {
            ...state.addressByCode,
            isLoading: true,
            isLoaded: false,
            isError: false,
          }
      };
    }

    case addressConstants.GET_ADDRESS_BY_POSTAL_CODE_SUCCESS: {
      const { longitude, latitude, postcode } = action.payload;
      if (postcode) {
        const {
          country,
          building_number,
          building_name,
          county,
          district,
          formatted_address,
          line_1,
          line_2,
          line_3,
          line_4,
          locality,
          sub_building_name,
          sub_building_number,
          thoroughfare,
          town_or_city
        } = action.payload.addresses[0];
        return {
          ...state,
            addressByCode: {
              ...state.addressByCode,
              isLoading: false,
              isLoaded: true,
              isError: false,
              latitude,
              longitude,
              postcode,
              building: {
                buildingName: building_name,
                buildingNumber: building_number,
                country,
                county,
                district,
                formattedAddress: formatted_address,
                line_1,
                line_2,
                line_3,
                line_4,
                locality,
                subBuildingName: sub_building_name,
                subBuildingNumber: sub_building_number,
                thoroughfare: thoroughfare,
                townOrCity: town_or_city
              }
            }
        };
      } else {
        const { addresses } = action.payload;
        return {
          ...state,
            addressByCode: {
              isLoading: false,
              isLoaded: true,
              isError: false,
              latitude,
              longitude,
              addresses,
              building: {}
          }
        };
      }
    }

    case addressConstants.GET_ADDRESS_BY_POSTAL_CODE_FAILURE: {
      return {
        ...state,
          addressByCode: {
            isLoading: false,
            isLoaded: false,
            isError: action.payload,
            addresses: []
          }
      };
    }

    default:
      return state
  }
};
