export const handleFormErrors = (err = {}) => {
  if (err.error) {
    return err.error.replace('_', ' ');
  }
  if (err.Message) {
    return err.Message;
  }
  if (err.errors) {
    return Object.keys(err.errors).reduce((result, key) => {
      return result += (err.errors[key].join('\n') + '\n');
    }, '')
  }
  return 'Something went wrong!';
};
