import React from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import './style.scss'

const Preloader = ({ status, color }) => {
  return (
    status ? <div className="preloader">
       <Spinner color={color} />
    </div> : null
  )
}

Preloader.propTypes = {
  status: PropTypes.bool,
  color: PropTypes.string
}

Preloader.defaultProps = {
  status: true,
  color: 'dark'
}

export default Preloader
