import React, {useState} from 'react'
import { Button } from 'reactstrap'
import AuthModal from '../../../../components/_common/Modals/AuthModal'
import '../style.scss'
import { Link } from 'react-router-dom'
import { modalActions } from '../../../../_actions'
import { useDispatch } from 'react-redux'

const ContactNotAutorized = props => {
  const [modalIsOpen, setModal] = useState(false)
  const [activeTab, setActiveTab] = useState('')
  const dispatch = useDispatch()

  const modalClose = () => {
    setModal(false)
  }

  const modalOpen = () => {
    setModal(true)
  }

  const actionHandler = (activeTab) => () => {
    dispatch(modalActions.setActiveTab(activeTab))
    setActiveTab(activeTab)
    setModal(true)
  }

  return (
    <>
      <div className="contact-holder not-authorised">
        <span className="notAuthorisedIcon"><i className="icon-user-lock"/></span>
        <span>
          For quality and security, contacting
          locations is restricted to industry
          professionals only.
        </span>
        <span>
          Please sign in or contact Kays to
          create a new account.
        </span>
      </div>
        <Button className="btn-primary" color="primary" onClick={actionHandler('login')}>Sign in</Button>
        <Link to="/industry_professionals_area">
          <Button className="btn-primary new-account-btn" color="primary">Create a new account</Button>
        </Link>
        <AuthModal
          isOpen={modalIsOpen}
          modal={{ open: modalOpen, close: modalClose }}
          activeTab={activeTab}
        />
    </>
  )
}

export default ContactNotAutorized
