import React, { useEffect, useCallback, useRef } from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'formik';
import FormikInput from '../../_common/FormikInput/FormikInput';
import FormSelect from '../../_common/FormSelect/FormSelect';
import addressActions from '../../../_actions/address.actions';

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const AddressForm = (props) => {
  const { apiLoading, apiAddresses, apiError } = useSelector((state) => ({
    apiLoading: state.address.addressByCode.isLoading,
    apiAddresses: state.address.addressByCode.addresses.map(item => ({ label: item.replace(/(,\s){2,}/g, ', '), value: item })),
    apiError: state.address.addressByCode.isError,
  }));

  const prevPostCode = usePrevious(props.values.postCode);
  const dispatch = useDispatch();
  const getAddresApiRequest = useCallback(
    params => {
      const getAddressByPostalCode = payload => dispatch(addressActions.getAddressByPostalCode(payload));
      getAddressByPostalCode(params);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onLoadAddress = address => {
    const newValues = {...props.values, ...address}
    props.setValues(newValues)
  }
  const isBuildingDisabled = (props.errors.postCode && !props.values.building) || !props.values.postCode

  useEffect(
    () => {
      if (props.values.postCode.length > 4) {
        getAddresApiRequest({ postalCode: props.values.postCode, building: prevPostCode === props.values.postCode ? props.values.building : null, onLoadAddress })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.values.postCode, props.values.building]
  )
  useEffect(
    () => {
      if (prevPostCode && prevPostCode !== props.values.postCode) {
        props.setFieldValue('building', '', false)
        props.setFieldValue('addressLine1', '', false)
        props.setFieldValue('addressLine2', '', false)
        props.setFieldValue('city', '', false)
        props.setFieldValue('county', '', false)
        props.setFieldValue('latitude', '', false)
        props.setFieldValue('longitude', '', false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.values.postCode]
  )
  const {errors = {}} = props

  return (
      <>
        <Row className="location-row-holder">
          <Col md={6}>
            <FormGroup>
              <Field
                render={
                  (props) => <FormikInput placeholder="Post code" {...props} errMessage={apiError || errors.postCode} />
                }
                type="text"
                name="postCode"
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <FormSelect
                name="building"
                value={props.values.building}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                placeholder="Select your address"
                touched={props.touched.building}
                options={apiAddresses}
                isDisabled={isBuildingDisabled}
                error={props.errors.building}
                isLoading={apiLoading}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="location-row-holder">
          <Col md={6}>
            <FormGroup>
            <Field
              component={FormikInput}
              type="text"
              name="addressLine1"
              disabled={true}
              placeholder="Address Line 1"
            />
          </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
            <Field
              component={FormikInput}
              type="text"
              name="addressLine2"
              disabled={true}
              placeholder="Address Line 2"
            />
          </FormGroup>
          </Col>
        </Row>
        <Row className="location-row-holder">
          <Col md={6}>
            <FormGroup>
            <Field
              component={FormikInput}
              type="text"
              name="city"
              disabled={true}
              placeholder="City or town"
            />
          </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
            <Field
              component={FormikInput}
              type="text"
              name="county"
              disabled={true}
              placeholder="County"
            />
          </FormGroup>
          </Col>
        </Row>
      </>
  );
};

export default AddressForm;
