export const newsConstants = {
  CATEGORY_REQUEST: 'NEWS_CATEGORY_REQUEST',
  CATEGORY_SUCCESS: 'NEWS_CATEGORY_SUCCESS',
  CATEGORY_FAILURE: 'NEWS_CATEGORY_FAILURE',
  LIST_REQUEST: 'NEWS_LIST_REQUEST',
  LIST_SUCCESS: 'NEWS_LIST_SUCCESS',
  LIST_FAILURE: 'NEWS_LIST_FAILURE',
  POST_REQUEST: 'NEWS_POST_REQUEST',
  POST_SUCCESS: 'NEWS_POST_SUCCESS',
  POST_FAILURE: 'NEWS_POST_FAILURE',
  SEARCH_REQUEST: 'NEWS_SEARCH_REQUEST',
  SEARCH_SUCCESS: 'NEWS_SEARCH_SUCCESS',
  SEARCH_FAILURE: 'NEWS_SEARCH_FAILURE',
  SET_SEARCH_KEY: 'SET_NEWS_SEARCH_KEY',
  CLEAR_LIST: 'CLEAR_NEWS_LIST',
  CHANGE_FILTER: 'CHANGE_CALENDAR_FILTER',
  INCREASE_PAGE: 'INCREASE_NEWS_PAGE_FILTER',
  CHANGE_CATEGORY: 'CHANGE_NEWS_CATEGORI_FILTER',
  CHANGE_DATE: 'CHANGE_NEWS_DATE_FILTER',
  LATEST_NEWS_REQUEST: 'LATEST_NEWS_POSTS_REQUEST',
  LATEST_NEWS_SUCCESS: 'LATEST_NEWS_POSTS_SUCCESS',
  LATEST_NEWS_FAILURE: 'LATEST_NEWS_POSTS_FAILURE',
  DATES_REQUEST: 'DATES_REQUEST',
  DATES_SUCCESS: 'DATES_SUCCESS',
  DATES_FAILURE: 'DATES_FAILURE',
}
