import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import SectionTitle from '../../components/_common/SectionTitle/SectionTitle'
import HeroBgImage from '../../components/_common/HeroBgImage/HeroBgImage'
import Card from '../../components/_common/Card/Card'
import HeroImage from '../../assets/images/help-support-bg-img.jpg'
import ContactManager from './ContactManager/ContactManager'
import './styles.scss'
import { cardData } from './page-data'

const Account = () => {
  return (
    <>
      <HeroBgImage backgroundImg={HeroImage} type={'simple'} />
      <section className="section-content help-support-section">
        <Container>
          <SectionTitle title={'Help & Support'} />
          <Row>
            {cardData.map((card, index) => (
              <Col md={4} key={card.title + index} className={'mb-4'}>
                <Card type={'link'} data={card} cardProps />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className="contact-manager">
        <Container>
          <div className="bg-white">
            <ContactManager />
          </div>
        </Container>
      </section>
    </>
  )
}

export default Account
