import React from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import { locationCategoriesActions } from '../../_actions/location-categories.actions'
import FormSelect from '../_common/FormSelect/FormSelect'
import { useFetching } from '../../_helpers/hooks';

const LocationKeywordSelect = (props) => {
  const {options, loaded, loading} = useSelector(({locationCategories}) => ({
    options: locationCategories.keyword_list.data,
    loaded: locationCategories.keyword_list.loaded,
    loading: locationCategories.keyword_list.isLoading,
  }), shallowEqual)
  useFetching(locationCategoriesActions.fetchLocationKeywords, useDispatch(), loaded)
  return (
    <FormSelect
      {...props}
      isDisabled={loading}
      isLoading={loading}
      options={options}
      label={false}
    />
  )
}

export default LocationKeywordSelect
