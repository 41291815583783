import React from 'react'
import { Button, FormGroup, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Field, Form, Formik } from 'formik'
import FormikInput from '../../../../components/_common/FormikInput/FormikInput'
import { contactFormLocationSchema } from '../../../../components/Forms/ValidationSchema'
import { formValidationActions } from '../../../../_actions'
import { useDispatch, useSelector } from 'react-redux'
import '../style.scss'
import AuthModal from '../../../../components/_common/Modals/AuthModal'
import { Link } from 'react-router-dom'

const ContactModal = props => {
  const dispatch = useDispatch()
  const validation = useSelector(state => state.formValidation)
  const isLogged = useSelector(state => state.authentication.loggedIn)
  const user = useSelector(state => state.authentication.user)


  const {
    modal,
    setModalState,
    location,
    actionHandler,
    modalIsOpen,
    activeTab,
    modalOpen,
    modalClose
  } = props

  const onSubmit = (val, { resetForm }) => {
    const data = {
      name: val.name,
      email: val.email,
      contact_number:val.contact_number,
      text: val.message
    }
    dispatch(formValidationActions.sendDataLocation(data, location.id))
    setTimeout(() => {resetForm({
      name: userName || '',
      email: user.email || '',
      contact_number: user.phone || '',
      message: '',
    })}, 0)
  }
  const userName = user && `${[user.first_name, user.last_name, user.job].filter(e => e).join(' ')}`

  return (
    <Formik
      initialValues={{
        name: userName || '',
        email: (user && user.email) || '',
        contact_number: (user && user.phone) || '',
        message: '',
      }}
      enableReinitialize
      validationSchema={contactFormLocationSchema}
      onSubmit={onSubmit}
    >
      {({ submitForm, isSubmitting, values, handleReset, errors, touched,  ...props }) => {
        return(
          <>
            <Modal isOpen={modal} toggle={setModalState(false)} className="contact-modal modal-dialog-centered">
              {isLogged ? <ModalBody>
                <Button className="btn-close" color="transparent" onClick={setModalState(false)}><i className="icon-times"></i></Button>
                <ModalHeader>
                  Contact this location
                </ModalHeader>
                <div className="contact-holder">
                  <span className="txt"><i className="icon-user-alt"></i>{location.contactName}</span>
                  <span className="txt"><i className="icon-phone"></i>{location.phone}</span>
                  {location.phone_2 &&<span><i className="icon-smartphone"></i>{location.phone_2}</span>}
                </div>
                <Form className="contact-form">
                  <FormGroup>
                    <Field
                      component={FormikInput}
                      type="text"
                      name="name"
                      placeholder="Name"
                      id="fullName"
                      className={errors.name && touched.name ? 'error-input' : null}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      component={FormikInput}
                      type="phone"
                      name="contact_number"
                      placeholder="Number"
                      id="contactNumber"
                      className={errors.contact_number && touched.contact_number ? 'error-input' : null}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      component={FormikInput}
                      type="email"
                      name="email"
                      placeholder="Email"
                      id="UserEmail"
                      className={errors.email && touched.email ? 'error-input' : null}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      component={FormikInput}
                      type="textarea"
                      name="message"
                      placeholder="Your location enquiry"
                      id="message"
                      className={errors.message && touched.message ? 'error-input' : null}
                    />
                  </FormGroup>
                  <Button
                    className="w-100 text-uppercase"
                    color="primary"
                    type="submit"
                    onClick={submitForm}
                  >
                    Enquire
                  </Button>
                  {validation.type === 'success' && (
                    <span className={`text-${validation.type}`}>
                      Thanks, your message has been sent
                    </span>
                  )}
                  {validation.type === 'danger' && (
                    <span className={`text-${validation.type}`}>
                      Something wrong, try again
                    </span>
                  )}
                </Form>
              </ModalBody> :
                <ModalBody>
                  <Button className="btn-close" color="transparent" onClick={setModalState(false)}><i className="icon-times"></i></Button>
                  <ModalHeader>
                    Contact this location
                  </ModalHeader>
                  <div className="contact-holder not-authorised">
                    <span className="notAuthorisedIcon"><i className="icon-user-lock"/></span>
                    <span>
                      For quality and security, contacting
                      locations is restricted to industry
                      professionals only.
                    </span>
                    <span>
                      Please sign in or contact Kays to
                      create a new account.
                    </span>
                  </div>
                  <Button className="w-100 text-uppercase" color="primary" onClick={actionHandler('login')}>Sign in</Button>
                  <Link to="/industry_professionals_area">
                    <Button className="w-100 text-uppercase new-account-btn" color="primary">Create a new account</Button>
                  </Link>
                </ModalBody>}
            </Modal>
            <AuthModal
              isOpen={modalIsOpen}
              modal={{ open: modalOpen, close: modalClose }}
              activeTab={activeTab}
            />
          </>
        )
      }}
    </Formik>
  )
}

export default ContactModal
