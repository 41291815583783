import React from 'react'
import HeroBgImage from '../../components/_common/HeroBgImage/HeroBgImage'
import SectionTitle from '../../components/_common/SectionTitle/SectionTitle'
import PostWithImage from '../../components/_common/PostWithImage/PostWithImage'
import HeroImage from '../../assets/images/about-hero-img.jpg'
import SectionContent from './SectionContent/SectionContent'
import {pageData} from '../../_content/about'

const About = () => (
  <div className="custom-grid">
    <HeroBgImage backgroundImg={HeroImage} type={'simple'} />
    <div className="about-content bg-white" >
      <SectionTitle title={'About Kays Locations'} />
      {pageData.map((item, index) => (
          <PostWithImage
              data={item}
              key={index}
              sectionBg={index === 1 ? 'bg-gray' : ''}
          />
      ))}
    </div>
    <SectionContent />
  </div>
)

export default About
