import React from 'react'
import { Formik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { Field, Form } from 'formik'
import * as Yup from 'yup'
import { Card, Row, Col, FormGroup, Button } from 'reactstrap'
import FormikInput from '../../../components/_common/FormikInput/FormikInput'
import { userActions } from '../../../_actions'
import AlertCustom from '../../../components/_common/Alert';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email is invalid')
    .required('Email is required'),
})

const ChangeProfile = () => {
  const user = useSelector(state => state.authentication.user)
  const dispatch = useDispatch()
  const onSubmit = (values, formActions) => {
    dispatch(userActions.updateUser(values, user, formActions))
  }
  return (
    <Formik
      initialValues={{
        email: user.email || '',
        first_name: user.first_name || '',
        last_name: user.last_name || '',
        phone: user.phone || ''
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, status = {}, errors }) => {
        return (
          <Card className="profile-box">
            <Form>
              <h4 className="profile-box__title">Name</h4>
              {status.error && <AlertCustom alert={{type: 'danger', message: `${status.error}`}} />}
              <Row form>
                <Col>
                  <FormGroup>
                    <Field
                      component={FormikInput}
                      type="text"
                      name="first_name"
                      errMessage={errors.first_name}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Field
                      component={FormikInput}
                      type="text"
                      name="last_name"
                      errMessage={errors.last_name}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <h4 className="profile-box__title">My account email address</h4>
              <FormGroup>
                <Field
                  component={FormikInput}
                  type="text"
                  name="email"
                  errMessage={errors.email}
                />
              </FormGroup>
              <h4 className="profile-box__title">Telephone number</h4>
              <FormGroup>
                <Field
                  component={FormikInput}
                  type="phone"
                  name="phone"
                  errMessage={errors.phone}
                />
              </FormGroup>
              <Button
                color="primary"
                className="text-uppercase"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Loading...' : 'Update'}
              </Button>
              {status.success && <span className="text-success">Successfully updated</span>}
            </Form>
          </Card>
        )
      }}
    </Formik>
  )
}

export default ChangeProfile
