export const normalizeAmeneties = (location) => {
  const ameneties = []
  const settings = {Rural: 'mountain', Urban: 'city',  Suburban: 'home'}

  ameneties.push(
    {
      icon: 'plug',
      description: location.powerSupply.name,
      status: ![0, 3].includes(location.powerSupply.id)

    },
    {
      icon: 'car-alt',
      description: [1, 6, 7].includes(location.parking.id) ? (location.parking.id === 1 ? 'No parking' : 'Parking available') : location.parking.name,
      status: ![0, 1].includes(location.parking.id)
    },
    {
      icon: 'truck',
      description: [6, 7].includes(location.parking.id) ?  location.parking.name : 'No parking for unit base',
      status: ![0, 1, 2, 3, 4, 5 ].includes(location.parking.id)
    },
    {
      icon: 'couch',
      description: location.bedrooms.id === 0 ? 'Unknown' : location.bedrooms.name ? `${location.bedrooms.name} ${location.bedrooms.id === 1 ? 'bedroom' : 'bedrooms'}` : 'No bedrooms',
      status: ![0].includes(location.bedrooms.id)
    },
    {
      icon: 'wifi',
      description: location.wifi.name,
      status: location.wifi.id === 1
    },
    {
      icon: 'utensils',
      description: location.additionalInfo.find(i => i.name === 'On-site catering') ? 'On-site catering' : 'No on-site catering',
      status: !!location.additionalInfo.find(i => i.name === 'On-site catering')
    },
    {
      icon: 'tint',
      description: location.waterSource.name,
      status: ![0, 2].includes(location.waterSource.id)
    },
    {
      icon: location.locationSetting.id === 0 ? 'mountain' : settings[location.locationSetting.name],
      description: location.locationSetting.name,
      status: ![0].includes(location.locationSetting.id)
    }
  
  )
  return ameneties
}

