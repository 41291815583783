import '../../assets/global-styles/main.scss'
import './App.scss'
import React, { useEffect, useState } from 'react'
import { StripeProvider } from 'react-stripe-elements'
import { connect } from 'react-redux'
import { userActions } from '../../_actions'
import Header from '../Header/'
import Footer from '../Footer/'
import Main from '../Main'
import {initStripe} from '../../_helpers/stripe-helper';

function App(props) {
  const [stripe, setStripe] = useState(null);
  useEffect(() => {
    props.checkUser();
    initStripe(setStripe);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StripeProvider stripe={stripe}>
      <div className="App">
        <Header />
        <Main />
        <Footer />
      </div>
    </StripeProvider>
  )
}

const mapDispatchToProps = {
  checkUser: userActions.checkUser,
};

export default connect(
  null,
  mapDispatchToProps
)(App)
