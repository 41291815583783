import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import SectionTitle from '../../components/_common/SectionTitle/SectionTitle'
import HeroBgImage from '../../components/_common/HeroBgImage/HeroBgImage'
import Card from '../../components/_common/Card/Card'
import HeroImage from '../../assets/images/account-hero-img.jpg'
import './styles.scss'
import {useSelector} from 'react-redux';

const cardInfo = [
  {
    icon: 'user-lock',
    title: 'My Details',
    excerption:
      'Update your account email, billing address or change your account password ',
    url: '/profile',
    buttonText: 'Manage',
  },
  {
    icon: 'heart',
    title: 'My Favourites',
    excerption: 'View and manage your favourite locations',
    url: '/favorites',
    buttonText: 'Manage',
  },
  {
    icon: 'map-marked-alt',
    title: 'My Location Listings',
    excerption: 'View and edit your personal location listings',
    url: '/location_list',
    buttonText: 'Manage',
  },
  {
    icon: 'credit-card',
    title: 'Billing',
    excerption:
      'Update your billing information, view invoices and edit your subscription',
    url: '/billing',
    buttonText: 'Manage',
  },
  {
    icon: 'book-open',
    title: 'Documentation',
    excerption:
      'We’ve put together a section of very useful documents and templates ',
    url: '/documentation',
    buttonText: 'Read more',
  },
  {
    icon: 'info-circle',
    title: 'Help & Support',
    excerption:
      'If you require any help or support from Kays Locations, get in touch!',
    url: '/help-support',
    buttonText: 'Read more',
  },
]



const Account = () => {
  const isIndustrial = useSelector(state => state.authentication.is_industrial)


  return (
    <>
      <HeroBgImage backgroundImg={HeroImage} type={'simple'} />
      <section className="section-content account-content">
        <Container className={'card-container'}>
          <SectionTitle title={'My Account'} />
          <Row>
            {cardInfo.map((card, index) => (
              isIndustrial && ['/billing', '/location_list'].includes(card.url) ? null :
              <Col md={4} key={card.title + index} className={'card-col'}>
                <Card
                  type={'link'}
                  data={card}
                  cardProps={{ url: card.url, tag: 'Link' }}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Account
