import React from 'react'
import { Button } from 'reactstrap'
import './styles.scss'


const BackButton = ({ goToTitle, onClick, className }) => {
  return (
    <Button onClick={onClick} className={`back-button ${className ? className : ''}`} color="link"><span>{"<"} Back to</span> {goToTitle}</Button>
  )
}

export default BackButton