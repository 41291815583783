import React from 'react'
import { Button } from 'reactstrap'
import './styles.scss'
import { useSelector } from 'react-redux'

const AddToFavorite = (props) => {
  const loadingFavorites = useSelector(state => state.locations.singleLocation.loadingFavoritesMain)
  const { isFavourite, action, loading } = props

  return (
    <Button
      color={'transparent'}
      onClick={action}
      className={'btn-favorite'}
      disabled={loadingFavorites || loading}
    >
      {isFavourite ? (
        <i className={'icon icon-heart-full'}></i>
      ) : (
        <i className={'icon icon-heart'}></i>
      )}
    </Button>
  )
}

export default  AddToFavorite