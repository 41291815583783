export const cardsHolder = [
  {
    headerTitle:
      'How do I know if my property is suitable?',
    content:
      `Locations of all shapes & sizes, all over the country,
       are used for filming & photography. From domestic to corporate to 
       military to royal; period, modern, urban, rural; lakes, rivers, beaches,
       cliffs, castles - the requirements are endless! If the location fits 
       the brief then very few places are unsuitable provided they are 
       structurally safe and have the ability to be accessed & serviced.`,
  },
  {
    headerTitle:
      'Can I subscribe if I am already listed with a Location Library?',
    content:
      `If you have a non-exclusive arrangement with a Location Library
       then you are welcome to advertise your location with us as
       well, in your own name. If you have an exclusive arrangement with a
       Location Library then you can still advertise with us but you must
       use the Library in question as the contact.`,
  },
  {
    headerTitle: 'How many photographs can I have on my profile page?',
    content:
     `If you choose to advertise you can have up to 30 photographs per 
      profile page in order to give the best cross-section your property
      has to offer. You must have at least one photograph for the master
      profile photo.`,
  },
  {
    headerTitle: 'Is there a particular way photographs should be taken or presented?',
    content:
      `Basic advice and recommendations on how best to take and present
       photographs can be found in our photography advice document in ‘help 
       and support’. We recommend landscape photos for the web.`,
  },
  {
    headerTitle: 'How can I be sure that I am protected from a Data Protection perspective?',
    content:
      `Your profile page will consist only of information and images provided 
       by or agreed by you for this specific purpose and your details are held
       in a secure environment as per the terms and conditions of our extensive
       <a href="/privacy">Privacy Policy</a> and are not visible to anyone other than Kays Locations
       personnel.  We are committed to safeguarding and protecting all Personal
       Data (as defined in the Data Protection Act 2018, which incorporates the
       General Data Protection Regulation (“Data Protection Act”)).`,
  },
  {
    headerTitle: 'How can I be sure that the general public will not have access to my details?',
    content:
      `Although the website is accessible to all there is no open access to
       any personal details apart from the closed user-group of approved
       industry professionals.  All individuals who wish to join the closed
       user-group will have to register, and access will be denied if they
       cannot be accredited.`,
  },
  {
    headerTitle: 'How can I be sure that my address details will remain confidential?',
    content:
      `Your full address details are held in a secure environment as per
       the terms and conditions of our extensive <a href="/terms">Privacy Policy</a> and are
       not visible to anyone other than Kays Locations personnel. 
       The map on your listing which shows your location is only visible
       to logged in, approved industry professionals and only applies 
       to non-residential locations (i.e. public buildings).`,
  },
  {
    headerTitle: 'What if I have more than one property or own a chain of businesses?',
    content:
      `You may advertise as many properties as you wish, which will
       operate on a sliding discounted scale according to the number of
       locations involved. Please see <a href="/advertise">advertise with us</a> for further information.`,
  },
  {
    headerTitle: 'How do I know if I am being paid an appropriate fee?',
    content:
      `Fees are invariably dictated by type of location, size of crew,
       scale of aspirations, level of disruption, length of visit and the
       overall budget of the production.
       The standard protocol for fees is broken down as follows:
       <p>
         <ul>
          <li>Figure ‘x' for filming days</li>
          <li>50% of ‘x' for prep/strike days</li>
          <li>25% of ‘x’ for hold days</li>
         </ul>
       </p>
       All fees are negotiable.
       For further advice please email info@kayslocations.co.uk.
       (To give an idea, a TV Drama filming in a standard sized house will 
       expect to pay in the region of £1000-£2000 per filming day depending on 
       where it is in the country and the criteria above.)`,
  },
  {
    headerTitle: 'What kind of money can I expect to make?',
    content:
      `Fees are invariably dictated by type of location, size of crew, scale
       of aspirations, level of disruption, length of visit and the overall
       budget of the production. The standard protocol for fees is broken
       down as follows:
       <p>
         <ul>
          <li>Figure ‘x' for filming days</li>
          <li>50% of ‘x' for prep/strike days</li>
          <li>25% of ‘x’ for hold days</li>
         </ul>
       </p>
      All fees are negotiable. For further advice please
      email info@kayslocations.co.uk. (To give an idea, a TV Drama filming in
      a standard sized house will expect to pay in the region of £1000-£2000
      per filming day depending on where it is in the country and the
      criteria above.)`,
  },
  {
    headerTitle: 'How can I ensure that I am not left with damage repair costs?',
    content:
      `Most Location Agreements (Contracts) will have a clause referencing
       the Production Company’s obligation to restore the location to its 
       previous condition. If it is not already there you can insist on 
       inserting it. The Production Company will be in breach of contract 
       if they do not repair any damage they have caused to your satisfaction. 
       It is useful to take ‘before’ and ‘after’ photos if you are concerned. 
       The Location Manager (or equivalent key representative) may do the same. 
       Some Location Agreements have a ‘Release Form’, which you are not 
       obliged to sign until you are satisfied that reinstatement and 
       restitution has been fully completed. Only once the Release Form has 
       been signed are the Company relieved of their legal obligations and 
       responsibilities.  It is extremely rare for a Production Company not 
       to honour their obligations in this regard.`,
  },
  {
    headerTitle: 'What if I am uncertain about a particular element of the filming such as stunt or special effect or the dressing & lighting plans?',
    content:
      `Anything you are unclear about or uncomfortable with should be discussed
       with the Location Manager (or equivalent key representative). Ultimately, 
       your word is final. However if appropriate Risk Assessments & Insurance 
       Cover has been provided then there is usually no reason for concern. For 
       further advice please email info@kayslocations.co.uk.`,
  },
  {
    headerTitle: 'Who do I turn to if there is a clause in the contract I want to discuss?',
    content:
      `Any queries you have with the Location Agreement should be discussed 
      with the Location Manager (or equivalent key representative) of the 
      company in question. Ultimately, your word is final.  For further advice 
      please email info@kayslocations.co.uk.`,
  },
  {
    headerTitle: 'What if I don’t like the look of their contract? Is there a different contract I can use?',
    content:
      `Yes. We have our own downloadable Location Agreement template in our 
      documents portal. It is available as an alternative to that of the 
      Production Company in question. This comes in both Standard and 
      Short form formats and covers both the necessary filming & photography-related 
      requirements for the Production Company (such as the right to make temporary 
      changes to the Location, ownership of the recordings, confidentiality) and 
      the Company’s obligations to the Location Owner (such as indemnity, 
      re-instatement of damage, standards of behavior, payment).`,
  },
  {
    headerTitle: 'Who is my main contact from the Production Company?',
    content:
      `The Location Manager is usually your primary contact as well as any 
      designated members of his/her team. In the event the shoot does not 
      require a locations team (i.e. it is a small Stills shoot) then the 
      Producer will usually be your primary contact.`,
  },
  {
    headerTitle: 'Will I need to move out of my home?',
    content:
      `It is ultimately your choice and dependent on the size of the property, 
      the level of disruption and the length of the visit. It is often impractical 
      for a homeowner to co-exist with the large amounts of equipment, 
      cabling, set-dressing and personnel from both a safety and continuity 
      perspective.  In this instance the Production Company are obliged to 
      offer you a suitable hotel (or similar) during the agreed period as well 
      as provide a food allowance the amount of which is negotiable and may 
      depend on whether spouses, partners, children & pets are involved.  
      For further advice please email info@kayslocations.co.uk.`,
  },
  {
    headerTitle: 'How many people can I expect at my property?',
    content:
      `The number of people at your property will vary greatly according to the 
      stage of the production (recces, preparation, filming, reinstatement) 
      and the type of production (Film, TV, Commercial, Promo, Stills).  
      Initial recces usually consist of a couple of people at most, second & 
      third stage recces will include a few more (perhaps 6 or so) whilst 
      technical recces could be anything up to 40 people in the case of 
      larger productions.  Preparation & reinstatement numbers vary and 
      could be anything from 4 people dressing a kitchen for a TV Drama to 
      100+ people creating an enormous outdoor Set.  The filming days are 
      obviously when numbers are at their highest.  Again this will 
      depend on the type and scale of the production.  A large Feature Film
      may have 150 crew, 20 cast & 300 supporting artists at the location, for 
      example. A standard TV Drama may have 50-60 crew + cast & supporting 
      artists at the location.  TV Comedy drops to around 30-40 crew + cast 
      & supporting artists.  Commercials are often similar in scale to a TV 
      Comedy but it will depend on the product and the number of supporting 
      artists required.  Pop Promos are usually a size down unless they require 
      lots of supporting artists (i.e. for a live performance theme).  
      Stills shoots are comparatively small, often less than 20 people.`,
  },
  {
    headerTitle: 'What paperwork should I expect from the Production Company?',
    content:
      `Once a decision has been made to film at a premises, and dates have 
      been mutually agreed, a formal breakdown of the Production’s plans & 
      wishes should be submitted by the Location Manager (or equivalent key 
      representative) along with a proposal of Fees (which can be negotiated), 
      a Location Agreement, evidence of Liability Insurance cover and, 
      depending on the activity, a Risk Assessment.  Additionally, the 
      practicalities and logistics of the shoot should be worked out and 
      fed back in advance of filming for approval. There is a guideline 
      Paperwork checklist on the website for reference, to clarify what 
      information should be given. There is also a downloadable 
      Statement-of-intent form in your documents portal, which you 
      can ask the Production’s key contact to complete. 
      This Statement-of-intent is designed to provide a comprehensive 
      breakdown of the Production’s plans and wishes and is the basis 
      upon which the Location Agreement is formed.`,
  },
  {
    headerTitle: 'How long does it take from when I join up to becoming visible on the website?',
    content:
      `Once you have completed the membership process your location will 
      be visible on the website within 48 hours.`,
  },
  {
    headerTitle: 'Can I register my property if I am renting it?',
    content:
      `Not if you are renting a residential premises.`,
  },
  {
    headerTitle: 'Do you promote specific or individual locations?',
    content:
      `As part of the general advertising process and the general promotion 
      of the website, both individual locations and themes or categories of 
      locations are promoted in a variety of ways. Please see <a href="/advertise">Advertise with 
      us</a> for further information.`,
  },
  {
    headerTitle: 'How do I know if the person contacting me is genuine?',
    content:
      `Anyone who contacts you via Kays Locations will have done so because 
      they are approved members of the Film & Photographic Industry. Everyone 
      should be able to prove they are working for a particular Production and 
      can give you the production company details for your independent 
      verification should you wish. For further advice please 
      email info@kayslocations.co.uk.`,
  },
  {
    headerTitle: 'What do I do if someone is rude or disrespectful?',
    content:
      `In the unlikely event someone is behaving unprofessionally either before,
       during or after a shoot please inform us at info@kayslocations.co.uk and
       the individual in question will lose the privilege of being part of the 
       closed user-group. Lack of professionalism or respect, albeit 
       extremely rare, is not tolerated. `,
  },
  {
    headerTitle: 'How can I decide if this is right for me or not?',
    content:
      `Although hiring out your premises for filming and photography is 
      an easy way to earn decent money in a short space of time not 
      everyone is comfortable having a film/photography crew in their 
      house or on their premises. Invariably there will be the need for 
      set-dressing, pre-rigging lights and sometimes painting prior to the 
      ‘main event’ at which point the entire crew will descend with all 
      their equipment, in all their glory. Although the shoot will usually 
      be managed by the production’s Locations team it can, of course, be 
      disruptive to your normal schedule and family life. Whilst the vast 
      majority of crews are extremely careful & respectful inevitably there 
      is occasional accidental damage caused against which the Production 
      Company will be fully insured. Any damage will be put right after 
      the shoot and it will be within the terms of the Location Agreement 
      that this is done to your satisfaction. You are also not obliged to 
      accept an enquiry if, for any reason, it does not appeal. It could be 
      inconvenient timing, too potentially disruptive, contentious subject 
      matter or you simply don’t feel like it. It is your decision.`,
  },
]