import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import PrivateRoute from '../_common/PrivateRoute/PrivateRoute'
import Home from '../../pages/Home'
import About from '../../pages/About'
import News from '../../pages/News'
import Account from '../../pages/Account'
import Profile from '../../pages/Profile'
import Faq from '../../pages/Faqs'
import PrivacyPolicy from '../../pages/PrivacyPolicy'
import TermsConditions from '../../pages/TermsConditions'
import AdvertiseWithUs from '../../pages/AdvertiseWithUs'
import HelpSupport from '../../pages/Help-Support'
import Documents from '../../pages/Documents'
import ContactUs from '../../pages/ContactUs'
import MyLocationList from '../../pages/MyLocationList'
import EditLocation from '../../pages/EditLocation'
import LocationList from '../../pages/LocationList'
import Favorites from '../../pages/Favorites'
import LocationSinglePage from '../../pages/LocationSinglePage/'
import AddLocation from '../../pages/AddLocation'
import SignUpIndustry from '../../pages/SignUpIndustry'
import AllLocationCategories from '../../pages/AllLocationCategories'
import LocationPage from '../../pages/LocationList/LocationPage'
import Billing from '../../pages/Billing'
import {useSelector} from 'react-redux';

const Main = props => {
  const holderClasses = classNames({
    'home-page-tmpl': props.match.isExact
  })
  const isIndustrial = useSelector(state => state.authentication.is_industrial)

  return (
    <main className={holderClasses}>
      <Switch>
        <Route exact path="/" component={Home} isHome />
        <Route path="/industry_professionals_area" component={SignUpIndustry} />
        <Route path="/all-categories" component={AllLocationCategories} />
        <Route path="/about" component={About} />
        <Route path="/news" component={News} />
        <Route path="/faq" component={Faq} />
        <Route path="/privacy" component={PrivacyPolicy} />
        <Route path="/terms" component={TermsConditions} />
        <Route path="/advertise" component={AdvertiseWithUs} />
        <Route path="/help-support" component={HelpSupport} />
        <Route path="/documentation" component={Documents} />
        <Route path="/contact" component={ContactUs} />
        <Route path="/locations" component={LocationList} />
        <Route path="/location_page/:id" component={LocationPage} />
        <PrivateRoute path="/account" component={Account} />
        <PrivateRoute path="/profile" component={Profile} />
        {!isIndustrial && <PrivateRoute path="/location_list" component={MyLocationList} />}
        <PrivateRoute path="/edit-location/:id" component={EditLocation} />
        <PrivateRoute path="/favorites/:id" component={LocationSinglePage} />
        <PrivateRoute path="/favorites" component={Favorites} />
        {!isIndustrial &&<PrivateRoute path="/billing" component={Billing} />}
        {!isIndustrial && <PrivateRoute path="/add-location/:id" component={AddLocation}/>}
        {!isIndustrial && <PrivateRoute path="/add-location" component={AddLocation}/>}
        <Redirect to="/"/>
      </Switch>
    </main>
  )
}

export default withRouter(Main)
