import React from 'react'
import FormSelect from '../_common/FormSelect/FormSelect'

const LocationSpecificSelect = (props) => {
  return (
    <FormSelect
      {...props}
      isLoading={props.loaded}
      options={props.locations}
      label= {false}
    />
  )
}

export default LocationSpecificSelect
