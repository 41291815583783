import React from 'react'
import { Button, FormGroup } from 'reactstrap'
import { Field, Form, Formik } from 'formik'
import FormikInput from '../../../../components/_common/FormikInput/FormikInput'
import { contactFormLocationSchema } from '../../../../components/Forms/ValidationSchema'
import { formValidationActions } from '../../../../_actions'
import { useDispatch, useSelector } from 'react-redux'
import '../style.scss'

const ContactAutorized = props => {
  const { location } = props
  const dispatch = useDispatch()
  const validation = useSelector(state => state.formValidation)
  const user = useSelector(state => state.authentication.user)


  const onSubmit = (val, { resetForm }) => {
    const data = {
      name: val.name,
      email: val.email,
      contact_number:val.contact_number,
      text: val.message
    }
    dispatch(formValidationActions.sendDataLocation(data, location.id))
    setTimeout(() => {resetForm({
      name: userName || '',
      email: user.email || '',
      contact_number: user.phone || '',
      message: '',
    })}, 0)
  }

  const userName = `${[user.first_name, user.last_name, user.job && `(${user.job})`].filter(e => e).join(' ')}`

  return (
    <Formik
      initialValues={{
        name: userName || '',
        email: user.email || '',
        contact_number: user.phone || '',
        message: '',
      }}
      enableReinitialize
      validationSchema={contactFormLocationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
    >
      {({ submitForm, isSubmitting, values, handleReset, errors, touched, ...props }) => {
        return(
          <>
            <div className="contact-holder">
              <span><i className="icon-user-alt"></i>{location.contactName}</span>
              <span><i className="icon-phone"></i>{location.phone}</span>
              {location.phone_2 &&<span><i className="icon-smartphone"></i>{location.phone_2}</span>}
            </div>
            <Form className="contact-form">
              <FormGroup>
                <Field
                  component={FormikInput}
                  type="text"
                  name="name"
                  placeholder="Name"
                  id="fullName"
                  className={errors.name && touched.name ? 'error-input' : null}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={FormikInput}
                  type="phone"
                  name="contact_number"
                  placeholder="Number"
                  id="contactNumber"
                  className={errors.contact_number && touched.contact_number ? 'error-input' : null}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={FormikInput}
                  type="email"
                  name="email"
                  placeholder="Email"
                  id="UserEmail"
                  className={errors.email && touched.email ? 'error-input' : null}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={FormikInput}
                  type="textarea"
                  name="message"
                  placeholder="Your location enquiry"
                  id="message"
                  className={errors.message && touched.message ? 'error-input' : null}
                />
              </FormGroup>
              <Button
                className="btn-primary"
                type="submit"
                onClick={submitForm}
              >
                Enquire
              </Button>
              {validation.type === 'success' && (
                <span className={`text-${validation.type}`}>
                  Thanks, your message has been sent
                </span>
              )}
              {validation.type === 'danger' && (
                <span className={`text-${validation.type}`}>
                  Something wrong, try again
                </span>
              )}
            </Form>
          </>
        )
      }}
    </Formik>
  )
}

export default ContactAutorized
