import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { Container, Row, Col, Card, CardBody } from 'reactstrap';

import HeroBgImage from '../../components/_common/HeroBgImage/HeroBgImage'
import HeroImage from '../../assets/images/lighthouse_sunset_web.jpg'
import Filters from '../../components/_common/Filters'
import { newsActions } from '../../_actions/news.actions'
import Routes from './Routes'
import BackButton from '../../components/_common/BackButton'
import NewsSearch from './NewsSearch'
import './styles.scss'

const News = ({
  news: {
    list,
    dates,
    categories
  },
  loadCategories,
  loadDates,
  increasePage,
  changeNewsCategory,
  changeDate,
  history
}) => {
  useEffect(() => {
    loadCategories()
    loadDates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadCategories])
  const regex = RegExp(/\/news\//)
  const isPostPage = regex.test(history.location.pathname)
  const setCategoryId = filter => {
    if (isPostPage) history.push('/news')
    changeNewsCategory(filter.id)
  }

  const setDateFilter = filter => {
    changeDate(filter.archive_id)
  }

  let combinedCategories = categories.data.length > 0 ? [{ id: 0, name: 'All'}].concat(categories.data) : categories.data
  let combinedDates = [{ archive_id: '', month_name: 'All'}].concat(dates.data)

  const filtersArray = { ...categories, data: combinedCategories}
  const filtersArrayDates = { ...dates, data: combinedDates}

  return (
    <div className="news">
    <HeroBgImage backgroundImg={HeroImage} type={'simple'} />
    <section className={`news-content ${isPostPage ? 'post-full' : ''}`}>
      <Container>
        <Row className={`top_block ${isPostPage ? 'top_block__post' : ''}`}>
          <Col xs={12}>
            {isPostPage ? (
              <BackButton color="link" onClick={() => history.push('/news')} goToTitle="News Feed" />
            ) : (
              <h1 className="section-title font-weight-bold text-center">News</h1>
            )}
          </Col>
        </Row>
        <Row className="flex-column-reverse flex-md-row">
          <Col xs={12} md={8}>
            {!list.data.length && list.isLoaded && <div>Sorry, but you are looking for something that isn't here.</div>}
            <Routes />
          </Col>
          <Col xs={12} md={4} className="filters-col">
            <Card className="filters-section">
              <CardBody>
                <NewsSearch />
                <div className="d-none d-md-block">
                  <Filters filters={filtersArray.data} setFilter={setCategoryId} title="Categories" />
                  <Filters filters={filtersArrayDates.data} setFilter={setDateFilter} title="Archive" />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>

    </div>
  )
};

const mapStateToProps = state => ({
  news: state.news,
  page: state.news.page
})

const mapDispatchToProps = {
  loadCategories: newsActions.loadCategories,
  loadDates: newsActions.loadDates,
  changeNewsCategory: newsActions.changeNewsCategory,
  changeDate: newsActions.changeDate,
  newsSearch: newsActions.newsSearch,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(News);
