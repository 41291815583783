export function formatDate(date, type) {
  var monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];
  var daysNames = [
    '1st', '2nd', '3rd', '4th', '5th',
    '6th', '7th', '8th', '9th', '10th',
    '11th', '12th', '13th', '14th', '15th',
    '16th', '17th', '18th', '19th', '20th',
    '21st', '22nd', '23rd', '24th', '25th',
    '26th', '27th', '28th', '29th', '30th',
    '31st'
  ]

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();
  switch (type){
    case 'MY':
      return `${monthNames[monthIndex]} ${year}`

    case 'DM':
      return `${daysNames[day -1]} ${monthNames[monthIndex]}`;

    default:
        return daysNames[day -1] + ' ' + monthNames[monthIndex] + ' ' + year;
  }
}

function twoDigits(d) {
  if(0 <= d && d < 10) return "0" + d.toString();
  if(-10 < d && d < 0) return "-0" + (-1*d).toString();
  return d.toString();
}

export function toMysqlFormat(date) {
  return date.getUTCFullYear() + "-" + twoDigits(1 + date.getUTCMonth()) + "-" + twoDigits(date.getUTCDate()) + " " + twoDigits(date.getUTCHours()) + ":" + twoDigits(date.getUTCMinutes()) + ":" + twoDigits(date.getUTCSeconds());
}

export function formatTime(date) {
  return date.slice(0, 10).split('-').reverse().join('/')
}