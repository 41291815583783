import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, FormGroup, Col, Container } from 'reactstrap'
import LocationKeywordSelect from '../../../../components/LocationKeywordSelect'
import FormSelect from '../../../../components/_common/FormSelect/FormSelect'
import { history, searchLocations } from '../../../../_helpers'
import '../styles.scss'


const DropDownFilters = (props) => {
  const dispatch = useDispatch()
  const {setLocation, setCategory, setFeature, location, radius, changeRadius, radiusOptions, defaultRadiusOption, toggleFiltersResponsive, keyword, setKeyword, style, interior, setStyle, setInterior} = props
  const formOptions = useSelector(state => state.formOptions)
  const {search, pathname} = window.location
  const params = new URLSearchParams(search)


  const updateLocationKeywords = (style, interior) => {
    const p = [];
    if (style) p.push(style)
    if (interior) p.push(interior)
    params.set('search-keywords', p.join())
    history.replace(`${pathname}?${params.toString()}`)
  }

  const changeKeyword = (option, value) => {
    setKeyword(value)
    props.updateLocationSearch('search-keywords', value)
  }

  const changeStyle = (name, value) => {
    setStyle(value)
    updateLocationKeywords(value, interior)
  }

  const changeInterior = (name, value) => {
    setInterior(value)
    updateLocationKeywords(style, value)
  }

  const resetOptions = () => {
    params.delete('search-location')
    params.delete('longitude')
    params.delete('latitude')
    params.delete('search-keywords')
    params.delete('search-category')
    params.delete('search-radius')
    params.delete('search-sort')
    setFeature('')
    setStyle('')
    setInterior('')
    setLocation('')
    setCategory('')
    history.replace(`${pathname}?${params.toString()}`)
    searchLocations(dispatch)
    toggleFiltersResponsive()
  }
  
  const onSubmit = (e) => {
    e.preventDefault()
    searchLocations(dispatch)
    toggleFiltersResponsive()
  }

  return (
    <div className="modal-filter">
      <Container>
        <Form onSubmit={onSubmit} inline>
          <Col className="col-12 col-md-3">
            <FormGroup>
              <h3>Search radius</h3>
              <FormSelect 
                className="radius-select"
                value={radius}
                isDisabled={!location || location.length === 0}
                placeholder="Search Radius"
                onChange={location ? changeRadius : null}
                options={radiusOptions}
                defaultValue={defaultRadiusOption}
                label={false}
              />
            </FormGroup>
          </Col>
          <Col className="col-12 col-md-3">
            <FormGroup>
              <h3>Special features</h3>
              <span className="additional-massage">Refine your search by key features eg: "Stained glass windows"</span>
              <LocationKeywordSelect onChange={changeKeyword} value={keyword} isClearable={true} placeholder="Enter keyword"/>
            </FormGroup>
          </Col>
          <Col className="col-12 col-md-3">
            <FormGroup>
              <h3>Age/style or property</h3>
              <span className="additional-massage">Refine your search by style or period eg: "Victorian"</span>
              <FormSelect
                placeholder="Please choose an age/style"
                options={formOptions.ageStyle.data.options}  
                onChange={changeStyle}
                value={style}
                isClearable={true}
                label={false}
              />
            </FormGroup>
          </Col>
          <Col className="col-12 col-md-3">
            <FormGroup>
              <h3>Interior style</h3>
              <span className="additional-massage">Refine your search by interior style eg: "Derelict"</span>
              <FormSelect
                placeholder="Please choose an interior style"
                options={formOptions.interiorStyle.data.options}
                onChange={changeInterior}
                value={interior}
                isClearable={true}
                label={false}
              />
            </FormGroup>
          </Col>
          <button className="btn btn-primary">SEARCH</button>
          <span className="clear-filters d-block w-100 text-center pt-3" onClick={resetOptions}>Clear all filters</span>

        </Form>
      </Container>
    </div>
  )
}

export default DropDownFilters
