import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field } from 'formik'
import { Input, Spinner } from 'reactstrap'

import { optionsActions } from '../../_actions'

const LocationTypeItem = ({ field: { name, value, onChange, onBlur }, item, id, label, className, setValue, ...props }) => {
  return (
      <div
        className={`location-item location-item--flex ${value === item.id ? 'checked' : ''}`}
        key={item.id}
        onClick={() => setValue(name, item.id)}
      >
          <span className="icon-location-holder">{item.value === 1 ? <i className="icon-industry-alt"></i> : <i className="icon-home"></i>}</span>
          <div className="location-item-descr">

            <div className="top">
              <h6>{item.name}</h6>
              <p>{item.description}</p>
            </div>

            <div className="bottom">
              <span><strong>&#163;{item.monthPrice}</strong> per month or </span>
              <span><strong>&#163;{item.yearPrice}</strong> annually {item.value === 1 ? ' (all prices excl VAT)' : ' (all prices inc VAT)'}</span>
            </div>
          </div>
          <div className="custom-checkbox custom-control location-checkbox">
            <Input  className="custom-control-input" type="checkbox" name={name} checked={value === item.id ? 'checked' : ''} onChange={onChange} />
            <label className="custom-control-label"></label>
          </div>
      </div>
  )
}

const LocationTypesRadio = (props) => {
  const setValue = (name, value) => {
    props.setFieldTouched(name, true)
    props.setFieldValue('locationCategory', 0)
    props.setFieldValue(name, value)
  }

  const locationTypes = useSelector( store => store.formOptions.locationTypes)

  const dispatch = useDispatch()
  const loadLocationTypes = () => dispatch(optionsActions.loadLocationTypes())

  useEffect(() => {
    if (!locationTypes.isLoad) loadLocationTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
      <>
        {locationTypes.isLoad ? locationTypes.data.map((item) => {
          return (
            <Field
              key={item.id}
              component={LocationTypeItem}
              value={props.values.locationType}
              name="locationType"
              id="radioOption1"
              label="Choose this option"
              item={item}
              setValue={setValue}
            />
          )
        }) : locationTypes.isError ? <div className={'invalid-feedback'}>Failed to load location types</div> : <Spinner size="lg" color="dark" />}
        {props.touched['locationType'] && props.errors['locationType'] && (
          <div className={'invalid-feedback'}>{props.errors['locationType']}</div>
        )}
      </>
  )
}

export default LocationTypesRadio
