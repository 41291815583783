import { generalConstants } from '../_constants/config.constants'
import { authHeader } from '../_helpers'
import { handleFormErrors } from '../_helpers/errors'

export const imagesService = {
	fetchLocationImage,
	addLocationImage,
	removeLocationImage,
}

// function fetchLocationImage(locationId, source) {
function fetchLocationImage(source) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader({})
	}
	return (
		fetch(
			// `${generalConstants.BASIC_IMAGE_URL}/storage/location/${locationId}/${source}`,
			`${source}`,
			requestOptions
		)
			.then(handleFileResponse)
	)
}

function addLocationImage(locationId, data, type = 'images') {
	// type can be 'images', 'pdf', 'images/featured'
	const requestOptions = {
		method: 'POST',
		headers: authHeader({}),
		body: data
	}

	return (
		fetch(
			`${generalConstants.BASIC_URL}/locations/${locationId}/${type}`,
			requestOptions
		)
			.then(handleResponse)
	)
}

function removeLocationImage(locationId, name, type = 'images') {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader({})
	}

	return (
		fetch(
			`${generalConstants.BASIC_URL}/locations/${locationId}/${type}/${name}`,
			requestOptions
		)
			.then(handleResponse)
	)
}

function handleResponse(response) {
	return response.json().then((data) => {
		if (!response.ok) {
			const error = handleFormErrors(data)
			return Promise.reject(error)
		}
		return data
	})
}

function handleFileResponse(response) {
	return response.blob().then((file) => {
		if (!response.ok) {
			return Promise.reject('Not found')
		}
		return file
	})
}
