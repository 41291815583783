import React, { useState } from 'react'
import ConfirmModal from './ConfirmModal'
import {
  Card,
  CardTitle,
  CardBody,
  CardText,
  Button,
  CardImg,
} from 'reactstrap'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { formatTime } from '../../../_helpers/date'
import './styles.scss'
import { removeHelper } from '../../../_helpers'


const CardMyLocation = props => {
  const dispatch = useDispatch()
  const [modal, setModal] = useState(false)

  const setStateModal = (state) => () => {
    setModal(state)
  }
  const modalClose = () => {
    setModal(false)
  }
  const {
    id,
    name,
    active,
    address_line1,
    address_line2,
    updated_at,
    categories,
    image_featured_urls,
    payment
  } = props.location

  const removeLocation = () => {
    setModal(true)
  }

  const confirmRemove = () => {
    removeHelper(dispatch, id)
  }

  const getStatus = (p) => {
     switch (p) {
      case 'Draft':
        return (<span className={'draft-status'}>DRAFT</span>)
      case 'Paid (M)':
        return (<span className={'active-status'}>ACTIVE</span>)
      case 'Paid (Y)':
        return (<span className={'active-status'}>ACTIVE</span>)
      case '£ Failed':
        return (<span className={'non-active-status'}>PAYMENT FAILED</span>)
      case '£ Pending':
        return (<span className={'pending-status'}>PAYMENT PENDING</span>)
      case 'Comp':
        return (<span className={'complementary-status'}>COMPLIMENTARY</span>)
      case 'Error':
        return (<span className={'non-active-status'}>ERROR</span>)
       default: return  null
    }
  }

  return (
    <Card className="card-location">
      <ConfirmModal
        modal={modal}
        setModalState={setStateModal}
        confirmRemove={confirmRemove}
        modalClose={modalClose}
      />
      <figure className="card-visual">
        {image_featured_urls.length ? <CardImg top width="100%" src={image_featured_urls.map(item => item)} alt="Card image cap" /> : <div className="no-photo"/>}
      </figure>
      <CardBody>
        <CardTitle tag={'h4'}>
          {name}
        </CardTitle>
        <CardText>
          {[name, address_line2, address_line1].filter(e => e).join(', ')}
        </CardText>

        <div className="info-wrap">
          <ul className="card-infos">
            <li>
              <strong>Category:</strong> {`${categories.map(item => item.name).join(', ')}`}
            </li>
            <li className="txt-rows">
              <div className="txt-row">
                <strong>Listing ID:</strong> {id}
              </div>
              <div className="txt-row">
                <strong>Status:</strong> {getStatus(payment)}
              </div>
            </li>
            <li>
              <strong>Last Updated:</strong> {formatTime(updated_at)}
            </li>
          </ul>

          <div className="bottom-card-section">
            <div className="btns">
              <Button className="btn btn-pink no-bg" onClick={removeLocation}>
                <span className="txt">Remove</span>
                <i className="icon-times-circle"/>
              </Button>
              {!!active && <Link className="btn btn-pink no-bg" to={{pathname: `/location_page/${id}`, state: {fromLocations: 'location_list'}}}>
                <span className="txt">View</span>
                <i className="icon-eye"/>
              </Link>}
              <Link className="btn btn-pink" to={`/${active ? 'edit-location' : 'add-location'}/${id}`}>
                <span className="txt">Edit</span>
                <i className="icon-edit"/>
              </Link>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default CardMyLocation
