import React from 'react'
import HeroBgImage from '../../components/_common/HeroBgImage/HeroBgImage'
import SectionTitle from '../../components/_common/SectionTitle/SectionTitle'
import HeroImage from '../../assets/images/faq-hero-img.jpg'
import { Container, Row, Col } from 'reactstrap'
import './styles.scss'


const TermsConditions = () => {
  return (
    <>
      <HeroBgImage backgroundImg={HeroImage} type={'simple'} />
      <section className="section-content terms-conditions">
        <Container>
          <SectionTitle title={'Terms & Conditions'} />
          <div className={'bg-white text-container'}>
            <Row>
              <Col>
                <article className={'article-content'}>
                  <h2>
                    Kays Locations – Conduct of Industry Professionals (the User Group)
                  </h2>
                  <p>
                    Professionalism & manners must be exercised at all times.
                  </p>
                  <p>
                    Please be courteous and inform a Location if you decide not
                    to go ahead with an enquiry.
                  </p>
                  <p>
                    Please inform Kays Locations if you wish to make a complaint
                    or if a Location has misrepresented itself.
                    Email info@kayslocations.co.uk
                  </p>
                  <p>
                    It is always advisable to have a Location Manager/Location
                    Team facilitating any Location no matter how large or
                    small the production.
                  </p>
                  <p>
                    Where possible, please endeavour to pay for the hire of a
                    Location prior to any filming or stills photography taking
                    place. (This does not apply to the recce process.)
                    If it is not possible or practical to pay in advance
                    then payment should be made within 14 days from the end
                    of your visit.
                  </p>
                </article>
                <article className={'article-content'}>
                  <h3>
                    Kays Locations – Conduct of Location Owners & Authorised Representatives
                  </h3>
                  <p>
                    Honesty and integrity must be exercised at all times.
                  </p>
                  <p>
                    Please use best endeavours to respond to an enquiry within
                    24 hours.  The sooner the response the greater the possibility
                    of filming/stills photography taking place.
                  </p>
                  <p>
                    Please inform Kays Locations if you wish to make a complaint
                    or if an Industry Professional has misrepresented themselves
                    or the Production Company for whom they are working.
                    Email info@kayslocations.co.uk
                  </p>
                  <p>
                    Ideally, a production should pay for the hire of a Location
                    prior to any filming/stills photography taking place.
                    (This does not apply to the recce process.)
                    If it is not possible or practical for the production
                    company to pay in advance then payment should be
                    expected within 14 days from the end of your visit.
                  </p>
                </article>
                <article className={'article-content'}>
                  <h3>
                    Kays Locations - Disclaimer
                  </h3>
                  <p>
                    All advice and guidance provided by Kays Locations and its
                    associates is given in good faith and based on their opinion
                    as experienced professionals in their specialist field.
                    However, the final decision on all matters rests with the
                    Location owner or the Location’s authorised representative.
                    Kays Locations gives no guarantee that your property will
                    be hired.
                  </p>
                </article>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  )
}

export default TermsConditions
