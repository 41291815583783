import React, {useState}  from 'react'
import { Button, Form, FormGroup, Col } from 'reactstrap'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import LocationCategorySelect from '../../LocationCategorySelect'
import LocationKeywordSelect from '../../LocationKeywordSelect'
import LocationPlaces from '../../LocationPlaces'
import { history } from '../../../_helpers'

const SearchForm = () => {
  const [location, setLocation] = useState('')
  const [category, setCategory] = useState('')
  const [keyword, setKeyword] = useState('')

  const {search, pathname} = window.location
  const params = new URLSearchParams(search)


  const updateLocationSearch = (key, value) => {
    params.set(key, value)
    history.replace(`${pathname}?${params.toString()}`)
  }

  const changeCategory = (name, value) => {
    setCategory(+value)
    updateLocationSearch('search-category', value)
  }

  const changeLocation = (option) => {
    geocodeByAddress(option)
      .then(results => getLatLng(results[0]))
      .then(({lat, lng}) => {
        setLocation(option)
        updateLocationSearch('search-location', option)
        updateLocationSearch('longitude', lng)
        updateLocationSearch('latitude', lat)
      })
  }

  const changeKeyword = (option, value) => {
    setKeyword(value)
    updateLocationSearch('search-keywords', value)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    history.push(`/locations?${params.toString()}`)
  }

  return (
    <Form onSubmit={onSubmit} className="search-form" inline>
      <Col lg={3}>
        <FormGroup>
          <LocationCategorySelect onChange={changeCategory} value={category} isClearable={true} placeholder="All categories"/>
        </FormGroup>
      </Col>
      <Col lg={3}>
        <FormGroup>
          <LocationKeywordSelect onChange={changeKeyword} value={keyword} isClearable={true} placeholder="Keyword (optional)"/>
        </FormGroup>
      </Col>
      <Col lg={3}>
        <FormGroup>
          <LocationPlaces locationSelect={changeLocation} placeholder="Anywhere" value={location}/>
        </FormGroup>
      </Col>
      <Col>
        <FormGroup>
          <Button color="primary" className="w-100">
            SEARCH
          </Button>
        </FormGroup>
      </Col>
    </Form>
  )
}

export default SearchForm
