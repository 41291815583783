export const modalConstants = {
  OPEN: 'MODAL_OPEN',
  CLOSE: 'MODAL_CLOSE',
  CLEAR: 'MODAL_CLEAR',
  OPEN_LOCATION_MODAL: 'OPEN_LOCATION_MODAL',
  CLOSE_LOCATION_MODAL: 'CLOSE_LOCATION_MODAL',
  OPEN_FORGOT_MODAL: 'OPEN_FORGOT_MODAL',
  CLOSE_FORGOT_MODAL: 'CLOSE_FORGOT_MODAL',
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB'
}
