import AboutImg_1 from '../assets/images/about-img.jpg'
import AboutImg_2 from '../assets/images/about-img-2.jpg'

export const pageData = [
  {
    description: [
      `<p>
         <b>Kays Locations</b> is the sister company of the <b>Kays Production Manual</b>,
         which is the premier information directory for the UK Film & Television
         industry and has been in operation for over thirty seven years providing
         a comprehensive annual listing of every facet of the business from Suppliers
         to Personnel to Companies to Technicians. It is a secure, closed user-group
         site for exclusive use & reference by approved Film & Television industry personnel.
       </p>
       <p>
         We decided to give the Locations section from the Production Manual
         it’s own platform in order that it can expand and operate independently as <b>Kays Locations</b>.
       </p>`
    ],
    image: {
      imageSrc: AboutImg_1,
      imageAlt: 'Our experiance',
      width: '435',
      height: '251',
    },
    imagePosition: 'right',
  },
  {
    description: [
      `<p>
         Mindful of the ever-increasing need to source locations and assess
         availability & feasibility within tight timelines,
         Kays Locations is the only organisation of it’s kind to give 
         Location Managers and other Industry Professionals the opportunity 
         to communicate directly with prospective filming and photography 
         locations in a safe, data protected environment.
       </p>
       <p>
         Location owners/authorised representatives and Industry professionals 
         alike may also take advantage of our portal of useful downloadable 
         documents such as Location Agreement Templates, Risk Assessment 
         Templates & Statement-of-Intent Form as well as a range of helpful 
         guidelines & information, whilst help and support from Kays Locations 
         personnel is always on hand.
       </p>`
    ],
    image: {
      imageSrc: AboutImg_2,
      imageAlt: 'The team at Kays Locations Photo',
      width: '435',
      height: '251',
    },
    imagePosition: 'left',
  },
]