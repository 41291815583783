import { generalConstants } from '../_constants/config.constants'

export const initStripe = (setStripe) => {
  if (window.Stripe) {
    setStripe(window.Stripe(generalConstants.STRIPE_API_KEY));
  }
  document.querySelector('#stripe-js').addEventListener('load', () => {
    setStripe(window.Stripe(generalConstants.STRIPE_API_KEY));
  });
};
