import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Spinner } from 'reactstrap'
import { billingActions } from '../../_actions/billing.actions'
import './styles.scss'
import PlanCard from './PlanCard'


const PaymentPlans = () => {
  const dispatch = useDispatch()
  const {subscriptions, subscriptionsLoading} = useSelector(({billing}) => ({
    subscriptions: billing.subscriptions,
    subscriptionsLoading: billing.subscriptionsLoading
  }))
  const removeSubscription = id => dispatch(billingActions.removeSubscription(id))
  useEffect(() => {
    dispatch(billingActions.fetchSubscriptions())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (subscriptionsLoading) {
    return <Spinner/>
  }
  return (
      <div className="payment-plans">
        {subscriptions.length
          ? subscriptions.map((item) => (
            <div key={item.id} className="payment-plan">
              <PlanCard subscription={item} onRemovePlan={removeSubscription} />
            </div>
          )) : <p>Empty subscriptions list</p>
        }
      </div>
  )
}

export default PaymentPlans
