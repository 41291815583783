import { generalConstants } from '../_constants/config.constants'

export const formService = {
  sendForm,
  sendFormLocation,
  sendFormLocationManager
}

function sendForm(data) {
  const requestOptions = {
    method: 'POST',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }

  return (
    fetch(
      `${generalConstants.BASIC_URL}/email/contact`,
      requestOptions
    )
      .then(handleResponse)
  )
}

function sendFormLocation(data, id) {
  const requestOptions = {
    method: 'POST',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }

  return (
    fetch(
      `${generalConstants.BASIC_URL}/locations/${id}/contact`,
      requestOptions
    )
      .then(handleResponse)
  )
}

function sendFormLocationManager(data) {
  const requestOptions = {
    method: 'POST',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }

  return (
    fetch(
      `${generalConstants.BASIC_URL}/email/contact_manager`,
      requestOptions
    )
      .then(handleResponse)
  )
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }
    return data
  })
}
