import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik } from 'formik'

import LocationTypeForm from './LocationTypeForm'
import { locationTypeSchema } from '../../ValidationSchema/index'
import { locationActions } from '../../../../_actions/location.actions'

const LocationTypeContainer = props => {
  const {
    storeValues
  } = useSelector(state => ({
    storeValues: state.locations.newLocation.formValues
  }))
  const dispatch = useDispatch()
  const createLocation = params => dispatch(locationActions.createLocation(params))
  const updateForm = params => dispatch(locationActions.updateLocation(params))
  const updateNewLocationValues = params => dispatch(locationActions.updateNewLocationValues(params))
  const goToNext = params => dispatch(locationActions.goToNext())

  const toNextStep = () => {
    goToNext()
  }

  const onSubmit = (val, { resetForm }) => {
    updateNewLocationValues(val)
    if (storeValues.id) {
      updateForm({ location_type_id: val.locationType, category_id: [val.locationCategory], id: storeValues.id, onSuccess: toNextStep })
    } else {
      createLocation({ location_type_id: val.locationType, category_id: [val.locationCategory], onSuccess: toNextStep })
    }
  }

  return (
    <Formik
      initialValues={{
        locationType: storeValues.locationType,
        locationCategory: storeValues.locationCategory
      }}
      enableReinitialize
      validationSchema={locationTypeSchema}
      onSubmit={onSubmit}
    >
      {({ submitForm, isSubmitting, values, handleReset, ...props }) => {
        return (
          <LocationTypeForm
            submitForm={submitForm}
            isSubmitting={true}
            values={values}
            {...props}
          />
        )
      }}
    </Formik>
  )
}

export default LocationTypeContainer
