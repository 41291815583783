import React from 'react'
import { Container, Row, Col, Card } from 'reactstrap'
import { Elements } from 'react-stripe-elements'
import SectionTitle from '../../components/_common/SectionTitle/SectionTitle'
import HeroImage from '../../assets/images/account-hero-img.jpg'
import HeroBgImage from "../../components/_common/HeroBgImage/HeroBgImage";
import PaymentCards from '../../components/PaymentCards'
import StripeForm from '../../components/Forms/StripeForm/StripeForm'
import PaymentPlans from '../../components/PaymentPlans'
import BillingHistory from '../../components/BillingHistory'
import './styles.scss'

const Billing = () => {
  return (
      <>
        <HeroBgImage backgroundImg={HeroImage} type={'simple'} />
        <section className="section-content billing-content">
          <SectionTitle title="Billing"/>
          <Container>
            <Row className="flex-column flex-md-row">
              <Col>
                <Card className="billing-box">
                  <h4 className="billing-box__title">Current plan details</h4>
                  <p className="billing-box__desc">Below are the active subscription packages that are linked to your account…</p>
                  <PaymentPlans />
                  <div className="info-detail-holder d-flex align-items-center">
                    <span className="icon-holder">
                      <i className="icon-info-circle" />
                    </span>
                    <p>
                      Upgrade to an 'Advertiser' account to have your
                      location featured at the top of search results.
                      Advertisers are featured in our locations blog and have
                      full access to the kays marketing platform.
                      <a href="/advertise">Find out more</a></p>
                  </div>
                </Card>
                <Card className="billing-box">
                  <h4 className="billing-box__title">Payment method</h4>
                  <PaymentCards />
                  <h4 className="billing-box__title">Add new card</h4>
                  <Elements>
                    <StripeForm defaultOption={true}/>
                  </Elements>
                </Card>
              </Col>
              <Col>
                <Card className="billing-box">
                  <h4 className="billing-box__title">Billing history</h4>
                  <BillingHistory />
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </>

  )
}

export default Billing
