import { billingConstants } from '../_constants'
import { billingService } from '../_services/billing.service'

export const billingActions = {
  fetchCards,
  addNewCard,
  removeCard,
  defaultCard,
  activateCard,
  fetchSubscriptions,
  removeSubscription,
  fetchInvoices
}

function fetchCards() {
  return dispatch => {
    dispatch({type: billingConstants.BILLING_CARDS_LOADING, payload: true})
    billingService
      .fetchCards()
      .then(
        ({data = []}) => {
          if (data.length) {
            const list = data.map((card, index) => ({...card, default: index === 0}))
            dispatch({type: billingConstants.BILLING_CARDS, payload: list})
            dispatch(activateCard(list[0].id))
          } else {
            dispatch({type: billingConstants.BILLING_CARDS, payload: []})
          }
        },
        error => {
          console.warn(error)
        }
      )
      .finally(() => dispatch({type: billingConstants.BILLING_CARDS_LOADING, payload: false}))
  }
}

function addNewCard(token, defaultPayment) {
  return dispatch => {
    return billingService
      .addNewCard(token, defaultPayment)
      .then(
        (data) => {
          if (data.error) {
            throw Error(data.message)
          }
          if (data.id) {
            return dispatch(billingActions.fetchCards())
          }
        }
      )
  }
}

function removeCard(id) {
  return dispatch => {
    dispatch({type: billingConstants.BILLING_CARD_UPDATING, payload: true})
    billingService
      .removeCard(id)
      .then(
        () => {
          dispatch(billingActions.fetchCards())
        }
      )
      .finally(() => dispatch({type: billingConstants.BILLING_CARD_UPDATING, payload: false}))
  }
}

function defaultCard(id) {
  return dispatch => {
    dispatch({type: billingConstants.BILLING_CARD_UPDATING, payload: true})
    billingService
      .defaultCard(id)
      .then(
        () => {
          dispatch(billingActions.fetchCards())
        }
      )
      .finally(() => dispatch({type: billingConstants.BILLING_CARD_UPDATING, payload: false}))
  }
}

function fetchSubscriptions() {
  return dispatch => {
    dispatch({type: billingConstants.BILLING_SUBSCRIPTIONS_LOADING, payload: true})
    billingService
      .fetchSubscriptions()
      .then(
        (data) => {
          dispatch({type: billingConstants.BILLING_SUBSCRIPTIONS, payload: data})
        },
        error => {
          console.warn(error)
        }
      )
      .finally(() => dispatch({type: billingConstants.BILLING_SUBSCRIPTIONS_LOADING, payload: false}))
  }
}

function removeSubscription(id) {
  return dispatch => {
    dispatch({type: billingConstants.BILLING_SUBSCRIPTIONS_LOADING, payload: true})
    billingService
      .removeSubscription(id)
      .then(
        () => {
          dispatch(billingActions.fetchSubscriptions())
        }
      )
      .finally(() => dispatch({type: billingConstants.BILLING_SUBSCRIPTIONS_LOADING, payload: false}))
  }
}

function activateCard(id) {
  return (dispatch, getState) => {
    const {cards = [], activeCard = {}} = getState().billing;
    if (activeCard.id === id) return;
    const newCard = cards.find(c => c.id === id);
    const newCards = cards.map((card) => ({...card, default: cards.some(() => card.id === id)}))
    dispatch({type: billingConstants.BILLING_CARD_ACTIVATE, payload: newCard})
    dispatch({type: billingConstants.BILLING_CARDS, payload: newCards})
  }
}

function fetchInvoices(params){
  const loadStart = () => ({
    type: billingConstants.GET_INVOICE_REQUEST
  })

  const loadSuccess = (invoices) => ({
    type: billingConstants.GET_INVOICE_SUCCESS,
    payload: invoices
  })

  const loadFailure = (error) => ({
    type: billingConstants.GET_INVOICE_FAILURE,
    error
  })

  return (dispatch) => {
    dispatch(loadStart())
    billingService
      .fetchInvoices(params)
      .then((invoices) => {
        dispatch(loadSuccess(invoices))
      })
      .catch((error) => {
        dispatch(loadFailure(error))
      })
  }
}
