import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardTitle,
  CardBody,
  CardText,
  Button
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'


import CarouselSlider from '../CarouselSlider'
import AddToFavorites from '../AddToFavorites'

import './styles.scss'
import { locationActions } from '../../../_actions'


const CardLocation = props => {
  const isLogged = useSelector(state => state.authentication.loggedIn)
  const dispatch = useDispatch()
  const searchParams = new URLSearchParams(window.location.search)
  const searchLocation = searchParams.get('search-location')
  const searchCategory = searchParams.get('search-category')
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' })


  const {
    id,
    name,
    filming_availability,
    description,
    city,
    county,
    is_favourite,
    image_featured_urls,
    image_urls,
    power_supply,
    parking,
    wifi,
    water_source,
    loadingFavorites,
    featured
  } = props.location


  const photos = [...image_featured_urls, ...image_urls].map(url => ({url}))

  const addToFavorite = (id) => {
    dispatch(locationActions.addToFavorite(id))
  }

  const removeFromFavorite = (id) => {
    dispatch(locationActions.removeFromFavorite(id,  false))
  }

  const _handleClick = () => {
    is_favourite ? removeFromFavorite(id) : addToFavorite(id)
  }

  return (
    <div className={!!featured ? 'card-location-featured' : ''}>
      {!!featured && <div className="card-location-label">Featured Location</div>}
      <Card className="card-location">
        <CarouselSlider photos={photos} />
        <CardBody>
          <CardTitle tag={'h4'}>
            {isLogged ? name : 'Ref ' + id}
          </CardTitle>
          <CardText>
            <small className={'smaller-text'}>
              {isLogged ? [city, county].filter(e => e).join(', ') : null}
            </small>
          </CardText>
          <CardText>{description && description.length > 24 ? description.slice(0, 312) + '...' : description}</CardText>
          <div className="bottom-card-section">
            <div>
              <CardText className="bolder-text">Availability: {filming_availability && filming_availability.name}</CardText>
              <CardTitle className="smaller-text" >
                {`${[power_supply.name, parking.name, wifi.name, water_source.name].filter(e => e).join(', ')} & more...`}
              </CardTitle>
            </div>
            <Link to={{pathname: `/location_page/${id}`, state: {fromLocations: 'locations', searchLocation, searchCategory, category: props.category}}}>
              <Button className="btn-pink">{isTabletOrMobile ? 'View' : 'View Location'}</Button>
            </Link>
            {isLogged ? <AddToFavorites isFavourite={is_favourite} id={id}  action={_handleClick} loading={loadingFavorites} /> : null}
          </div>

        </CardBody>

      </Card>
    </div>

  )
}

CardLocation.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string,
  main_photo: PropTypes.string,
  addedOn: PropTypes.string,
  url: PropTypes.string,
}

export default CardLocation
