import React from 'react'
import { Formik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'formik'
import { Card, CardTitle, Button } from 'reactstrap'
import { userActions } from '../../../_actions'
import AlertCustom from '../../../components/_common/Alert';
import AddressForm from '../../../components/Forms/AddressForm/AddressForm'
import { profileFormSchema } from '../../../components/Forms/ValidationSchema'

const ChangeAddress = () => {
  const user = useSelector(state => state.authentication.user)
  const dispatch = useDispatch()
  const onSubmit = (values, formActions) => {
    const data = {
      address1: values.addressLine1,
      address2: values.addressLine2,
      city: values.city,
      county: values.county,
      postcode: values.postCode,
      building: values.building
    }
    dispatch(userActions.updateUser(data, user, formActions))
  }
  return (
    <Formik
      initialValues={{
        addressLine1: user.address1 || '',
        addressLine2: user.address2 || '',
        city: user.city || '',
        county: user.county || '',
        postCode: user.postcode || '',
        building: user.building || '',
      }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={profileFormSchema}
    >
      {({ submitForm, isSubmitting, values, handleReset, status = {}, ...props }) => {
        return (
          <Card className="profile-box">
            <CardTitle className="profile-box__title" tag={'h4'}>Address</CardTitle>
            <Form>
              {status.error && <AlertCustom alert={{type: 'danger', message: `${status.error}`}} />}
              <AddressForm values={values} {...props} />
              <Button
                color="primary"
                className="text-uppercase"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Loading...' : 'Update'}
              </Button>
              {status.success && <span className="text-success">Successfully updated</span>}
            </Form>
          </Card>
        )
      }}
    </Formik>
  )
}

export default ChangeAddress
