import { generalConstants } from '../_constants/config.constants'
import { authHeader, history } from '../_helpers'
import { handleFormErrors } from '../_helpers/errors'

export const userService = {
	register,
	login,
	getUser,
	logout,
	changePass,
	updateUser,
	registerProfUser,
	resetPassword
}

function register(user) {
	const requestOptions = {
		method: 'POST',
		mode: 'cors',
		headers: {'Content-Type': 'application/json'},
		body: JSON.stringify(user)
	}

	return (
		fetch(
			`${generalConstants.BASIC_URL}/auth/register`,
			requestOptions
		)
			.then(handleResponse)
			.then((user) => localStorage.setItem('user', JSON.stringify(user)))
			.then(() => {
				return getUser();
			})
	)
}

function getUser() {
	const requestOptions = {
		method: 'GET',
		mode: 'cors',
		headers: authHeader()
	}

	return (
		fetch(
			`${generalConstants.BASIC_URL}/auth/user`,
			requestOptions
		)
			.then(handleResponse)
	)
}

function login(user) {
	const requestOptions = {
		method: 'POST',
		mode: 'cors',
		headers: {'Content-Type': 'application/json'},
		body: JSON.stringify(user)
	}

	return (
		fetch(
			`${generalConstants.BASIC_URL}/auth/login`,
			requestOptions
		)
			.then(handleResponse)
			.then((user) => {
				localStorage.setItem('user', JSON.stringify(user))
			})
      .then(() => {
        return getUser();
      })
	)
}

function logout() {
	localStorage.removeItem('user');
	history.push('/');
}

function changePass(pass) {
  const requestOptions = {
    method: 'POST',
    mode: 'cors',
    headers: authHeader(),
    body: JSON.stringify(pass)
  }

  return (
    fetch(
      `${generalConstants.BASIC_URL}/auth/password/reset`,
      requestOptions
    )
      .then(handleResponse)
  )
}

function updateUser(user) {
  const requestOptions = {
    method: 'PATCH',
    mode: 'cors',
    headers: authHeader(),
    body: JSON.stringify(user)
  }

  return (
    fetch(
      `${generalConstants.BASIC_URL}/users/${user.id}`,
      requestOptions
    )
      .then(handleResponse)
  )
}

function registerProfUser(user) {
	const requestOptions = {
		method: 'POST',
		body: user
	}

	return (
		fetch(
			`${generalConstants.BASIC_URL}/auth/register_industrial`,
			requestOptions
		)
			.then(handleResponse)
	)
}

function resetPassword(email) {
	const requestOptions = {
		method: 'POST',
		headers: {'Content-Type': 'application/json'},
		body: JSON.stringify({email})
	}

	return (
		fetch(
			`${generalConstants.BASIC_URL}/auth/recovery`,
			requestOptions
		)
			.then(handleResponse)
	)
}

function handleResponse(response) {
	return response.text().then((text) => {
		let data = text && JSON.parse(text)
		if (!response.ok) {
			const error = handleFormErrors(data)
			return Promise.reject(error)
		}
		return data
	})
}
