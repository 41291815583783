import React from 'react'
import { Button, Row, Col, FormGroup } from 'reactstrap'
import FormikInput from '../../_common/FormikInput/FormikInput'
import FormSelect from '../../_common/FormSelect/FormSelect'
import { Field, Form } from 'formik'
import { useSelector } from 'react-redux'

const ContactForm = ({ submitForm, isSubmitting, errors, touched, ...props }) => {
  const validation = useSelector(state => state.formValidation)

  return (
    <Form>
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Field
              component={FormikInput}
              type="text"
              name="name"
              placeholder="Full name"
              id="fullName"
              className={errors.name && touched.name ? 'error-input' : null}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Field
              component={FormikInput}
              type="email"
              name="email"
              placeholder="Your email address"
              id="UserEmail"
              className={errors.email && touched.email ? 'error-input' : null}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Field
              component={FormikInput}
              type="tel"
              name="contact_number"
              placeholder="Your contact number"
              id="contactNumber"
              className={errors.contact_number && touched.contact_number ? 'error-input' : null}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Field
              component={FormikInput}
              type="url"
              name="location_link"
              placeholder="Link to your Kays Location page"
              id="linkToLocPage"
              className={errors.location_link && touched.location_link ? 'error-input' : null}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Field
              component={FormikInput}
              type="text"
              name="location_address"
              placeholder="Address of your location"
              id="linkToLocPage"
              className={errors.location_address && touched.location_address ? 'error-input' : null}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Field
              component={FormikInput}
              type="text"
              name="enquire"
              placeholder="Nature of enquiry"
              id="enquire"
              className={errors.enquire && touched.enquire ? 'error-input' : null}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <FormSelect
              name="preferred_time"
              value={props.values.preferred_time}
              onChange={props.setFieldValue}
              onBlur={props.setFieldTouched}
              error={errors.preferred_time}
              touched={touched.preferred_time}
              placeholder="Best time to be contacted"
              options={[
                { value: 'Morning', label: 'Morning' },
                { value: 'Afternoon', label: 'Afternoon' },
                { value: 'Evening', label: 'Evening' },
              ]}
              className={errors.preferred_time && touched.preferred_time ? 'error-input' : null}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="submit-row justify-content-end d-flex">
          <Button
            color="primary"
            className="text-uppercase"
            type="submit"
            onClick={submitForm}
          >
            Submit
          </Button>
          {validation.type === 'success' && (
            <span className={`text-${validation.type}`}>
              Thanks for submitting your details.
              We will match you with a Location Manager and notify you via email
            </span>
          )}
          {validation.type === 'danger' && (
            <span className={`text-${validation.type}`}>
              Something wrong, try again
            </span>
          )}
        </Col>
      </Row>
    </Form>
  )
}

export default ContactForm
