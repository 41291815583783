export const billingConstants = {
  BILLING_CARDS_LOADING: 'BILLING_CARDS_LOADING',
  BILLING_CARD_UPDATING: 'BILLING_CARD_UPDATING',
  BILLING_CARDS: 'BILLING_CARDS',
  BILLING_CARD_ACTIVATE: 'BILLING_CARD_ACTIVATE',

  BILLING_SUBSCRIPTIONS: 'BILLING_SUBSCRIPTIONS',
  BILLING_SUBSCRIPTIONS_LOADING: 'BILLING_SUBSCRIPTIONS_LOADING',

  GET_INVOICE_REQUEST: 'GET_INVOICE_REQUEST',
  GET_INVOICE_SUCCESS: 'GET_INVOICE_SUCCESS',
  GET_INVOICE_FAILURE: 'GET_INVOICE_FAILURE',
}
