import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Spinner } from 'reactstrap'
import { useParams } from 'react-router-dom'
import StepNavigation from './Navigation'
import Routes from './Routes'
import Confirmation from './Confirmation'
import { locationActions } from '../../_actions'
import './styles.scss'

const AddLocation = () => {
  const { id: locationId } = useParams()
  const {
    stepsNav,
    isProcessing,
  } = useSelector(store => ({
    isProcessing: store.locations.newLocation.isProcessing,
    stepsNav: store.locations.newLocation.stepsNav,
  }))
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(locationActions.resetNewLocationValues())
    dispatch(locationActions.locationIsEditFiles(false))
    if (locationId) dispatch(locationActions.pullPrevLocation(locationId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId])

  return (
    <Container>
      <section className="section-content add-location">
        {isProcessing && <Spinner/>}
        {!isProcessing && <>
          {stepsNav.currentStep === 4 ? <Confirmation/> : (
            <Row>
              <Col md={3} className="steps-holder">
                <StepNavigation stepsNav={stepsNav}/>
              </Col>
              <Col md={9} className="add-location-content">
                <Routes currentStep={stepsNav.currentStep}/>
              </Col>
            </Row>
          )}
        </>}
      </section>
    </Container>
  )
}

export default AddLocation
