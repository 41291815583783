import { userConstants } from '../_constants'
import { userService } from '../_services/user.service'
import { alertActions } from './alert.actions'
import { modalActions } from './modal.actions'

export const userActions = {
  checkUser,
  register,
  login,
  logout,
  changePassword,
  updateUser,
  resetPassword,
  changeResetInput
}

function checkUser() {
  return dispatch => {
    if (!!localStorage.getItem('user')) {
      userService
        .getUser()
        .then(
          user => {
            dispatch({ type: userConstants.LOGIN_SUCCESS, user })
          },
          error => {
            dispatch(logout())
          }
        )
    }
  }
}

function changePassword(pass, formActions) {
  return dispatch => {
    userService
      .changePass(pass)
      .then(
        user => {
          formActions.setSubmitting(false)
          formActions.setStatus({success: true})
          setTimeout(() => {
            formActions.resetForm()
          }, 2000)
        },
        error => {
          formActions.setSubmitting(false)
          formActions.setStatus({error})
        }
      )
  }
}

function register(user, goToPage, callback, resetForm) {
  return dispatch => {
    dispatch(request(user))
    userService
      .register(user)
      .then(
        user => {
          dispatch(success(user))
          dispatch(loginSuccess(user, goToPage))
          dispatch(alertActions.clear())
          dispatch(modalActions.closeAuth())
          resetForm();
          return { user: true }
        },
        error => {
          dispatch(failure())
          dispatch(alertActions.error(error.email ? error.email[0] : error.toString()))
          return error
        }
      )
      .then(item => (item.user ? callback(goToPage) : null))
  }

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user }
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user }
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error }
  }
  function loginSuccess(user) {
    return { type: userConstants.LOGIN_SUCCESS, user }
  }
}

function login(userData, goToPage, callback, resetForm) {
  return dispatch => {
    dispatch(request(userData.email))
    userService
      .login(userData)
      .then(
        user => {
          dispatch(success(user))
          dispatch(alertActions.clear())
          dispatch(modalActions.closeAuth())
          resetForm();
          return { user: true }
        },
        error => {
          dispatch(failure())
          dispatch(alertActions.error(error.toString()))
          return error
        }
      )
      .then(item => (item.user ? callback(goToPage) : null))
  }

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user }
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user }
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error }
  }
}

function logout() {
  return dispatch => {
    userService.logout()
    dispatch({ type: userConstants.LOGOUT })
  }
}

function updateUser(values, user, formActions) {
  return dispatch => {
    const updatedUser = {...user, ...values}
    userService
      .updateUser(updatedUser)
      .then(
        user => {
          dispatch({type: userConstants.UPDATE, updatedUser})
          formActions.setSubmitting(false)
          formActions.setStatus({success: true})
          setTimeout(() => {
            formActions.setStatus()
          }, 2000)
        },
        error => {
          formActions.setSubmitting(false)
          formActions.setStatus({error})
        }
      )
  }
}

function failureReset() {
  return { type: userConstants.RESET_ERROR }
}

function successReset() {
  return { type: userConstants.RESET_SUCCESS }
}

function changeResetInput() {
  return { type: userConstants.RESET_CHANGE }
}

function resetPassword(email) {
  return dispatch => {
    userService
      .resetPassword(email)
      .then(
        () => {
          dispatch(successReset())
        },
        error => {
          dispatch(failureReset())
        })
    }
  }

