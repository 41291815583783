import React from 'react'
import PropTypes from 'prop-types'
import NewAccount from './Svg/NewAccount'
import SignIn from './Svg/Sign-in'
import HomeIcon from './Svg/HomeIcon'
import CityIcon from './Svg/CityIcon'
import CommentsIcon from './Svg/CommentsIcon'
import SearchLocationIcon from './Svg/SearchLocationIcon'

const Icon = props => {
  switch (props.name) {
    case 'newAccount':
      return <NewAccount {...props} />
    case 'signIn':
      return <SignIn {...props} />
    case 'home':
      return <HomeIcon {...props} />
    case 'city':
      return <CityIcon {...props} />
    case 'comments':
      return <CommentsIcon {...props} />
    case 'searchLocation':
      return <SearchLocationIcon {...props} />
    default:
      return
  }
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Icon
