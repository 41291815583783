import { billingActions } from '../_actions'

export const invoicesParams = (dispatch) => {
  const searchParams = new URLSearchParams(window.location.search)
  dispatch(billingActions.fetchInvoices({
    per_page: searchParams.get('per_page') || 12
  }))
}

export const getDate = (time) => {
  const d = new Date(time  * 1000)
  return `${d.getUTCDay()}/${d.getUTCMonth()}/${d.getFullYear()}`
}