import React from 'react'
import FormSelect from '../../../_common/FormSelect/FormSelect'


const BuillingPlan = ({ currentLocationType = { name: '' }, fieldData, totalPrice, ...props }) => {
  return (
    <div className="location-item location-item--flex location-item-billing">
      <div className="icon-location-holder">
        {currentLocationType.id === 1 &&
          <i className="icon-industry-alt"></i>
        }
        {currentLocationType.id === 2 &&
          <i className="icon-home"></i>
        }
      </div>
      <div className="location-item-descr">
        <h5 className="location-detail-title">{currentLocationType.name}</h5>
        <FormSelect
          {...props}
          name="paymentPeriod"
        />
        <strong>
          Total: &#163;{totalPrice} {props.value && <span className="billed">({props.value === 'month' ? 'billed monthly' : 'billed annually'})</span>}
        </strong>
      </div>
    </div>
  )
}

export default BuillingPlan
