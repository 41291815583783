import { generalConstants } from '../_constants/config.constants'
import { handleFormErrors } from '../_helpers/errors'

export const locationCategoriesService = {
	loadLocationAlphabetCategories,
	loadLocationCategories,
  loadLocationKeywords,
}

function loadLocationAlphabetCategories() {
	const requestOptions = {
		method: 'GET',
		mode: 'cors',
	}

	return (
		fetch(
			`${generalConstants.BASIC_URL}/category/alphabet_list`,
			requestOptions
		)
			.then(handleResponse)
	)
}

function loadLocationCategories() {
	const requestOptions = {
		method: 'GET',
		mode: 'cors',
	}

	return (
		fetch(
			`${generalConstants.BASIC_URL}/category/children_list`,
			requestOptions
		)
			.then(handleResponse)
	)
}

function loadLocationKeywords() {
  const requestOptions = {
    method: 'GET',
    mode: 'cors',
  }

  return (
    fetch(
      `${generalConstants.BASIC_URL}/keywords`,
      requestOptions
    )
      .then(handleResponse)
  )
}

function handleResponse(response) {
	return response.text().then((text) => {
		let data = text && JSON.parse(text)
		if (!response.ok) {
			const error = handleFormErrors(data)
			return Promise.reject(error)
		}
		return data
	})
}
