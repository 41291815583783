import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Container, Col, Row } from 'reactstrap'

import { convertUncerscoreObject } from '../../_helpers'
import { locationActions } from '../../_actions'
import Preloader from '../../components/_common/Preloader'
import LocationDetailsForm from '../../components/Forms/AddLocationForm/LocationDetailsForm'
import LocationDetailPreview from '../../components/Forms/AddLocationForm/LocationDetailsForm/LocationDetailPreview'

function isEmpty(obj){
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false
    }
  }

  return JSON.stringify(obj) === JSON.stringify({})
}

const EditLocation = (props) => {
  const [compliteMessage, setCompliteMessage] = useState('')
  const { id: locationId } = useParams()

  const location = useSelector((state) => state.locations.singleLocation)
  const locationsState = useSelector((state) => state.locations)
  const isLogged = useSelector((state) => state.authentication)
  const dispatch = useDispatch()
  const loadLocation = () => {
    dispatch(locationActions.getById(locationId, true))
  }

  useEffect(() => {
    loadLocation()
    dispatch(locationActions.locationIsEditFiles(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (!isLogged) {
    props.history.push('/')
  } else if (!location) {
    props.history.push('/location_list')
    return null
  }

  const sortedKeywords = {}
  if (isEmpty(location)) return null
  location.keywords && location.keywords.forEach((item) => {
    if (Array.isArray(sortedKeywords[item.type])) {
      sortedKeywords[item.type].push(item.id)
    } else {
      sortedKeywords[item.type] = [ item.id ]
    }
  })

  const addedKeywordsByBroup = { ...location, ...sortedKeywords }
  const response = convertUncerscoreObject(addedKeywordsByBroup)

  const filteredValues = {}

  // eslint-disable-next-line no-unused-vars
  for (let key in response) {
    if ((response[key] && !Array.isArray(response[key])) || (Array.isArray(response[key]) && response[key].length)) {
      switch (key) {
        case 'bedrooms':
          filteredValues['bedroomsNo'] = response[key].id
          break

        case 'locationTypeId':
          filteredValues['locationType'] = response[key].id
          break

        case 'powerSupply':
        case 'waterSource':
        case 'locationSetting':
        case 'parking':
        case 'filmingAvailability':
        case 'wifi':
          filteredValues[key] = response[key].id
          break

        case 'postcode':
          filteredValues['postCode'] = response[key]
          break

        case 'additionalInfo':
          filteredValues[key] = response[key].map((item) => item.id)
          break

        case 'videoType':
          const selected = ['youtube', 'vimeo', 'matterport'].indexOf(response[key]) + 1
          filteredValues[key] = selected
          break

        default:
          filteredValues[key] = response[key]
          break
      }
    }
  }

  const EditingComplite = () => {
    setCompliteMessage('Updating completed')
    setTimeout(() => {
      setCompliteMessage('')
    }, 2000)
  }

  return (
    <Container>
      <Preloader status={locationsState.loading} />
      {locationsState.isError && <p className="text-warning">We got some error</p>}
      {!locationsState.loading &&
      !locationsState.isError && (
        <div>
          <section className="section-content add-location">
            <Row>
              <Col md={3} className="position-relative" >
                <div className="position-sticky">
                  <LocationDetailPreview />
                </div>
              </Col>
                <Col md={9} className="add-location-content">
                <LocationDetailsForm editMode={true} defaultValues={filteredValues} onSuccess={EditingComplite} />
                {compliteMessage.length > 0 ? <p style={{ color: '#0fbf06' }}>{compliteMessage}</p> : null }
              </Col>
            </Row>
          </section>
        </div>
      )}
    </Container>
  )
}

export default EditLocation
