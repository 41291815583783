import React from 'react';

import BuillingDetailsForm from '../../../components/Forms/AddLocationForm/BuillingDetailsForm'

const BuillingDetails = ({ history }) => {
  return (
    <div className="billing-step">
      <h4>Billing details</h4>
      <BuillingDetailsForm />
    </div>
  );
};

export default BuillingDetails;
