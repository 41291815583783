import React, {useState, useEffect} from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import { initMap, history } from '../../_helpers'
import './styles.scss'

const LocationPlaces = (props) => {
  const {search, pathname} = window.location
  const params = new URLSearchParams(search)
  const [isGoogleMapsLoaded, trigger] = useState(false)
  const [isError, setError] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [didMount, setDidMount] = useState(false)
  const [value, setValue] = useState(props.searchLocation || '')
  const {searchLocation, setLocation} = props

  const handleChange = (location) => {
    setDidMount(true)
    setValue(location)
    props.locationSelect(location)
    return () => setDidMount(false)
  }

  const errorHandler = (error) => {
    setError(error)
  }

  const setLocationValue = (location) => {
    setValue(location)
    // if (location.length === 0) {
      if(!location){
      params.delete('search-location')
      params.delete('longitude')
      params.delete('latitude')
      params.delete('search-radius')
      history.replace(`${pathname}?${params.toString()}`)
    }
  }

  useEffect(() => {
    let isSubscribed = true
    initMap().then(() => {
      if(isSubscribed) trigger(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => isSubscribed = false
  }, [])

  useEffect(() => {
    setValue(searchLocation || '')
  }, [searchLocation])


  if (!isGoogleMapsLoaded) return null

  const searchOptions = {
    componentRestrictions: {country: "uk"}
  }

  const clear = () => {
    setValue('')
    props.locationSelect('')
    setLocation(null)
    params.delete('search-location')
    params.delete('longitude')
    params.delete('latitude')
    params.delete('search-radius')
    history.replace(`${pathname}?${params.toString()}`)
  }

  return(
    <PlacesAutocomplete
      value={value}
      onChange={setLocationValue}
      onError={errorHandler}
      onSelect={handleChange}
      searchOptions={searchOptions}
    >
      {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
        <div className="autocomplete-holder">
          <input {...getInputProps({
            placeholder: props.placeholder ||  'Search Places ...',
            className: 'location-search-input'
          })}
          />
          <i className="icon-map-marker-alt1"></i>
          {value && <i className="icon-times" style={{right: '10px', left: 'auto'}} onClick={clear}></i>}
          <div className="autocomplete-dropdown-container">
            {loading && <div className="autocomplete-loading">Loading...</div>}
            {isError && !suggestions.length && <div className="autocomplete-loading">No suggestions </div>}
            {suggestions.map((suggestion, index) => {
              const className = `suggestion-item${suggestion.active ? '--active' : ''}`;
              return (
                <div key={index} {...getSuggestionItemProps(suggestion, {className})}>
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export default LocationPlaces