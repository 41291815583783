import React from 'react'

import LocationTypeContainer from '../../../components/Forms/AddLocationForm/LocationTypeForm'

const LocationType = ({ history }) => {

  return (
    <>
      <h4 className="location-title">Select your location type and category</h4>
      <LocationTypeContainer />
    </>
  )
}

export default LocationType
