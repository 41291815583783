import React from 'react'
import { withRouter } from 'react-router'
import Header from './Header'
import { modalActions, userActions } from '../../_actions'
import { connect } from 'react-redux'

const HeaderContainer = props => {
  return <Header {...props} />
}

const mapStateToProps = state => {
  const {
    authentication: { loggedIn },
    modal: { modalIsOpen, pathTo },
  } = state
  return { loggedIn, modalIsOpen, pathTo }
}

const mapDispatchToProps = {
  modalOpen: modalActions.openAuth,
  modalClose: modalActions.closeAuth,
  logout: userActions.logout
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HeaderContainer))
