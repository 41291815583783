import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import HeroBgImage from '../../components/_common/HeroBgImage/HeroBgImage'
import SectionTitle from '../../components/_common/SectionTitle/SectionTitle'
import { IconBlock } from '../../components/_common/Typography'
import HeroImage from '../../assets/images/contact-hero-bg.jpg'
import Card from '../../components/_common/Card/Card'
import { ContentBox } from '../../components/Layout'
import ContactForm from '../../components/Forms/ContactForm'
import './styles.scss'

const ContactPage = () => {
  return (
    <>
      <HeroBgImage backgroundImg={HeroImage} type={'simple'} />
      <section className="section-content contact-section">
        <Container>
          <SectionTitle title={`Contact Kays Locations`} />
          <Row>
            <Col md={8}>
              <div className="content-section">
                <ContentBox>
                  <IconBlock
                    icon={'envelope'}
                    textContainer={{
                      text: [
                        `If you have any queries or concerns, or would like 
                         advice and guidance about any aspect of Kays Locations, 
                         this website or location filming, our enthusiastic and 
                         experienced team are happy to help. Please fill out the 
                         box below and the person best placed to assist you will 
                         respond as quickly as possible.`
                      ],
                    }}
                    smHidden
                  />
                </ContentBox>
                <Card
                  cardProps
                  type={'link'}
                  className={'mob-view'}
                  data={{
                    icon: 'info-circle',
                    title: 'Have you read our FAQs?',
                    excerption:
                      'We have plenty of information on our Frequently Asked Questions page...',
                    url: '/faq',
                    buttonText: 'GO TO FAQs',
                  }}
                />
                <ContentBox>
                  <ContactForm />
                </ContentBox>
              </div>
              
            </Col>
            <Col md={4}>
              <div className="sidebar">
                <Card
                  cardProps
                  type={'link'}
                  className={'mob-hidden'}
                  data={{
                    icon: 'info-circle',
                    title: 'Have you read our FAQs?',
                    excerption:
                      'We have plenty of information on our Frequently Asked Questions page...',
                    url: '/faq',
                    buttonText: 'GO TO FAQs',
                  }}
                />
                <div className="bg-white content-box contact-infos">
                  <Row>
                    <Col md={12} xs={6}>
                    <h6 className="card-title">Where we are</h6>
                    <address>
                      Kays Locations
                      <br/>
                      Pinewood Studios
                      <br/>
                      Pinewood Road
                      <br/>
                      Iver Heath
                      <br/>
                      Bucks SL0 0NH
                    </address>
                    </Col>
                    <Col md={12} xs={6}>
                      <h6 className="card-title">Get in touch</h6>
                      <div className="txt">T: 01753-307537</div>
                      <div className="txt"><a href="mailto:info@kayslocations.co.uk">E: info@kayslocations.co.uk</a></div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default ContactPage
