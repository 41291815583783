import { generalConstants } from '../_constants/config.constants'
import { buildSearchParams, authHeader } from '../_helpers'


function loadLocations(params) {
  const requestOptions = {
    method: 'GET',
    mode: 'cors',
    headers: authHeader()
    // headers: { 'Content-Type': 'application/json' }
  }

  return (
    fetch(
      `${generalConstants.BASIC_URL}/locations/active/search?${buildSearchParams(params)}`,
      requestOptions
    )
      .then(handleResponse)
  )
}

function loadFeaturedLocations(params) {
  const requestOptions = {
    method: 'GET',
    mode: 'cors',
    headers: authHeader()
    // headers: { 'Content-Type': 'application/json' }
  }

  return (
    fetch(
      `${generalConstants.BASIC_URL}/locations/featured?${buildSearchParams(params)}`,
      requestOptions
    )
      .then(handleResponse)
  )
}

function loadSingleLocation(id, isAny = false) {
  const requestOptions = {
    method: 'GET',
    mode: 'cors',
    headers: authHeader()
  }

  return (
    fetch(
      `${generalConstants.BASIC_URL}/locations${isAny ? '' : '/active'}/${id}`,
      requestOptions
    )
      .then(handleResponse)
  )
}

function loadFavoriteLocations(params) {
  const requestOptions = {
    method: 'GET',
    mode: 'cors',
    headers: authHeader()
  }

  return (
    fetch(
      `${generalConstants.BASIC_URL}/locations/favourites?${buildSearchParams(params)}`,
      requestOptions
    )
      .then(handleResponse)
  )
}

function addToFavorite(id) {
  const requestOptions = {
    method: 'POST',
    mode: 'cors',
    headers: authHeader()
  }

  return (
    fetch(
      `${generalConstants.BASIC_URL}/locations/favourites/${id}`,
      requestOptions
    )
      .then(handleResponse)
  )
}

function removeFromFavorite(id) {
  const requestOptions = {
    method: 'DELETE',
    mode: 'cors',
    headers: authHeader()
  }

  return (
    fetch(
      `${generalConstants.BASIC_URL}/locations/favourites/${id}`,
      requestOptions
    )
      .then(handleResponse)
  )
}

function loadMyLocations(params) {
  const requestOptions = {
    method: 'GET',
    mode: 'cors',
    headers: authHeader()
  }

  return (
    fetch(
      `${generalConstants.BASIC_URL}/locations/my?${buildSearchParams(params)}`,
      requestOptions
    )
      .then(handleResponse)
  )
}

function removeMyLocation(id) {
  const requestOptions = {
    method: 'DELETE',
    mode: 'cors',
    headers: authHeader()
  }

  return (
    fetch(
      `${generalConstants.BASIC_URL}/locations/${id}`,
      requestOptions
    )
      .then(handleResponse)
  )
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }
    return data
  })
}

export const locationsService = {
  loadLocations,
  loadFeaturedLocations,
  loadSingleLocation,
  loadFavoriteLocations,
  addToFavorite,
  removeFromFavorite,
  loadMyLocations,
  removeMyLocation
}
