import AboutImg_1 from '../assets/images/about-img.jpg'
import AboutImg_2 from '../assets/images/featured-listing.jpg'
import AboutImg_3 from '../assets/images/adver-uk.jpg'
import AboutImg_4 from '../assets/images/featured-website.png'
import AboutImg_5 from '../assets/images/access-platform.jpg'

export const pageData = [
  {
    articleTitle: '37 years in the industry',
    description: [
      `<p>
        Kays have been an independent company specialising in film and TV 
        information publishing.
      </p>
      <p>
        It’s what we do and we know the industry inside out. In fact, 
        Kays is still run by the same management team that founded 
        the company on 1 April 1981. First with directories and now across web, 
        app and direct marketing, we cover all aspects of media publishing and 
        have become the industry standard.
      </p>
      <p>
        Based in Pinewood Studios and MediaCityUK, Manchester, we are uniquely 
        placed to research and monitor the UK as well as the world’s film and 
        television industry. Alongside which our continual investment in 
        technology across all four platforms enables us to bring you instant 
        access to the latest, up-to-date contact information on the industry’s 
        leading companies, freelance technicians and through our Kays Locations 
        platform, we aim to showcase the best the UK has to offer.
      </p>`
    ],
    image: {
      imageSrc: AboutImg_1,
      imageAlt: 'image description',
      width: '435',
      height: '255',
    },
    imagePosition: 'right',
  },
  {
    articleTitle: 'Featured listing on Kays Locations',
    description: [
      `As part of a Kays advertising package, you will have access to the wider 
      Kays platform which includes advertisements and increased prominence on 
      the Kays websites, apps and publications. If you become a Kays advertiser, 
      your location profile page will become highlighted as ‘featured’ in the 
      search results and show at the very top before all standard listings. 
      We also aim to produce a blog ‘write up’ about all of our featured 
      locations. These will show on a carousel on the front page.`
    ],
    image: {
      imageSrc: AboutImg_2,
      imageAlt: 'Featured listing on Kays Locations',
      width: '435',
      height: '255',
    },
    imagePosition: 'left',
  },
  {
    articleTitle: 'Advert in the Kays UK Production Manual',
    description: [
      `<p>
        Kays UK Production Manual is the essential and trusted platform to 
        promote your services and facilities within the UK and Ireland.
       </p>
       <p>
        Whether your company works on feature films, TV dramas or commercials, 
        you can be sure that your advertising will be seen by the people who 
        matter. Distributed by a controlled free circulation, it is used by 
        key personnel working in the production industry, including Production 
        Companies, Line Producers, Production Managers, Location Managers, 
        Directors of Photography and more.
       </p>
       <p>
        Circulation: 5,000 copies
       </p>`
    ],
    image: {
      imageSrc: AboutImg_3,
      imageAlt: 'Advert in the Kays UK Production Manual',
      width: '435',
      height: '255',
    },
    imagePosition: 'right',
  },
  {
    articleTitle: 'Featured on Kays website and Apps',
    description: [
      `<p>
        As an advertiser, you will automatically appear at the top of any 
        searches on all Kays websites and apps. From the moment you become a 
        client of Kays, your profile pages are immediately uploaded to our website 
        and app. You will then be encouraged by our team to keep your profiles 
        up-to-date – we’re always on hand to help.
       </p>
       <p>
         Your company will also have a banner advertisement displayed on the 
         main Kays website. Kays app is by invitation only to ensure its 
         distribution to all high-end industry professionals, including freelance 
         technicians and facility/service companies.
        </p>`
    ],
    image: {
      imageSrc: AboutImg_4,
      imageAlt: 'Featured on Kays website and Apps',
      width: '435',
      height: '255',
    },
    imagePosition: 'left',
  },
  {
    articleTitle: 'Access to the Kays marketing platform',
    description: [
      `<p>
        Kays main website features a powerful search tool, messaging system 
        and enquiry service, it enables you – as an advertiser – to gain access 
        to our extensive database. Search by category, location, credit or name 
        and, at the touch of a button, you will have instant contact information.
       </p>
       <p>
        As an advertiser of KAYS, your company will have access to our database 
        for bespoke direct marketing purposes – both postal mailouts and global 
        email campaigns are available. There is no better way of promoting your 
        services to your potential client base.
       </p>`
    ],
    image: {
      imageSrc: AboutImg_5,
      imageAlt: 'Access to the Kays marketing platform',
      width: '435',
      height: '255',
    },
    imagePosition: 'right',
  },
]