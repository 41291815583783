import { validationConstants } from '../_constants'

export function formValidation(state = {}, action) {
  switch (action.type) {
    case validationConstants.SUCCESS:
      return { type: 'success', message: action.message }
    case validationConstants.ERROR:
      return { type: 'danger', message: action.message }
    case validationConstants.CLEAR:
      return {}
    default:
      return state
  }
}
