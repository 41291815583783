import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

export const IconBlock = ({
  icon,
  textContainer: { title, text },
  smHidden = false,
}) => {
  return (
    <div className="icon-content-holder">
      <div
        className={`icon-content-holder__icon ${smHidden &&
          'd-none d-lg-flex'}`}
      >
        <i className={`icon icon-${icon}`}/>
      </div>
      <div className="icon-content-holder__text">
        {title && <h5>{title}</h5>}
        {text && text.map((item, index) => <p key={index} dangerouslySetInnerHTML={{__html: item}}/>)}
      </div>
    </div>
  )
}

IconBlock.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.array,
}
