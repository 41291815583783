import React from 'react'
import { Button, Spinner } from 'reactstrap'

const RequestButton = ({ isLoading, children, color = "primary", ...props}) => {
  if (isLoading) {
    return (
      <Spinner color="gray" />
    )
  }
  return (
    <Button
      color={color}
      children={children}
      {...props} 
    />
  )
}

export default RequestButton
