import { generalConstants } from '../_constants/config.constants'
import { authHeader, buildSearchParams } from '../_helpers'
import { handleFormErrors } from '../_helpers/errors'

export const billingService = {
	fetchCards,
	addNewCard,
	removeCard,
	defaultCard,
	fetchSubscriptions,
	removeSubscription,
	fetchInvoices
}

function fetchCards() {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	}

	return (
		fetch(
			`${generalConstants.BASIC_URL}/billing/cards`,
			requestOptions
		)
			.then(handleResponse)
	)
}

function addNewCard(token, defaultPayment = false) {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify({
			token_id: token,
			default: defaultPayment
		})
	}

	return (
		fetch(
			`${generalConstants.BASIC_URL}/billing/add_card`,
			requestOptions
		)
			.then(handleResponse)
	)
}

function removeCard(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader()
	}

	return (
		fetch(
			`${generalConstants.BASIC_URL}/billing/card/${id}`,
			requestOptions
		)
			.then(handleResponse)
	)
}

function defaultCard(id) {
	const requestOptions = {
		method: 'PATCH',
		headers: authHeader()
	}

	return (
		fetch(
			`${generalConstants.BASIC_URL}/billing/default_card/${id}`,
			requestOptions
		)
			.then(handleResponse)
	)
}

function fetchSubscriptions() {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	}

	return (
		fetch(
			`${generalConstants.BASIC_URL}/billing/subscriptions`,
			requestOptions
		)
			.then(handleResponse)
	)
}

function removeSubscription(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader()
	}

	return (
		fetch(
			`${generalConstants.BASIC_URL}/billing/remove_subscription/${id}`,
			requestOptions
		)
			.then(handleResponse)
	)
}

function fetchInvoices(params) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	}

	return (
		fetch(
			`${generalConstants.BASIC_URL}/billing/invoices?${buildSearchParams(params)}`,
			requestOptions
		)
			.then(handleResponse)
	)
}

function handleResponse(response) {
	return response.json().then((data) => {
		if (!response.ok) {
			const error = handleFormErrors(data)
			return Promise.reject(error)
		}
		return data
	})
}
