import React, {useState} from 'react';
import { Link } from 'react-router-dom'
import { Row, Col, FormGroup, Button } from 'reactstrap';
import { FilePond } from 'react-filepond';
import { Formik, Form, Field } from 'formik'
import { ContentBox } from '../../Layout';
import { industryFormSchema } from '../ValidationSchema'
import FormikInput from '../../_common/FormikInput/FormikInput'
import AlertCustom from '../../_common/Alert'
import { userService } from '../../../_services/user.service'
import FormSelect from '../../_common/FormSelect/FormSelect'
import AddressForm from '../AddressForm/AddressForm'
import './styles.scss';

const jobOptions = [
  {label: 'Supervising Location Manager', value: 'Supervising Location Manager'},
  {label: 'Location Manager', value: 'Location Manager'},
  {label: 'Location Scout', value: 'Location Scout'},
  {label: 'Assistant Location Manager', value: 'Assistant Location Manager'},
  {label: 'Unit Manager', value: 'Unit Manager'},
  {label: 'Art Director', value: 'Art Director'},
  {label: 'Producer', value: 'Producer'},
  {label: 'Director', value: 'Director'},
  {label: 'Designer', value: 'Designer'},
  {label: 'Line Producer', value: 'Line Producer'},
  {label: 'Production Manager', value: 'Production Manager'},
  {label: 'Director of Photography', value: 'Director of Photography'},
  {label: 'Stills Photographer', value: 'Stills Photographer'},
  {label: 'Other', value: 'Other'}
]

const IndustryForm = () => {
  const [pdfFile, setPdfFile] = useState(null)

  const validateFileType = (source, type) => new Promise((resolve, reject) => {
    if (source.name.endsWith('.doc')) {
      resolve('.doc');
    }
    if (source.name.endsWith('.pdf')) {
      resolve('.pdf');
    }
    reject();
  });


  const onSubmit = (values, formActions) => {
    const formData = new FormData();
    formData.append('first_name', values.first_name);
    formData.append('last_name', values.last_name);
    formData.append('password', values.password);
    formData.append('password_confirmation', values.password_confirmation);
    formData.append('email', values.email);
    formData.append('phone', values.phone);
    formData.append('postcode', values.postcode);
    formData.append('address1', values.address1);
    formData.append('address2', values.address2);
    formData.append('city', values.city);
    formData.append('county', values.county);
    formData.append('job', values.job);
    formData.append('job_specify', values.job_specify);
    formData.append('portfolio', values.portfolio);
    formData.append('file_pdf', pdfFile);

    userService.registerProfUser(formData)
      .then(
        user => {
          formActions.setSubmitting(false)
          formActions.setStatus({success: true})
        },
        error => {
          formActions.setSubmitting(false)
          formActions.setStatus({error})
        }
      )
  }

  return (
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        password: '',
        password_confirmation: '',
        email: '',
        phone: '',
        postCode: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        county: '',
        job: '',
        job_specify: '',
        portfolio: '',
        agreement: true
      }}
      enableReinitialize
      validationSchema={industryFormSchema}
      onSubmit={onSubmit}
    >
      {({ values, isSubmitting, status = {}, setFieldValue, setFieldTouched, touched, errors, setValues }) => (
        <Form className="industry-form">
          {status.error && <AlertCustom alert={{type: 'danger', message: `${status.error}`}} />}
          <ContentBox>
            <h4 className="industry-form__title">Your details</h4>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Field
                    component={FormikInput}
                    type="text"
                    name="first_name"
                    placeholder="First name"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Field
                    component={FormikInput}
                    type="text"
                    name="last_name"
                    placeholder="Surname"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Field
                    component={FormikInput}
                    type="email"
                    name="email"
                    placeholder="Email address"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Field
                    component={FormikInput}
                    type="text"
                    name="phone"
                    placeholder="Telephone number"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <FormSelect
                    name="job"
                    value={values.job}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    placeholder="Select your job"
                    touched={touched.job}
                    options={jobOptions}
                    error={errors.job}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                {values.job === 'Other' && <FormGroup>
                  <Field
                    component={FormikInput}
                    type="text"
                    name="job_specify"
                    placeholder="Other, please specify"
                  />
                </FormGroup>}
              </Col>
            </Row>
            <AddressForm
              setValues={setValues}
              setFieldValue={setFieldValue}
              values={values}
              errors={errors}
              touched={touched}
            />
          </ContentBox>
          <ContentBox>
            <h4 className="industry-form__title">Account password</h4>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Field
                    component={FormikInput}
                    type="password"
                    name="password"
                    placeholder="Create a password"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Field
                    component={FormikInput}
                    type="password"
                    name="password_confirmation"
                    placeholder="Confirm password"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ContentBox>
          <ContentBox>
            <h4 className="industry-form__title">Your portfolio & experience</h4>
            <Field
              component={FormikInput}
              type="textarea"
              name="portfolio"
              placeholder="Please give a description of your experience in the industry."
            />
            <div className="upload-caption"><strong>Alternatively you may upload a copy of your CV</strong> (File types .doc, or .pdf) Max 3mb</div>
            <FilePond
              className="industry-form__file"
              allowDrop={false}
              maxFiles={1}
              labelIdle="Choose file"
              maxFileSize="3MB"
              acceptedFileTypes={['.pdf', '.doc']}
              fileValidateTypeDetectType={validateFileType}
              onupdatefiles={fileItems => {
                setPdfFile(fileItems[0].file)
              }}
            />
          </ContentBox>
          <ContentBox className="industry-form__agreement bg-white content-box">
            <h4 className="industry-form__title">Declaration</h4>
            <div className="text-holder">
              <label className="industry-form__checkbox">
                <Field
                  component={FormikInput}
                  name="agreement"
                  type="checkbox"
                  checked={values.agreement}
                />
                <i className="icon-check"/>
              </label>
              <div className="agree-text">By signing up to Industry Professional account you agree to I have read the <Link to="/terms" target="_blank">terms and conditions</Link> and that the details you have submitted will be used to create your user account in accordance with our <Link to="/privacy" target="_blank">privacy policy</Link></div>
            </div>
            <div className="submit-row">
              <Button
                disabled={isSubmitting || status.success}
                type="submit"
                color="primary"
                className={'text-uppercase'}
              >
                {isSubmitting ? 'Loading...' : 'Submit'}
              </Button>
              {status.success && <span className="text-success">Thanks for your application! Your account will become active within 24-48 hours, you will be notified by email.</span>}
              {status.error && <span className="text-warning">The email has already been taken.</span>}
            </div>
          </ContentBox>
        </Form>
      )}
    </Formik>
  );
};

export default IndustryForm;
