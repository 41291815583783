import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import SectionTitle from '../../components/_common/SectionTitle/SectionTitle'
import HeroBgImage from '../../components/_common/HeroBgImage/HeroBgImage'
import HeroImage from '../../assets/images/industry-hero-img.jpg'
import Card from '../../components/_common/Card/Card'
import { IconBlock } from '../../components/_common/Typography'
import { ContentBox } from '../../components/Layout'
import IndustryForm from '../../components/Forms/IndustryForm/IndustryForm'
import './styles.scss'

const SignUpIndustry = () => {
  return (
    <>
      <HeroBgImage backgroundImg={HeroImage} type={'simple'} />
      <section className="section-content industry-area">
        <SectionTitle title={'Sign-up for an Industry Professional account'} />
        <Container>
          <Row>
            <Col md={8}>
              <ContentBox>
                <IconBlock
                  icon="user-plus"
                  textContainer={{
                    text: [
                      `If you are a Location Manager, Location Scout or other 
                       relevant industry professional, you can complete the 
                       form below and join our user group. To protect location 
                       owner’s security, we only allow approved professionals 
                       to make direct contact with a location owner. Industry 
                       professional accounts also have access to many other 
                       features within the site. Please complete form and 
                       include plenty of information or links to your portfolio 
                       and experience.`
                    ],
                  }}
                  smHidden
                />
              </ContentBox>
              <IndustryForm />
            </Col>
            <Col md={4}>
              <Card
                className="help-card"
                type={'link'}
                data={{
                  icon: 'info-circle',
                  title: 'Need some help signing up?',
                  excerption:
                    'Our team at Kays Locations are on hand to answer any queries about Industry Professional accounts',
                  url: '/help-support',
                  buttonText: 'CONTACT',
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default SignUpIndustry
