import { billingConstants } from '../_constants'

const initialState = {
  cards: [],
  cardsLoading: false,
  cardUpdating: false,
  invoicesLoading: false,
  activeCard: {},
  subscriptions: [],
  invoices: [],
  subscriptionsLoading: false
}

export function billing(state = initialState, action) {
  switch (action.type) {
    case billingConstants.BILLING_CARDS_LOADING:
      return {
        ...state,
        cardsLoading: action.payload
      }
    case billingConstants.BILLING_CARD_UPDATING:
      return {
        ...state,
        cardUpdating: action.payload
      }
    case billingConstants.BILLING_CARDS:
      return {
        ...state,
        cards: action.payload
      }
    case billingConstants.BILLING_CARD_ACTIVATE:
      return {
        ...state,
        activeCard: action.payload
      }
    case billingConstants.BILLING_SUBSCRIPTIONS_LOADING:
      return {
        ...state,
        subscriptionsLoading: action.payload
      }
    case billingConstants.BILLING_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload
      }
    case billingConstants.GET_INVOICE_REQUEST:
      return {
        ...state,
        invoicesLoading: true
      }
    case billingConstants.GET_INVOICE_SUCCESS:
      return {
        ...state,
        invoicesLoading: false,
        invoices: action.payload
      }

    default:
      return state
  }
}
