import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardTitle,
  CardBody,
  CardText,
  CardImg,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { formatTime } from '../../../_helpers/date'
import AddToFavorite from '../../_common/AddToFavorites'
import './styles.scss'
import { locationActions } from '../../../_actions'
import { useDispatch } from 'react-redux'

const CardFavorite = props => {
  const dispatch = useDispatch()

  const {
    id,
    name, 
    city,
    county,
    created_at,
    image_featured_urls
  } = props.data

  const _handleClick = () => {
    dispatch(locationActions.removeFromFavorite(id,  true))
  }


  return (
    <Card className={'card-favorite'}>
      <Link to={{pathname: `/location_page/${id}`, state: {fromLocations: 'favorites'}}} className={'image-holder'}>
        {image_featured_urls.length ? <CardImg top width="100%" src={image_featured_urls.map(item => item)} alt="Card image cap" /> : <div className="no-photo"/>}
      </Link>
      <CardBody>
        <CardTitle tag={'h4'}>
          <Link to={{pathname: `/location_page/${id}`, state: {fromLocations: 'favorites'}}}>{name}</Link>
          <AddToFavorite fromFavorite={true} isFavourite={true} id={id} action={_handleClick}/>
        </CardTitle>
        <CardText>
          <small className={'text-muted'}>
            {`${[city, county].filter(e => e).join(', ')}`}
          </small>
        </CardText>
        <CardText>Added on: {formatTime(created_at)}</CardText>
      </CardBody>
    </Card>
  )
}

CardFavorite.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string,
  main_photo: PropTypes.string,
  created_at: PropTypes.string,
  url: PropTypes.string,
}

export default CardFavorite
