import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button } from 'reactstrap'
import CustomMedia from '../../../components/_common/CustomMedia/CustomMedia'
import './styles.scss'
import {useSelector} from 'react-redux';

const mediaArr = [
  {
    iconName: 'home',
    description:
      'We have a wide range of residential locations of varying ages, styles, shapes and sizes for you to choose from.',
  },
  {
    iconName: 'city',
    description:
      'We list a variety of Industrial, Educational, Institutional, Urban, Rural, Sporting, Events and Public venues to suit your needs.',
  },
  {
    iconName: 'comments',
    description:
      'If you have any queries or concerns, or would like advice and guidance, our enthusiastic and experienced team are happy to help.',
  },
  {
    iconName: 'searchLocation',
    description:
      'By using the keywords and filter options during your search you will be able to find the perfect location to fit your requirements.',
  },
]

const SectionContent = () => {
  const isIndustrial = useSelector(state => state.authentication.is_industrial)

  return (
    <section className="section-content list-location">
      <Container>
        <h2 className="text-md-center section-content__title">Do you have a property that would make a great location?</h2>
        <Row>
          <Col md={6} className="border-right">
            <p>
              Locations of all shapes & sizes, all over the country,
              are used for filming and photography on a daily basis.
              From domestic to corporate to military to royal; period, modern,
              urban, rural; lakes, beaches, cliffs, woodland - the requirements
              are endless! What may seem like a tatty old house to you might be
              a treasure trove to a Director!
            </p>
            <p>
              Most places, with appropriate dressing, would make a suitable
              location for something.
            </p>
            <p>
              Whether you have a residential or non-residential premises,
              a patch of land, a stand-alone company or a nationwide chain
              of businesses you can list your property for potential hire
              on the Kays Locations website.
            </p>
            {!isIndustrial && <Link to="/add-location">
              <Button color="primary" className="text-uppercase">
                LIST YOUR LOCATION
              </Button>
            </Link>}
          </Col>
          <Col md={6}>
            {mediaArr.map(({ iconName, description }, index) => (
              <CustomMedia
                key={index}
                mediaData={{ icon: iconName, text: description }}
              ></CustomMedia>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default SectionContent
