import React, {useState, useEffect} from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Map, Marker,InfoWindow } from 'google-maps-react'
import { Link, BrowserRouter } from 'react-router-dom'
import './style.scss'
import { initMap } from '../../../_helpers'

const MapView = (props) => {
  // eslint-disable-next-line
  const [ref, setRef] = useState({})
  const [isGoogleMapsLoaded, trigger] = useState(false)
  const [activeMarker, setActiveMarker] = useState({})
  const [showingInfoWindow, setShowingInfoWindow] = useState(false)
  const [selectedPlace, setSelectedPlace] = useState({})

  const {
    modal,
    setModalState,
    locations
  } = props

  useEffect(() => {
    let isSubscribed = true
    initMap().then(() => {
      if(isSubscribed) trigger(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => isSubscribed = false
  }, [])


  const boundsCoords = []
  locations && locations.forEach(item => {
    boundsCoords.push({lat: item.latitude, lng: item.longitude})
  })

  const bounds = isGoogleMapsLoaded && window.google && new window.google.maps.LatLngBounds()
  isGoogleMapsLoaded && boundsCoords.forEach(item => {
    if(!item.lat || !item.lng) return
    bounds.extend(item)
  })

  const onMarkerClick = (props, marker) => {
    setSelectedPlace(props)
    setActiveMarker(marker)
    setShowingInfoWindow(true)
  }

  const onMapClick = (props) => {
    if (showingInfoWindow) {
      // eslint-disable-next-line no-unused-expressions
        setShowingInfoWindow(false)
        setActiveMarker(null)
    }
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={setModalState(false)} className="modal-map">
        <ModalBody>
          <ModalHeader>
              Map View
              <Button color="transparent" onClick={setModalState(false)}><i className="icon-times"></i></Button>
          </ModalHeader>
          <div className="google-map-modal">
            {isGoogleMapsLoaded &&
              <Map
                ref={setRef}
                google={window.google}
                zoom={boundsCoords.length === 1 ? 9 : 6}
                center={boundsCoords[0] || {lat: 54.821970, lng: -5.418672}}
                bounds={boundsCoords.length > 1 ? bounds : null}
                fullscreenControl={false}
                mapTypeControl={false}
                streetViewControl={false}
                onClick = {onMapClick}
              >
                {locations && locations.map((item, index) => {
                  return <Marker
                    onClick={onMarkerClick}
                    key={index}
                    id={item.id}
                    name={item.name}
                    photo={item.image_featured_urls}
                    position={{lat: item.latitude, lng: item.longitude}}
                  />
                })}
                <InfoWindow
                  marker={activeMarker}
                  visible={showingInfoWindow}
                >
                  <div style={{width: 260}} >
                    <BrowserRouter>
                      <Link to={`/location_page/${selectedPlace.id}`}>
                        {selectedPlace.photo && selectedPlace.photo.length !== 0 && <img style={{width: 260, height: 160, marginBottom: 5}} src={selectedPlace.photo} alt={""} />}
                        <div >{selectedPlace.name}</div>
                      </Link>
                    </BrowserRouter>
                  </div>
                </InfoWindow>
              </Map>
            }
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default MapView;
