import React, { useEffect, useRef, useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'
import NewsCard from '../../../components/_common/Card/NewsCard'
import { newsActions } from '../../../_actions/news.actions'
import InfiniteScroll from '../../../components/_common/InfiniteScroll'


const NewsList = ({
  page,
  news: {
    list,
    categories,
    perPage,
    categoryId,
    archive_id
  },
  keyword,
  newsSearch,
  clearNewsList,
  loadNewsList,
  increasePage
}) => {
  const delay = useRef(false)

  const newsListRequest = useCallback(() => {
    loadNewsList({ page, perPage, categoryId, archive_id  })
  }, [perPage, page, categoryId, loadNewsList, archive_id])

  useEffect(() => {
    clearNewsList()
  }, [categoryId, archive_id, clearNewsList])

  useEffect(() => {
    if (keyword === ''){
      newsListRequest()
    } else if (keyword.length > 0) {
      if (delay.current){
        clearTimeout(delay.current);
      }
      delay.current = setTimeout(() => {
        newsSearch({ keyword })
      }, 400)

      return () => clearTimeout(delay.current)
    }
  }, [newsSearch, keyword, newsListRequest, clearNewsList])

  if (list.isError) return <p className="text-warning">We got some error</p>
  return (
    <InfiniteScroll handler={increasePage} status={!list.isLoaded} isLoadable={list.total > list.to}>
      <Row>
        {list.data.map((item, index) => {
          return (
            <Col className="swiper-custom__slide" key={item.title + index} md={6} xs={12}>
              <NewsCard type={'complex'} data={item} cardProps categories={categories.data} />
            </Col>
          );
        })}
      </Row>
    </InfiniteScroll>
  )
}

NewsList.propTypes = {
  clearNewsList: PropTypes.func
}

const mapStateToProps = state => ({
  news: state.news,
  page: state.news.page,
  keyword: state.news.keyword
})

const mapDispatchToProps = {
  loadNewsList: newsActions.loadList,
  clearNewsList: newsActions.clearNewsList,
  increasePage: newsActions.increaseNewsPage,
  newsSearch: newsActions.newsSearch
}

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(NewsList)
