import { generalConstants } from '../_constants/config.constants'

function loadCategories() {
  const requestOptions = {
    method: 'GET',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
  }

  return (
    fetch(
      `${generalConstants.BASIC_URL}/news/categories`,
      requestOptions
    )
      .then(handleResponse)
  )
}

function loadDates() {
  const requestOptions = {
    method: 'GET',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
  }

  return (
    fetch(
      `${generalConstants.BASIC_URL}/news/group_date_list`,
      requestOptions
    )
      .then(handleResponse)
  )
}

const loadNewsList = ({ page, perPage, categoryId, archive_id }) => {
  const requestOptions = {
    method: 'GET',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
  }
  return (
    fetch(`${generalConstants.BASIC_URL}/news/published?${page ? 'page=' + page + '&' : ''}${perPage ? 'per_page=' + perPage + '&' : '' }${ categoryId ? `category_id=${categoryId}&` : ''}${ archive_id ? `archive_id=${archive_id}&` : ''}`, requestOptions)
      .then(handleResponse)
  )
}

const newsSearch = ({ keyword }) => {
  const requestOptions = {
    method: 'GET',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
  }
  return (
    fetch(`${generalConstants.BASIC_URL}/news/published/search?${keyword ? 'key=' + keyword : ''}`, requestOptions)
      .then(handleResponse)
  )
}

const loadNewsPost = slug => {
  const requestOptions = {
    method: 'GET',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
  }
  return (
    fetch(`${generalConstants.BASIC_URL}/news/published/${ slug ? slug : ''}`, requestOptions)
      .then(handleResponse)
  )
}

const loadLatestNewsPost = slug => {
  const requestOptions = {
    method: 'GET',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
  }
  return (
    fetch(`${generalConstants.BASIC_URL}/news/published?per_page=8`, requestOptions)
      .then(handleResponse)
  )
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }
    return data
  })
}

export const newsService = {
  loadCategories,
  loadNewsList,
  loadNewsPost,
  loadLatestNewsPost,
  newsSearch,
  loadDates
}
