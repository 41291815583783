import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Card,
  CardTitle,
  CardBody,
  CardText,
} from 'reactstrap'

import './styles.scss'
import { useSelector } from 'react-redux'

const RecentlyCard = ({
  type,
  data
}) => {
  const isLogged = useSelector(state => state.authentication.loggedIn)

  const imagePath = data.image_featured_urls && data.image_featured_urls[0] ? data.image_featured_urls[0] : require(`../../../assets/images/slider-img-1.jpg`)

  return (
    <Card className={`card card-news`}>
      <Link to={{pathname: `/location_page/${data.id}`, state: {fromLocations: '/'}}} className={`card__image-wrap`}>
        <div className="card__image" style={{ backgroundImage: `url(${imagePath})` }}/>
      </Link>
      <CardBody>
        <CardTitle tag={'h4'} className={`card-${type}__title`}>
          <Link to={{pathname: `/location_page/${data.id}`, state: {fromLocations: '/'}}}>
            {isLogged ? <b>{data.name}</b> : 'Ref ' + data.id}
          </Link>
        </CardTitle>

        <CardText className={`card-${type}-meta`}>
          {data.categories.map((i, idx) => (
            <span key={idx} className="card-news__description" title={i.name}>{i.name}</span>
          ))}
        </CardText>
        <CardText className="card-news__description">{data.description && data.description.length > 24 ? data.description.slice(0, 200) + '...' : data.description}</CardText>
      </CardBody>
    </Card>
  )
}

RecentlyCard.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object.isRequired,
}

export default RecentlyCard
