import { locationCategoriesConstants } from '../_constants/location-categories.constants';
import { locationCategoriesService } from '../_services/location-categories.service'

const fetchLocationAlphabetCategories = () => {
  const loadStart = () => ({
    type: locationCategoriesConstants.GET_LOCATION_ALPHABET_CATEGORIES_REQUEST
  })

  const loadSuccess = categories => ({
    type: locationCategoriesConstants.GET_LOCATION_ALPHABET_CATEGORIES_SUCCESS,
    payload: categories
  })

  const loadFailure = () => ({
    type: locationCategoriesConstants.GET_LOCATION_ALPHABET_CATEGORIES_FAILURE
  })

  return dispatch => {
    dispatch(loadStart())
    locationCategoriesService
      .loadLocationAlphabetCategories()
      .then(
        categories => {
          const normalizedData = Object.keys(categories).reduce((result, key) => {
            result.push({headerTitle: key, linkList: [...categories[key]]})
            return result;
          }, [])
          dispatch(loadSuccess(normalizedData))
        }
      )
      .catch(error => {
        console.log(error);
        dispatch(loadFailure())
      })
  }
}

const fetchLocationCategories = () => {
  const loadStart = () => ({
    type: locationCategoriesConstants.GET_LOCATION_CATEGORIES_REQUEST
  })

  const loadSuccess = categories => ({
    type: locationCategoriesConstants.GET_LOCATION_CATEGORIES_SUCCESS,
    payload: categories
  })

  const loadSuccessMaster = categories => ({
    type: locationCategoriesConstants.GET_LOCATION_CATEGORIES_MASTER_SUCCESS,
    payload: categories
  })

  const loadFailure = () => ({
    type: locationCategoriesConstants.GET_LOCATION_CATEGORIES_FAILURE
  })

  return dispatch => {
    dispatch(loadStart())
    locationCategoriesService
      .loadLocationCategories()
      .then(
        categories => {
          const normalizedData = categories.reduce((result, {name, category_id, children = []}) => {
            result = [...result, ...children.map(o => ({value: o.id, label: o.name, category_id: o.category_id, parentCatName: name, parentCatId: category_id}))]
            return result
          }, [])
          normalizedData.sort((a, b) => {
            if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
            if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
            return 0;
          })

          const normalizedDataMaster = categories.reduce((result, key) => {
            result.push({headerTitle: key.name, linkList: key.children })
            return result
          }, [])

          dispatch(loadSuccess(normalizedData))
          dispatch(loadSuccessMaster(normalizedDataMaster))
        }
      )
      .catch(error => {
        console.log(error);
        dispatch(loadFailure())
      })
  }
}

const fetchLocationKeywords = () => {
  const loadStart = () => ({
    type: locationCategoriesConstants.GET_LOCATION_KEYWORDS_REQUEST
  })

  const loadSuccess = keywords => ({
    type: locationCategoriesConstants.GET_LOCATION_KEYWORDS_SUCCESS,
    payload: keywords
  })

  const loadFailure = () => ({
    type: locationCategoriesConstants.GET_LOCATION_KEYWORDS_FAILURE
  })

  return dispatch => {
    dispatch(loadStart())
    locationCategoriesService
      .loadLocationKeywords()
      .then(
        keywords => {
          const normalizedData = keywords.map(k => ({value: k.id, label: k.name}))
          dispatch(loadSuccess(normalizedData))
        }
      )
      .catch(error => {
        console.log(error);
        dispatch(loadFailure())
      })
  }
}

export const locationCategoriesActions = {
  fetchLocationAlphabetCategories,
  fetchLocationCategories,
  fetchLocationKeywords,
}
