export function authHeader(params) {
  // return authorization header with jwt token
  const user = JSON.parse(localStorage.getItem('user'))

  if (user && user.access_token) {
    return {
      Authorization: 'Bearer ' + user.access_token,
      ...(params ? params : {'Content-Type': 'application/json'})
    }
  } else {
    return {}
  }
}


