import React from 'react'
import { Button } from 'reactstrap'
import './styles.scss'


const PlanCard = ({subscription, onRemovePlan}) => {
  const cancelSubscription = () => {
    onRemovePlan(subscription.id)
  }
  return (
    <>
      <h4>{`${subscription.type} Package - ${subscription.location_name}`}</h4>
      <div>

        <div className="text-holder">
          <span className="txt">
            {`You have `}
            <strong>{`1 active `}</strong>
            {`${subscription.type}`} 
            {` listing. `}
          </span>
          <span className="txt">
            {`Billed at `}
            <strong>£${subscription.price}</strong>
            {` per  `}
            {`${subscription.period}`}
          </span>
        </div>

        <Button onClick={cancelSubscription} className="btn-icon">
          <i className="icon-times-circle"/>
          Cancel my subscription
        </Button>
      </div>
    </>
  )

}

export default PlanCard
