import React from 'react'
import { Container } from 'reactstrap'
import SectionTitle from '../../components/_common/SectionTitle/SectionTitle'
import Routes from './Routes';
import ChangePassword from './ChangePassword'
import HeroImage from '../../assets/images/account-hero-img.jpg'
import HeroBgImage from "../../components/_common/HeroBgImage/HeroBgImage";
import './styles.scss'

const Profile = () => {
  return (
      <>
        <HeroBgImage backgroundImg={HeroImage} type={'simple'} />
        <section className="section-content details-content">
          <SectionTitle title="My Details"/>
          <Container>
            <Routes />
            <ChangePassword/>
          </Container>
        </section>
      </>

  )
}

export default Profile
