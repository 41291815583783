import React from 'react'
import { connect } from 'react-redux'

const LocationSinglePage = props => {
  return (
    <div>
      {/* <ShareOnBlock
        pinterestPhoto={props.location.main_photo}
        shareUrl={`${String(window.location)}`}
        size={32}
      /> */}
    </div>
  )
}
const mapStateToProps = (state, ownProps) => {
  return {
    location: state.locations.list.filter(
      location => location.id === ownProps.match.params.id
    )[0],
  }
}

export default connect(mapStateToProps)(LocationSinglePage)
