import { newsConstants } from '../_constants/news.constants'
import { newsService } from '../_services/news.service'

const loadCategories = () => {
  const loadStart = () => ({
    type: newsConstants.CATEGORY_REQUEST
  })

  const loadSuccess = categories => ({
    type: newsConstants.CATEGORY_SUCCESS,
    payload: categories
  })

  const loadFailure = error => ({
    type: newsConstants.CATEGORY_FAILURE,
    error
  })

  return dispatch => {
    dispatch(loadStart())
    newsService
      .loadCategories()
      .then(
        categories => {
          dispatch(loadSuccess(categories))
        }
      ).catch(error => {
        dispatch(loadFailure(error))
      })
  }
}

const loadDates = () => {
  const loadStart = () => ({
    type: newsConstants.DATES_REQUEST
  })

  const loadSuccess = dates => ({
    type: newsConstants.DATES_SUCCESS,
    payload: dates
  })

  const loadFailure = error => ({
    type: newsConstants.DATES_FAILURE,
    error
  })

  return dispatch => {
    dispatch(loadStart())
    newsService
      .loadDates()
      .then(
        dates => {
          dispatch(loadSuccess(dates))
        }
      ).catch(error => {
      dispatch(loadFailure(error))
    })
  }
}

const loadList = props => {
  const loadStart = () => ({
    type: newsConstants.LIST_REQUEST
  })

  const loadSuccess = categories => ({
    type: newsConstants.LIST_SUCCESS,
    payload: categories
  })

  const loadFailure = payload => ({
    type: newsConstants.LIST_FAILURE,
    payload
  })

  return dispatch => {
    dispatch(loadStart())
    newsService
      .loadNewsList(props)
      .then(data => {
        dispatch(loadSuccess(data))
      })
      .catch(error => {
        dispatch(loadFailure(error.message))
      })
  }
}

const newsSearch = props => {
  const loadStart = () => ({
    type: newsConstants.SEARCH_REQUEST
  })

  const loadSuccess = payload => ({
    type: newsConstants.SEARCH_SUCCESS,
    payload: payload
  })

  const loadFailure = payload => ({
    type: newsConstants.SEARCH_FAILURE,
    payload
  })

  return dispatch => {
    dispatch(loadStart())
    newsService
      .newsSearch(props)
      .then(data => {
        dispatch(loadSuccess(data))
      })
      .catch(error => {
        dispatch(loadFailure(error.message))
      })
  }
}

const loadLatest = props => {
  const loadStart = () => ({
    type: newsConstants.LATEST_NEWS_REQUEST
  })

  const loadSuccess = categories => ({
    type: newsConstants.LATEST_NEWS_SUCCESS,
    payload: categories
  })

  const loadFailure = payload => ({
    type: newsConstants.LATEST_NEWS_FAILURE,
    payload
  })

  return dispatch => {
    dispatch(loadStart())
    newsService
      .loadLatestNewsPost(props)
      .then(data => {
        dispatch(loadSuccess(data))
      })
      .catch(error => {
        dispatch(loadFailure(error.message))
      })
  }
}

const loadPost = id => {
  const loadStart = () => ({
    type: newsConstants.POST_REQUEST
  })

  const loadSuccess = categories => ({
    type: newsConstants.POST_SUCCESS,
    payload: categories
  })

  const loadFailure = payload => ({
    type: newsConstants.POST_FAILURE,
    payload
  })

  return dispatch => {
    dispatch(loadStart())
    newsService
      .loadNewsPost(id)
      .then(data => {
        dispatch(loadSuccess(data))
      })
      .catch(error => {
        dispatch(loadFailure(error.message || error))
      })
  }
}

const clearNewsList = () => {
  return dispatch => dispatch({
    type: newsConstants.CLEAR_LIST
  })
}

const changeNewsFilter = payload => {
  return dispatch => dispatch({
    type: newsConstants.CHANGE_FILTER,
    payload
  })
}

const increaseNewsPage = () => {
  return dispatch => dispatch({
    type: newsConstants.INCREASE_PAGE
  })
}

const changeNewsCategory = payload => {
  return dispatch => dispatch({
    type: newsConstants.CHANGE_CATEGORY,
    payload
  })
}

const changeDate = payload => {
  return dispatch => dispatch({
    type: newsConstants.CHANGE_DATE,
    payload
  })
}

const setSearchKey = payload => {
  return dispatch => dispatch({
    type: newsConstants.SET_SEARCH_KEY,
    payload
  })
}

export const newsActions = {
  loadCategories,
  loadDates,
  loadList,
  clearNewsList,
  changeNewsFilter,
  increaseNewsPage,
  changeNewsCategory,
  loadPost,
  changeDate,
  loadLatest,
  newsSearch,
  setSearchKey
}