import { generalConstants } from '../_constants/config.constants'
import { authHeader } from '../_helpers'
import { handleFormErrors } from '../_helpers/errors'

const createNewLocation = props => {
  const requestOptions = {
    method: 'POST',
    mode: 'cors',
    headers: authHeader(),
    body: JSON.stringify(props)
  }
  return (
    fetch(`${generalConstants.BASIC_URL}/locations`, requestOptions)
      .then(handleResponse)
  )
}

const updateNewLocation = props => {
  const { id } = props
  const requestOptions = {
    method: 'PATCH',
    mode: 'cors',
    headers: authHeader(),
    body: JSON.stringify(props)
  }
  return (
    fetch(`${generalConstants.BASIC_URL}/locations/${id}`, requestOptions)
      .then(handleResponse)
  )
}

const pullPrevLocation = (id) => {
  const requestOptions = {
    method: 'GET',
    mode: 'cors',
    headers: authHeader()
  }
  return (
    fetch(`${generalConstants.BASIC_URL}/locations/${id}`, requestOptions)
      .then(handleResponse)
  )
}

function handleResponse(response) {
	return response.text().then((text) => {
		let data = text && JSON.parse(text)
		if (!response.ok) {
			const error = handleFormErrors(data)
			return Promise.reject(error)
		}
		return data
	})
}

export const locationService = {
  createNewLocation,
  updateNewLocation,
  pullPrevLocation
}
