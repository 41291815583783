import React, { useEffect } from 'react'
import { Button, Spinner, Table } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { invoicesParams, getDate, history } from '../../_helpers'


const BillingHistory = () => {
  const dispatch = useDispatch()
  const {invoices, invoicesLoading} = useSelector(({billing}) => ({
    invoices: billing.invoices,
    invoicesLoading: billing.invoicesLoading
  }))

  useEffect(() => {
    invoicesParams(dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadMore = () => {
    const page = +invoices.per_page + 12
    const {search, pathname} = window.location
    const params = new URLSearchParams(search)
    params.set('per_page', page)
    history.replace(`${pathname}?${params.toString()}`)
    setTimeout(() => {
      invoicesParams(dispatch)
    })
  }


  return (
    <>
      <Table borderless className="billing-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Invoice #</th>
            <th>Amount</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!!invoices.data && invoices.data.map((item, idx) => {
            return <tr key={idx}>
              <th scope="row" className="font-weight-normal">{getDate(item.created)}</th>
              <td>{item.number}</td>
              <td>{`£${item.amount}`}</td>
              <td className={`invoice-${item.status}`}><strong>{item.status}</strong></td>
              <td>
                <a href={item.invoice_pdf} className="btn btn-primary btn-pdf" target="_blank" rel="noopener noreferrer">
                  <span>View PDF</span>
                  <i className="icon-file-pdf"></i>
                </a>
              </td>
            </tr>
          })}
        </tbody>
      </Table>
      <div className="btn-row">
        {invoicesLoading && <Spinner/>}
        {!invoicesLoading && !invoices.data && <div>Empty history</div> }
        {invoices.total > invoices.per_page ? <Button onClick={loadMore}>Load more</Button> : null}
      </div>
    </>
  )

}

export default BillingHistory
