import React from 'react'
import { Link } from 'react-router-dom'

const FormCheckbox = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched },
  id,
  label,
  labelTmpl,
  className,
  close,
  ...props
}) => {
  const closeModal = () => {
    close()
  }

  const setLabelTmpl = type => {
    switch (type) {
      case 'privacyPolicy':
        return (
          <>
            By creating a location owner account, you agree to have read our{' '}
            <Link onClick={closeModal} to="/terms">terms of use</Link> and{' '}
            <Link onClick={closeModal} to="/privacy">privacy policy</Link>
          </>
        )
      case 'remember':
        return 'Remember me'
      default:
        return null
    }
  }
  return (
    <div className="custom-checkbox custom-control group-2">
      <input
        name={name}
        id={id}
        type="checkbox"
        value={value}
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
        className={'custom-control-input'}
      />
      <label className="custom-control-label" htmlFor={id}>
        {labelTmpl ? setLabelTmpl(labelTmpl) : label}
      </label>
      {touched[name] && errors[name] && (
        <div className={'invalid-feedback'}>{errors[name]}</div>
      )}
    </div>
  )
}

export default FormCheckbox
