import React, { useState } from 'react';
import { Button, Collapse } from 'reactstrap';
import './styles.scss';

const Filters = ({ filters, setFilter, title }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <div className="filters">
      <h5 className="filters__title">{title}</h5>
      {title === 'Archive' ? <ul className="filters">
        {filters && filters.map((filter, idx) => (
          (idx < 2) ? <li key={filter.archive_id}>
            <Button className="filters__button" color="link" onClick={() => setFilter(filter)}>
              {filter.month_name} {filter.year}
            </Button>
          </li> :
          <Collapse key={filter.archive_id} isOpen={isOpen}>
            <li key={filter.archive_id}>
              <Button className="filters__button" color="link" onClick={() => setFilter(filter)}>
                {filter.month_name} {filter.year}
              </Button>
            </li>
          </Collapse>
        ))}
        {filters.length > 2 ? <Button onClick={toggle} color="transparent" className="btn btn-link float-right" style={{ marginTop: '1rem' }}>{isOpen ? 'Show less' : 'Show more'}</Button> : null}
        </ul> :
        <ul className="filters">
          {filters.map((filter) => (
            <li key={filter.id}>
              <Button className="filters__button" color="link" onClick={() => setFilter(filter)}>
                {filter.name}
              </Button>
            </li>
          ))}
        </ul>}
    </div>
  )
}

export default Filters;
