import React, { useEffect, useState } from 'react'
import { Container, Row, Col, FormGroup, Form, CardText, Button } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import CardLocation from '../../components/_common/Card/CardLocation'
import DefaultSelect from '../../components/_common/DefaultSelect'
import FilterBar from './FilterBar/FilterBar'
import Preloader from '../../components/_common/Preloader'
import SideBar from './SideBar/SideBar'
import MapView from './MapView/MapView'
import { searchLocations, history } from '../../_helpers'
import './style.scss'
import PaginationComponent from "react-reactstrap-pagination"


const sortBy = ['radius', 'county']


const LocationList = (props) => {
  const searchParams = new URLSearchParams(window.location.search)
  const searchLocation = searchParams.get('search-location')
  const searchSort = searchParams.get('search-sort')
  const {pathname} = window.location
  const [modal, setModal] = useState(false)
  const locations = useSelector(state => state.locations.list)
  const locationsState = useSelector(state => state.locations)
  const category = useSelector(state => state.locationCategories.main_list.data).find(i => +i.value === +searchParams.get('search-category'))
  const isLogged = useSelector(state => state.authentication.loggedIn)
  const dispatch = useDispatch()
  const setStateModal = (state) => () => {
    setModal(state)
  }

  useEffect(() => {
    searchLocations(dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateLocationSearch = (key, value) => {
    searchParams.set(key, value)
    history.replace(`${pathname}?${searchParams.toString()}`)
  }

  const sortSelect = (value) =>  {
    updateLocationSearch('search-sort', value)
  }

  const changePage = (page) => {
    const {history} = props
    const {search, pathname} = window.location
    const params = new URLSearchParams(search)
    params.set('page', page)
    history.replace(`${pathname}?${params.toString()}`)
    setTimeout(() => {
      searchLocations(dispatch)
    })
  }
  return (
    <>
      <FilterBar />
      <Preloader status={locationsState.loading}/>
      {!locationsState.loading && !locationsState.isError &&
      <>
        <Container className="location-filters-holder">
          <Form className="col-lg-8 align-items-center pl-0 pr-0">
            <Row form className="align-items-center ">
              <Col className="list-text col-6">
                {locations.length > 0 && <CardText><span>Showing</span> {20 * locationsState.current_page - 19}-{locations.length * locationsState.current_page} <span>of</span> {locationsState.total} <span>locations</span> </CardText>}
              </Col>
              <Col className="list-filters col-6 d-flex justify-content-end align-items-center">
                {isLogged ? <Button className="mapView" onClick={setStateModal(true)} type='button'>Map View</Button> : null}
                <FormGroup>
                  <div className='arrow-holder'>
                    <DefaultSelect
                      id={'sortBy'}
                      name={'sortBy'}
                      options={sortBy}
                      placeholder={'Sort By'}
                      onChange={sortSelect}
                      defaultValue={searchSort}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Container>
        <Container className="location-list-container">
          <MapView modal={modal} setModalState={setStateModal} locations={locations} />
          <Row>
            <Col className="col-12 col-lg-8">
              <div>
                {locationsState.isError && <p className="text-warning">No results</p>}
                {(!locations || locations.length === 0) && <p className="text-warning">No results</p>}
                {locations && locations.map((item, idx) => (
                  <div key={idx} className={'mb-4'}>
                    <CardLocation location={item} category={category}/>
                  </div>
                ))}
              </div>
            </Col>
            <Col className="col-12 col-lg-4">
              <SideBar target={searchLocation}/>
            </Col>
          </Row>
          {!!locationsState.total && <Row className="">
            <Col className="col-lg-6 d-flex justify-content-center justify-content-lg-end">
              <PaginationComponent
                totalItems={locationsState.total}
                pageSize={20}
                defaultActivePage={locationsState.current_page}
                previousPageText={'<'}
                nextPageText={'>'}
                maxPaginationNumbers={5}
                navContainerClass={
                  'd-flex align-items-center justify-content-center'
                }
                onSelect={changePage}
              />
            </Col>
          </Row>}
        </Container>
      </>
      }
    </>
  )
}

export default LocationList
