import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import ChangeProfile from '../ChangeProfile'
import ChangeAddress from '../ChangeAddress';

const ProfileInfoEdit = () => {
  return (
    <>
      <Link to="/profile">&lt; Back to My Account</Link>
      <Row className="flex-column flex-md-row">
        <Col>
          <ChangeProfile/>
        </Col>
        <Col>
          <ChangeAddress/>
        </Col>
      </Row>
    </>
  )
}

export default ProfileInfoEdit
