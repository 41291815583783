import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { locationActions } from '../../../_actions'
import './style.scss'
import { initMap } from '../../../_helpers'
import GoogleMapReact from 'google-map-react'


const SideBar = (props) => {
  const targetCoords = useSelector(state => state.locations.targetCoords)
  const dispatch = useDispatch()
  const { target } = props
  const pageParams = new URLSearchParams(window.location.search)
  const [isGoogleMapsLoaded, trigger] = useState(false)
  const [center, setCenter] = useState({lat: 54.821970, lng: -5.418672})
  const [mapConfig, setMapConfig] = useState(null)
  const [circleConfig, setCircleConfig] = useState(null)



  useEffect(() => {
    if(target) dispatch(locationActions.getCoords(target))
    let isSubscribed = true
    initMap().then(() => {
      if(isSubscribed) trigger(true)
    })
    return () => isSubscribed = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target])


  useEffect(() => {
    circleConfig && circleConfig.setMap(null);
    mapConfig && renderMarkers(mapConfig)
    setCenter({ lat: targetCoords.lat, lng: targetCoords.lng });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageParams.get('search-radius')])

  const zoomParams = () => {
    switch (+pageParams.get('search-radius')) {
      case 1:
        return 13
      case 5:
        return 10
      case 15:
        return 9
      case 30:
        return 8
      case 50:
        return 7
      case 100:
        return 6
      default:
        return 5
    }
  }

  const getMapBounds = (map, maps, places) => {
    const bounds = new window.google.maps.LatLngBounds();

    places.forEach((place) => {
      bounds.extend( new window.google.maps.LatLng(
        place.lat,
        place.lng,
      ));
    });
    return bounds;
  };

  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      map.setZoom(target && !pageParams.get('search-radius') ? 9 : zoomParams());
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };

  const renderMarkers = ({map, maps}) => {
    setMapConfig({map, maps})
    const bounds = getMapBounds(map, maps, [{ lat: targetCoords.lat, lng: targetCoords.lng }]);
    map.fitBounds(bounds);
    bindResizeListener(map, maps, bounds);
    let circle =  target && new window.google.maps.Circle({
      strokeColor: 'transparent',
      strokeOpacity: 0,
      strokeWeight: 5,
      fillColor: '#FF0000',
      fillOpacity: 0.2,
      map,
      center: { lat: targetCoords.lat, lng: targetCoords.lng },
      radius: (target && +pageParams.get('search-radius') * 1609.34) || 25000,
    })
    setCircleConfig(circle)
    let marker = target &&  new window.google.maps.Marker({
      position: { lat: targetCoords.lat, lng: targetCoords.lng },
      map,
    });
    return {marker, circle};
  };



  const mapOptions = {
    fullscreenControl: false,
    disableDefaultUI:true,
    draggable: false
  };


  return (
    <div className="map-sidebar">
      {isGoogleMapsLoaded ?
        <div className="google-map">
          <GoogleMapReact
            debounced={true}
            center={center}
            zoom={!pageParams.get('search-radius') ? 9 : zoomParams()}
            resetBoundsOnResize={true}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={renderMarkers}
            options={mapOptions}
          />
      </div>
      : null}
      {/*<div className="banner-box"></div>*/}
    </div>
  )
}

export default SideBar
