import React from 'react';

const SignIn = ({ fill = '#000', width = '100%', height = width, className = ''  }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 36 32">
      <path 
        fill={fill}
        d="M35.619 27.569c-0.031-0.025-1.413-1.512-2.369-3.431 1.719-1.694 2.75-3.819 2.75-6.137 0-5-4.781-9.131-11.012-9.869-1.962-3.6-6.594-6.131-11.988-6.131-7.181 0-13 4.475-13 10 0 2.313 1.031 4.438 2.75 6.137-0.956 1.919-2.331 3.406-2.356 3.431-0.394 0.419-0.506 1.031-0.275 1.563 0.225 0.531 0.75 0.875 1.325 0.875 3.344 0 6.044-1.262 7.825-2.425 0.569 0.131 1.15 0.231 1.75 0.3 1.969 3.594 6.594 6.125 11.988 6.125 1.3 0 2.55-0.15 3.738-0.425 1.781 1.156 4.475 2.425 7.825 2.425 0.575 0 1.094-0.344 1.325-0.875 0.225-0.531 0.119-1.144-0.275-1.563zM9.713 19.625l-0.825-0.188-0.713 0.462c-1.256 0.819-3.156 1.762-5.481 2.031 0.55-0.706 1.263-1.725 1.844-2.9l0.65-1.3-1.031-1.019c-0.987-0.969-2.156-2.575-2.156-4.713 0-4.412 4.938-8 11-8s11 3.588 11 8-4.938 8-11 8c-1.106 0-2.213-0.125-3.287-0.375zM27.825 25.9l-0.712-0.462-0.825 0.194c-1.075 0.25-2.181 0.375-3.288 0.375-4.069 0-7.625-1.619-9.525-4.019 6.956-0.2 12.525-4.588 12.525-9.988 0-0.594-0.081-1.169-0.206-1.731 4.713 0.906 8.206 4.025 8.206 7.731 0 2.137-1.169 3.744-2.156 4.712l-1.031 1.019 0.644 1.294c0.588 1.181 1.3 2.2 1.844 2.9-2.319-0.262-4.219-1.212-5.475-2.025z"
      />
    </svg>


    // old icon:
    // <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 512 512">
    //   <path
    //     fill={fill}
    //     d="M32 217.1c0-8.8 7.2-16 16-16h144v-93.9c0-7.1 8.6-10.7 13.6-5.7l141.6 143.1c6.3 6.3 6.3 16.4 0 22.7L205.6 410.4c-5 5-13.6 1.5-13.6-5.7v-93.9H48c-8.8 0-16-7.2-16-16v-77.7m-32 0v77.7c0 26.5 21.5 48 48 48h112v61.9c0 35.5 43 53.5 68.2 28.3l141.7-143c18.8-18.8 18.8-49.2 0-68L228.2 78.9c-25.1-25.1-68.2-7.3-68.2 28.3v61.9H48c-26.5 0-48 21.6-48 48zM512 400V112c0-26.5-21.5-48-48-48H332c-6.6 0-12 5.4-12 12v8c0 6.6 5.4 12 12 12h132c8.8 0 16 7.2 16 16v288c0 8.8-7.2 16-16 16H332c-6.6 0-12 5.4-12 12v8c0 6.6 5.4 12 12 12h132c26.5 0 48-21.5 48-48z"
    //   />
    // </svg>
  );
};

export default SignIn;
