import React from 'react'
import { Label, Input } from 'reactstrap'
import PropTypes from 'prop-types'

const DefaultSelect = props => {
  const { id, name, label, options, placeholder, defaultValue } = props
  const handleChange = (e) => {
    props.onChange && props.onChange(e.target.value)
  }
  return (
    <>
      {label && <Label for={id}>{label}</Label>}
      <Input type="select" name={name} id={id} defaultValue={defaultValue || ''} onChange={handleChange}>
        {placeholder && (
          <option disabled value='' >
            {placeholder}
          </option>
        )}
        {options && options.map(option => <option key={option} >{option}</option>)}
      </Input>
    </>
  )
}

DefaultSelect.propTypes = {
  options: PropTypes.array,
}

export default DefaultSelect
