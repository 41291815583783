import React from 'react'
import { Switch, Route } from 'react-router-dom'

import NewsArticle from './NewsArticle'
import NewsList from './NewsList'

const NewsRoutes = () => {
  return (
    <Switch>
      <Route path="/news/:id" component={NewsArticle} /> 
      <Route path="/news" component={NewsList} />
    </Switch>
  )
}

export default NewsRoutes
