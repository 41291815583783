import { generalConstants } from '../_constants/config.constants'

export const initMap = () => {
  return new Promise((resolve, reject) => {
    if(window.google) return resolve()
    const scriptTag = document.createElement('script')
    scriptTag.type = 'text/javascript'
    scriptTag.id = 'location-autocomplete-library';
    scriptTag.src = `https://maps.googleapis.com/maps/api/js?key=${generalConstants.GET_GOOGLE_API_KEY}&libraries=places&region=uk`;
    (document.body).appendChild(scriptTag)
    scriptTag.addEventListener('load', resolve)
    scriptTag.addEventListener('error', reject)
  });
}