import { locationActions } from '../_actions'

export const searchLocations = (dispatch) => {
  const searchParams = new URLSearchParams(window.location.search)
  const searchKeyword = searchParams.get('search-keywords')
  const searchCategory = searchParams.get('search-category')
  const searchLongitude = searchParams.get('longitude')
  const searchLatitude = searchParams.get('latitude')
  const searchRadius = searchParams.get('search-radius')
  const searchLocation = searchParams.get('search-location')
  const searchSort = searchParams.get('search-sort')


  searchLocation ? 
  dispatch(locationActions.loadLocations({
    page: searchParams.get('page') || 1,
    category_ids: searchCategory || '',
    keyword_ids: searchKeyword || '',
    longitude: searchLongitude || '',
    latitude: searchLatitude || '',
    sort_by: searchSort || 'county',
    name: '',
    radius: searchRadius || 50
  })) :
  dispatch(locationActions.loadLocations({
    page: searchParams.get('page') || 1,
    category_ids: searchCategory || '',
    keyword_ids: searchKeyword || '',
    longitude: searchLongitude || '',
    latitude: searchLatitude || '',
    sort_by: searchSort || 'county',
    name: '',
  }))
}

export const searchFeaturedLocations = (dispatch) => {
  const searchParams = new URLSearchParams(window.location.search)
  dispatch(locationActions.loadFeaturedLocations({
    limit: searchParams.get('limit') || 12
  }))
}

export const searchFavorites = (dispatch) => {
  const searchParams = new URLSearchParams(window.location.search)
  const searchSort = searchParams.get('search-sort')
  const searchCategorySort = searchParams.get('category_ids')
  dispatch(locationActions.getUsersFavoriteLocations({
    page: searchParams.get('page') || 1,
    sort_by: searchSort || 'date',
    category_ids: searchCategorySort || ''
  }))
}

export const searchMy = (dispatch) => {
  const searchParams = new URLSearchParams(window.location.search)
  dispatch(locationActions.getUsersLocations({
    page: searchParams.get('page') || 1
  }))
}

export const removeHelper = (dispatch, id) => {
  const searchParams = new URLSearchParams(window.location.search)
  dispatch(locationActions.removeMyLocation(id, {
    page: searchParams.get('page') || 1
  }))
}