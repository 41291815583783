import { combineReducers } from 'redux'
import { registration } from './registration.reducer'
import { alert } from './alert.reducers'
import { formValidation } from './form.reducers'
import { authentication } from './authentication.reducer'
import { billing } from './billing.reducer'
import { modal } from './modal.reducer'
import { locations } from './locations.reducers'
import locationCategories from './location-categories.reduser'
import news from './news.reducer'
import address from './address.reducers'
import formOptions from './form-options.reduser'
import documents from './documents.reducres'

const rootReducer = combineReducers({
  registration,
  alert,
  formValidation,
  authentication,
  billing,
  modal,
  locations,
  news,
  address,
  locationCategories,
  formOptions,
  documents
})

export default rootReducer
