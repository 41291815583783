import { generalConstants } from '../_constants/config.constants'
import { authHeader } from '../_helpers'

function loadDocuments() {
  const requestOptions = {
    method: 'GET',
    mode: 'cors',
    headers: authHeader(),
  }

  return (
    fetch(
      `${generalConstants.BASIC_URL}/documents/list_individual`,
      requestOptions,
    )
      .then(handleResponse)
  )
}

function downloadDocument(name) {
  const requestOptions = {
    method: 'GET',
    mode: 'cors',
    headers: authHeader({'Content-Type': 'application/x-www-form-urlencoded'}),
  }

  fetch(`${generalConstants.BASIC_URL}/documents/${name}`, requestOptions).then(response => response.blob()).then((response) => {
    const url = window.URL.createObjectURL(response)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `file${name.match(/.[a-zA-Z0-9]+$/)[0]}`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  })
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }
    return data
  })
}

export const documentsService = {
  loadDocuments,
  downloadDocument
}