export const buildSearchParams = (params = {}) => {
  const searchParams = new URLSearchParams()
  Object.keys(params).forEach(p => searchParams.set(p, params[p]))
  return searchParams.toString()
}

export const convertUncerscoreObject = (response, isReverse) => {
  const toCamel = (s) => {
    if (isReverse) {
      return s.replace(/(?:^|\.?)([A-Z])/g, function (x,y){return "_" + y.toLowerCase()}).replace(/^_/, "")
    }
    return s.replace(/([-_][a-z])/ig, ($1) => {
      return $1.toUpperCase()
        .replace('-', '')
        .replace('_', '');
    });
  };
  
  const isArray = function (a) {
    return Array.isArray(a);
  };
  
  const isObject = function (o) {
    return o === Object(o) && !isArray(o) && typeof o !== 'function';
  };
  
  const keysToCamel = function (o) {
    if (isObject(o)) {
      const n = {};
  
      Object.keys(o)
        .forEach((k) => {
          n[toCamel(k)] = keysToCamel(o[k]);
        });
  
      return n;
    } else if (isArray(o)) {
      return o.map((i) => {
        return keysToCamel(i);
      });
    }
  
    return o;
  };

  return keysToCamel(response)
}