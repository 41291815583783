import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

export const ContentBox = ({
  className = 'bg-white content-box',
  ...props
}) => {
  return <div className={className}>{props.children}</div>
}

ContentBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}
