import React, {useState, useEffect}  from 'react'
import { Button, Form } from 'reactstrap'
import LocationSpecificSelect from '../../LocationSpecificSelect'
import { history } from '../../../_helpers'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { locationActions } from '../../../_actions'
import debounce from 'lodash/debounce'

const SearchFormSpecific = () => {
  const [location, setLocation] = useState('')

  const {options, loaded} = useSelector(({locations}) => ({
    options: locations.nameList.data,
    loaded: locations.loadingName,
  }), shallowEqual)

  const dispatch = useDispatch()

  const locations = options && options.map(i => ({value: i.id, label: i.name}))

  useEffect(() => {
    dispatch(locationActions.clearLocationList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeLocation = (option, value) => {
    setLocation(value)
  }

  const debounceLoadLocations = debounce((name) => {
    dispatch(locationActions.getLocationByName(name))
  }, 300)

  const handleInputChange = (value) => {
    if(value) debounceLoadLocations(value)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    history.push(`/location_page/${location}`)
  }

  return (
    <Form onSubmit={onSubmit} className="search-form" inline>
        <div className="specific-row">
          <LocationSpecificSelect loaded={loaded} onInputChange={handleInputChange} locations={locations} onChange={changeLocation} value={location} placeholder="Location name"/>
          <Button disabled={!location} color="primary">
            GO
          </Button>
        </div>
    </Form>
  )
}

export default SearchFormSpecific
