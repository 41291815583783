import addressConstants from '../_constants/address.constants'
import addressService from '../_services/address.service'

const getAddressByPostalCode = params => {
  const loadRequest = () => ({
    type: addressConstants.GET_ADDRESS_BY_POSTAL_CODE_REQUEST
  })
  const loadSuccess = payload => ({
    type: addressConstants.GET_ADDRESS_BY_POSTAL_CODE_SUCCESS,
    payload
  })
  const loadFailure = payload => ({
    type: addressConstants.GET_ADDRESS_BY_POSTAL_CODE_FAILURE,
    payload
  })

  return dispatch => {
    dispatch(loadRequest())
    addressService
      .getLocationByPostalCode(params)
      .then(payload => {
        dispatch(loadSuccess(payload))
        // if it second request we get postcode in answer, we push address to
        const onLoad = payload.postcode ? params.onLoadAddress : params.onLoadList;
        if (onLoad) {
          const address = payload.addresses.find(a => params.building && params.building.includes(a.formatted_address[0])) || payload.addresses[0]
          const { line_1, line_2, line_3, county, town_or_city } = address
          const { latitude, longitude } = payload
          onLoad({ addressLine1: !line_3 || line_3.trim().length === 0 ? line_1 : line_1 + ', ' + line_2, addressLine2: !line_3 || line_3.trim().length === 0 ? line_2 : line_3, city: town_or_city, postCode: params.postalCode, county, latitude, longitude })
        }
      })
      .catch(error => {
        dispatch(loadFailure(error.toString()))
      })
  }
}

const clearAddresses = () => {
  return dispatch => {
    dispatch({type: addressConstants.CLEAR_ADDRESSES})
  }
}

const addressActions = {
  getAddressByPostalCode,
  clearAddresses
}

export default addressActions
