import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FilePond } from 'react-filepond'
import { imagesService } from '../../../_services/images.service'
import { locationActions } from '../../../_actions'
import './styles.scss'

class FormFile extends Component {

  shouldComponentUpdate(nextProps) {
    return nextProps.files.length !== this.props.files.length || nextProps.error !== this.props.error || nextProps.isEdit !== this.props.isEdit
  }

  serverConfig() {
    return {
      process: (name, file, metadata, load, error, progress, abort, transfer, options) => {
        const data = new FormData();
        data.append('file', file);
        imagesService.addLocationImage(this.props.locationId, data, this.props.type)
          .then(data => {
            load(data.url)
            if (data.error) throw error
            this.props.locationIsEditFiles(true)
            this.props.addLocationFile(data.url, this.props.filesKey)
          })
          .catch(error)
      },
      revert: (uniqueFileId, load, error) => {
        const id = uniqueFileId.split('/').pop();
        imagesService.removeLocationImage(this.props.locationId, id, this.props.type)
          // .then(load)
          // .catch(error)
          .finally(() => {
            this.props.locationIsEditFiles(true)
            this.props.updateLocationFiles(uniqueFileId, this.props.filesKey)
          })
      },
      restore: (source, load, error, progress, abort, headers) => {
        // imagesService.fetchLocationImage(this.props.locationId, source)
        imagesService.fetchLocationImage(source)
          .then(load)
          .catch(error)
      },
    }
  }

  render() {
    const {maxFileSize, acceptedFileTypes, imagePreviewHeight, className = '', error,
      files = [], allowMultiple, maxFiles, label, isEdit, allowImagePreview = true} = this.props;
    const params = {
      maxFileSize,
      acceptedFileTypes,
      imagePreviewHeight,
      allowImagePreview,
      allowMultiple,
      maxFiles,
      className,
      ...(isEdit ? {} : {files}),
      labelIdle: label || 'Drag and drop a file here or click',
      server: this.serverConfig()
    }

    return (
      <>
        <FilePond
          {...params}
        />
        {error && <div className="form-file-error invalid-feedback">{error}</div>}
      </>
    );
  }
}

const mapDispatchToProps = {
  addLocationFile: locationActions.addLocationFile,
  updateLocationFiles: locationActions.updateLocationFiles,
  locationIsEditFiles: locationActions.locationIsEditFiles,
}

export default connect(
  null,
  mapDispatchToProps
)(FormFile)
