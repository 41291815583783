import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import './styles.scss'
import { Link } from 'react-router-dom'

const FullWidthSection = ({
  bgImage,
  bgColor,
  title,
  exception,
  button: { btnText, btnColor },
}) => (
  <section
    className={`it-work-section ${bgImage ? 'fullwidth-bg-section' : bgColor}`}
    style={{ backgroundImage: bgImage ? `url(${bgImage})` : '' }}
  >
    <Container>
      <Row>
        <Col className="info-holder ">
          <h2 className="info-holder__title">{title}</h2>
          <p>{exception}</p>
          <Link to={`/contact`}>
            <Button color={btnColor}>{btnText}</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  </section>
)

export default FullWidthSection
