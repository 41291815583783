import { modalConstants } from '../_constants'

export const modalActions = {
  openAuth,
  closeAuth,
  clearAuth,
  openGenericAlert,
  closeGenericAlert,
  openForgotModal,
  closeForgotModal,
  setActiveTab
}

function openAuth(payload) {
  return { type: modalConstants.OPEN, payload }
}

function closeAuth() {
  return { type: modalConstants.CLOSE }
}

function clearAuth() {
  return { type: modalConstants.CLEAR }
}
function openGenericAlert(id) {
  return { type: modalConstants.OPEN_LOCATION_MODAL, locationId: id }
}

function closeGenericAlert() {
  return { type: modalConstants.CLOSE_LOCATION_MODAL }
}

function openForgotModal() {
  return { type: modalConstants.OPEN_FORGOT_MODAL }
}

function closeForgotModal() {
  return { type: modalConstants.CLOSE_FORGOT_MODAL }
}

function setActiveTab(tab) {
  return { type: modalConstants.SET_ACTIVE_TAB, activeTab: tab }
}