import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Row, Col } from 'reactstrap'
import './styles.scss'

const AlertCustom = ({ alert: { type, message }, ...props }) => {
  return (
    <div className="alert-holder">
      <Row>
        <Col sm={12}>
          <Alert color={type}>{message}</Alert>
        </Col>
      </Row>
    </div>
  )
}

AlertCustom.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
}

export default AlertCustom
