import React from 'react'
import Accordion from '../../components/_common/Accordion'
import { Container, Row, Col } from 'reactstrap'
import HeroBgImage from '../../components/_common/HeroBgImage/HeroBgImage'
import SectionTitle from '../../components/_common/SectionTitle/SectionTitle'
import HeroImage from '../../assets/images/faq-hero-img.jpg'
import Card from '../../components/_common/Card/Card'
import './styles.scss'
import {cardsHolder} from '../../_content/faqs'


const FaqPage = () => {
  return (
    <>
      <HeroBgImage backgroundImg={HeroImage} type={'simple'} />
      <section className="section-content faq-section">
        <Container>
          <SectionTitle title={`FAQ's`} />
          <Row className={'align-items-start'}>
            <Col className="accordion-block" md={8}>
              <Accordion cards={cardsHolder} />
            </Col>
            <Col md={4}>
              <Card
                cardProps
                type={'link'}
                data={{
                  icon: 'info-circle',
                  title: 'Help & Support',
                  excerption:
                    'If you require any help or support from Kays Locations, get in touch!',
                  url: '/help-support',
                  buttonText: 'Read more',
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default FaqPage
