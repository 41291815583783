const location = {
  // PULL LOCATION TYPE OPTIONS
  LOCATION_TYPES_OPTIONS_REQUEST: 'LOCATION_TYPES_OPTIONS_REQUEST',
  LOCATION_TYPES_OPTIONS_SUCCESS: 'LOCATION_TYPES_OPTIONS_SUCCESS',
  LOCATION_TYPES_OPTIONS_FAILURE: 'LOCATION_TYPES_OPTIONS_FAILURE',

  // LOAD FULL DETAILS OPTIONS
  LOCATION_DETAILS_REQUEST: 'LOCATION_DETAILS_OPTIONS_REQUEST',
  LOCATION_DETAILS_SUCCESS: 'LOCATION_DETAILS_OPTIONS_SUCCESS',
  LOCATION_DETAILS_FAILURE: 'LOCATION_DETAILS_OPTIONS_FAILURE',

  // LOAD FULL DETAILS OPTIONS
  LOCATION_KEYWORDS_REQUEST: 'LOCATION_SORTED_KEYWORDS_OPTIONS_REQUEST',
  LOCATION_KEYWORDS_SUCCESS: 'LOCATION_SORTED_KEYWORDS_OPTIONS_SUCCESS',
  LOCATION_KEYWORDS_FAILURE: 'LOCATION_SORTED_KEYWORDS_OPTIONS_FAILURE',
}

export const optionsConstants = {
  ...location,
}