import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardTitle,
  CardBody,
  CardText,
  CardImg,
  Button,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import './styles.scss'
import {documentsActions} from '../../../_actions/documents.actions'
import {useDispatch} from 'react-redux';

const CustomCard = ({
  type,
  data: {
    title,
    image,
    date,
    category,
    excerption,
    buttonText,
    icon,
    url = '#',
    tag,
    downloadLink
  },
  className = '',
  style,
}) => {
  const dispatch = useDispatch()

  const downloadDocument = (name) => () => {
    dispatch(documentsActions.downloadDocument(name))
  }


  const imagePath = image ? require(`../../../assets/images/${image}`) : null
  switch (type) {
    case 'simple':
      return (
        <div className="card-holder">
          <Card
            inverse
            href={url}
            className={`card-${type}`}
            tag={tag}
            style={{
              backgroundImage: `url(${imagePath})`,
              ...style,
            }}
          >
            <CardTitle className="text-center" tag={'h3'}>{title}</CardTitle>
          </Card>
        </div>
      )
    case 'complex':
      return (
        <Card className={`card-${type}`}>
          <a href={url} className={`card-${type}__header`} >
            <CardImg top width="100%" src={imagePath} alt="Card image cap" />
          </a>
          <CardBody>
            <a href={url} >
              <CardTitle tag={'h4'} className={`card-${type}__title`}>
                {title}
              </CardTitle>
            </a>
            <CardText className={`card-${type}-meta`}>
              <small className={`card-${type}-meta__date`}>{date}</small>
              <span className={`card-${type}-meta__category`}>{category}</span>
            </CardText>
            <CardText>{excerption}</CardText>
          </CardBody>
        </Card>
      )
    case 'link':
      return (
        <Card outline color="secondary" className={`card-${type} ${className || ''}`}>
          <div className={'card-icon'}>
            <i className={`icon icon-${icon}`}/>
          </div>
          <div className="card-link__body">
            <CardTitle tag={'h6'}>{title}</CardTitle>
            <CardText>{excerption}</CardText>
          </div>
          <Button
            color={'primary'}
            className={'mt-md-auto'}
            onClick={downloadLink && downloadDocument(downloadLink)}
            tag={url ? Link : null}
            to={url ? url : null}
          >
            <i
              className={`d-md-none icon icon-${
                buttonText === 'Manage' ? 'cog' : 'eye'
              }`}
            />
            <span className={'d-none d-md-inline'}>{buttonText}</span>
          </Button>
        </Card>
      )
    default:
      return null
  }
}

CustomCard.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object.isRequired,
}

export default CustomCard
