import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, CardTitle } from 'reactstrap'
import { Link } from 'react-router-dom'

const ProfileInfo = ({user}) => {
  return (
    <Row className="flex-column flex-md-row">
      <Col>
        <Card className="profile-box">
          <h4 className="profile-box__title">Name <Link className="profile-box__edit" to="/profile/edit">
            <span className="profile-box__edit-text">Edit</span>
            <i className="icon icon-edit"/>
          </Link></h4>

          <p>{user.first_name} {user.last_name}</p>
          <h4 className="profile-box__title">My account email address</h4>
          <p>{user.email}</p>
          <h4 className="profile-box__title">Telephone number</h4>
          <p>{user.phone}</p>
        </Card>
      </Col>
      <Col>
        <Card className="profile-box">
          <CardTitle className="profile-box__title" tag={'h4'}>
            Address
            <Link className="profile-box__edit" to="/profile/edit">
              <span className="profile-box__edit-text">Edit</span>
              <i className="icon icon-edit"/>
            </Link>
          </CardTitle>
          <ul className="billing-address">
            <li className="billing-address__item">{user.address1}</li>
            <li className="billing-address__item">{user.address2}</li>
            <li className="billing-address__item">{user.city}</li>
            <li className="billing-address__item">{user.country}</li>
            <li className="billing-address__item">{user.postcode}</li>
          </ul>
        </Card>
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user
  }
}

export default connect(
  mapStateToProps,
  null
)(ProfileInfo)
